import {useHistory} from "react-router-dom";
import {Card} from "antd";
import React from "react";
import {LiveService} from "../Interfaces/Service";

const {Meta} = Card;

interface Props {
    service: LiveService
    adminId: string
}

export const StoreServiceCard = (props: Props) => {
    const service: LiveService = props.service;

    let history = useHistory();

    function handleClick(projectId: string) {
        history.push(`/store/${projectId}/Overview`);
    }

    return (
        <>
            <Card key={service.manifest.id}
                  type="inner" title={service.manifest.name} hoverable onClick={() => handleClick(service.manifest.id)}>
                <Meta title={""}
                      description={service.manifest.description}/>
            </Card>
        </>
    )
}

export default StoreServiceCard;