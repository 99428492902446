import React, { FC, useContext, useEffect } from 'react'
import { Button, Descriptions, Divider, Drawer, Form, Input, InputNumber, notification, Popconfirm, Select, Table } from 'antd'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { LogAdapterItem, LogAdapterType } from '../../Actions/Interfaces/IProject'
import { RootProjectClassMethods } from '../../Api/APIService'

const LogAdapters: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAdd, setShowAdd] = React.useState(false)
    const [showDetail, setShowDetail] = React.useState(false)
    const [selectedAdapter, setSelectedAdapter] = React.useState<LogAdapterItem>()
    const [loading, setLoading] = React.useState(false)
    const [loggingAdapters, setLoggingAdapters] = React.useState<LogAdapterItem[]>([])

    const [addForm] = Form.useForm()

    const getLoggingAdapters = async () => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)

        try {
            const res = await ctx.instance?.call<any>({
                method: RootProjectClassMethods.getLoggingAdapters,
            })
            setLoggingAdapters(res?.data || [])
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const addAdapter = async (values: any) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            const newAdapter = { ...values, id: Math.random().toString(36).substring(7) }
            if (
                newAdapter.retryConfig &&
                newAdapter.retryConfig?.maxRetries == null &&
                newAdapter.retryConfig?.retryDelay == null &&
                newAdapter.retryConfig?.retryableStatusCodes == null
            ) {
                delete newAdapter.retryConfig
            }

            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateLogAdaptors,
                body: {
                    loggingAdapters: [...loggingAdapters, newAdapter],
                },
            })
            await getLoggingAdapters()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        setShowAdd(false)
        setLoading(false)
        addForm.resetFields()
    }

    const deleteAdapter = async (values: LogAdapterItem) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateLogAdaptors,
                body: {
                    loggingAdapters: (loggingAdapters || []).filter(item => !(item.id === values.id)),
                },
            })
            await getLoggingAdapters()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        setShowDetail(false)
        setSelectedAdapter(undefined)
        setLoading(false)
    }

    // initial load
    useEffect(() => {
        getLoggingAdapters()
    }, [])

    return (
        <>
            <Button
                loading={loading}
                onClick={() => {
                    setShowAdd(true)
                }}
            >
                Add Log Adapter
            </Button>
            <Divider dashed />
            <Table
                dataSource={loggingAdapters}
                columns={[
                    {
                        title: 'Type',
                        render: (item: LogAdapterItem) => {
                            return (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setSelectedAdapter(item)
                                        setShowDetail(true)
                                    }}
                                >
                                    {item.type}
                                    {item.type === LogAdapterType.HTTP && ` - Endpoint: ${item.endpoint}`}
                                    {item.type === LogAdapterType.KINESIS && ` - Stream Name: ${item.streamName}`}
                                </Button>
                            )
                        },
                    },
                ]}
            />
            <Drawer
                title="Detail"
                width={600}
                placement="right"
                onClose={() => {
                    setShowDetail(false)
                }}
                visible={showDetail}
                extra={
                    <Popconfirm
                        title="Are you sure to delete this adapter?"
                        onConfirm={async () => {
                            await deleteAdapter(selectedAdapter!)
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger loading={loading}>
                            Delete
                        </Button>
                    </Popconfirm>
                }
            >
                <Descriptions bordered>
                    {selectedAdapter?.type === LogAdapterType.HTTP && (
                        <>
                            <Descriptions.Item span={3} label="Endpoint">
                                {' '}
                                {selectedAdapter?.endpoint}{' '}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="API Key">
                                {selectedAdapter?.apiKey}{' '}
                            </Descriptions.Item>
                        </>
                    )}
                    {selectedAdapter?.type === LogAdapterType.KINESIS && (
                        <>
                            <Descriptions.Item span={3} label="Stream Name">
                                {' '}
                                {selectedAdapter?.streamName}{' '}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Region">
                                {selectedAdapter?.region}{' '}
                            </Descriptions.Item>
                        </>
                    )}
                    <Descriptions.Item span={3} label="Parallelization Factor">
                        {' '}
                        {selectedAdapter?.pfactor}{' '}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={'Retry Config'}>
                        {selectedAdapter?.retryConfig?.count && `Count: ${selectedAdapter?.retryConfig?.count}`}
                        <br />
                        {selectedAdapter?.retryConfig?.delay && `Delay: ${selectedAdapter?.retryConfig?.delay}`}
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>

            <Drawer
                title="Add Log Adapter"
                width={600}
                placement="right"
                onClose={() => {
                    setShowAdd(false)
                }}
                visible={showAdd}
                extra={
                    <Button loading={loading} form={'add-log-adapter'} htmlType={'submit'}>
                        Add
                    </Button>
                }
            >
                <Form form={addForm} id={'add-log-adapter'} name="basic" labelCol={{ span: 8 }} initialValues={{ remember: true }} onFinish={addAdapter} autoComplete="off">
                    <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please select type!' }]}>
                        <Select>
                            {Object.keys(LogAdapterType).map(key => (
                                <Select.Option value={key}>{key}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                        {({ getFieldValue }) =>
                            getFieldValue('type') === LogAdapterType.HTTP ? (
                                <>
                                    <Form.Item labelCol={{ span: 8 }} label="Endpoint" name={'endpoint'} rules={[{ required: true, message: 'Please input endpoint!' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item labelCol={{ span: 8 }} label="API Key" name={'apiKey'}>
                                        <Input />
                                    </Form.Item>
                                </>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                        {({ getFieldValue }) =>
                            getFieldValue('type') === LogAdapterType.KINESIS ? (
                                <>
                                    <Form.Item labelCol={{ span: 8 }} label="Stream Name" name={'streamName'} rules={[{ required: true, message: 'Please input stream name!' }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        label="Access Key Id"
                                        name={'accessKeyId'}
                                        rules={[{ required: true, message: 'Please input access key id!' }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        label="Secret Access Key"
                                        name={'secretAccessKey'}
                                        rules={[{ required: true, message: 'Please input secret access key!' }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item labelCol={{ span: 8 }} label="Region" name={'region'} rules={[{ required: true, message: 'Please input region!' }]}>
                                        <Input />
                                    </Form.Item>
                                </>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item label="Parallelization Factor" name="pfactor">
                        <InputNumber />
                    </Form.Item>

                    <Form.Item label="Retry Config" name="retryConfig">
                        <Form.Item label="Delay" name={['retryConfig', 'delay']}>
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item label="Count" name={['retryConfig', 'count']}>
                            <InputNumber min={1} />
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default LogAdapters
