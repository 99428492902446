import React, {Component} from "react"
import {APIService} from "../../Api/APIService";
import {Admin} from "../../Interfaces/IProjectSummary";
import {Button, Popconfirm, Table, Tag} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {AddMemberToService} from "../../Modals/AddMemberToService";
import CustomSpinner from "../../Components/CustomSpinner";
import {EditServiceMemberRoles} from "../../Modals/EditServiceMemberRoles";
import {Developer} from "../../Interfaces/Developer";


interface Props {
    apiService: APIService
    serviceId: string
}

interface State {
    deleting: boolean
    developers: Developer[]
    loading: boolean
}

export default class DeveloperList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            deleting: false,
            developers: [],
            loading: true
        }
        this.deleteAdmin = this.deleteAdmin.bind(this)
        this.getMembers = this.getMembers.bind(this)
        this.addAdminSuccess = this.addAdminSuccess.bind(this)
    }

    async getMembers() {
        this.setState({
            loading: true
        })
        const members = await this.props.apiService.getServiceDetail(this.props.serviceId)
        this.setState({
            developers: members.developers,
            loading: false
        })
    }

    async componentDidMount() {
        await this.getMembers()
    }

    async deleteAdmin(adminId: string) {
        this.setState({deleting: true, loading: true})
        const resp = await this.props.apiService.removeAdminFromService(this.props.serviceId, adminId)
        if (resp)
            this.setState({
                developers: this.state.developers.filter(a => a.developerId!== adminId)
            })
        this.setState({deleting: false, loading: false})
    }

    async addAdminSuccess() {
        await this.getMembers()
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <AddMemberToService
                        apiService={this.props.apiService}
                        serviceId={this.props.serviceId}
                        successCallback={this.addAdminSuccess}
                    />
                    <br/><br/>
                    <Table
                        pagination={{defaultPageSize: 20}}
                        columns={[
                            {
                                title: 'Email',
                                render: (item: Developer) => <>{item.email}</>
                            },
                            {
                                title: 'Roles',
                                render: (item: Developer) => {
                                    return item.roles.map(role => {
                                        return <>
                                            <Tag color={role === 'owner' ? "geekblue" : ''}
                                                 key={role}>
                                                {role}
                                            </Tag>
                                        </>
                                    })
                                }
                            },
                            {
                                title: 'Operations',
                                render: (item: Developer) => {
                                    return <>
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined
                                                style={{color: 'red'}}/>}
                                            title="Are you sure to remove this member?"
                                            okButtonProps={{loading: this.state.deleting}}
                                            onConfirm={async () => {
                                                await this.deleteAdmin(item.developerId)
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={"link"} loading={this.state.deleting} danger>Remove</Button>
                                        </Popconfirm>
                                        &nbsp;
                                        <EditServiceMemberRoles
                                            key={item.developerId}
                                            member={
                                                {
                                                    email: item.email,
                                                    memberId: item.developerId,
                                                    currentRoles: item.roles,
                                                    updateToken: item.updateToken
                                                }
                                            }
                                            apiService={this.props.apiService}
                                            serviceId={this.props.serviceId}
                                            successCallback={this.getMembers}/>
                                    </>
                                }
                            }
                        ]}
                        dataSource={this.state.developers}/>
                </CustomSpinner>
            </>
        );
    }
}
