import React, {Component} from "react";
import {Breadcrumb, Layout} from 'antd';
import {APIService} from "../../Api/APIService";
import {Link, Route, RouteComponentProps, Switch} from "react-router-dom";
import ServiceOverviewContent from "./ServiceOverviewContent";

const {Content} = Layout;


interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps<{ serviceId: string }>
}

interface State {
    service: any
    loading: boolean
    activeMenu: string
}

export default class ServiceDetailLayout extends Component<Props, State> {
    private readonly apiService: APIService;
    private readonly serviceId: string;
    private urls: { [key: string]: string } = {};

    constructor(props: Props) {
        super(props);

        this.urls = {
            projectOverview: this.props.routeComponentProps.match.url + '/Overview',
        }

        this.serviceId = props.routeComponentProps.match.params.serviceId

        this.apiService = props.apiService

        let activeMenu = ""
        Object.keys(this.urls).forEach(key => {
            if (this.props.routeComponentProps.location.pathname.includes(this.urls[key])) {
                activeMenu = this.urls[key]
            }
        })

        this.state = {
            activeMenu,
            service: Object.create({}),
            loading: true
        }
    }

    render() {
        return (
            <Layout>
                <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                    <Breadcrumb style={{margin: '16px 0'}}>
                        <Breadcrumb.Item>
                            <Link to="/">Services</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.props.routeComponentProps.match.params.serviceId}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                        }}
                    >
                        <div>
                            <Switch>
                                <Route exact path={`${this.props.routeComponentProps.match.path}/Overview`}
                                       render={(props: RouteComponentProps<{ serviceId: string }>) => {
                                           return <ServiceOverviewContent apiService={this.props.apiService}
                                                                          routeComponentProps={props}/>
                                       }}>
                                </Route>
                            </Switch>
                        </div>

                    </Content>
                </Layout>
            </Layout>
        )
    }
}