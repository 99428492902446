import React, { FC, useContext, useEffect } from 'react'
import { Button, Descriptions, Divider, Drawer, Form, Input, InputNumber, notification, Popconfirm, Select, Space, Table, Typography } from 'antd'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { StateStreamTarget, StateStreamTargetType } from '../../Actions/Interfaces/IProject'
import { RootProjectClassMethods } from '../../Api/APIService'
import DestinationFirestoreForm from './DestinationInputTypes/DestinationFirestoreForm'
import DestinationElasticForm from './DestinationInputTypes/DestinationElasticForm'
import DestinationHttpForm from './DestinationInputTypes/DestinationHttpForm'
import Editor from '@monaco-editor/react'
import { ThemeContext } from '../../Contexts/ThemeContext'

const vtlHelp = (
    <>
        <p>
            You can transform state objects using Velocity Templating Language (VTL). By using VTL you can easily generate a custom data to be sent to your destination. VTL is not
            spesific to JSON. With VTL you can create an XML file to be send to your destination as well.
        </p>

        <p>Rio uses AWS Velocity utils described here:</p>

        <Typography.Link target={'_blank'} href={'https://docs.aws.amazon.com/appsync/latest/devguide/resolver-mapping-template-reference-programming-guide.html'}>
            https://docs.aws.amazon.com/appsync/latest/devguide/resolver-mapping-template-reference-programming-guide.html
        </Typography.Link>

        <p>
            To find more generic information about VTL you can visit:{' '}
            <Typography.Link target={'_blank'} href={'https://velocity.apache.org'}>
                https://velocity.apache.org
            </Typography.Link>
        </p>

        <>Some very basic examples:</>

        <p>1- Sending only private part of state to destination</p>

        <pre>$util.toJson($data.private)</pre>

        <p>2- Sending a custom JSON containing a field called "test" inside public part of the state.</p>

        <pre>
            {`
{
    "someFieldName": "$data.public.test"
}
        `}
        </pre>

        <p>3- Creating an XML file fron some items in private field:</p>

        <pre>
            {`
<Items>
    #foreach( $item in $data.private.items )
    <Item>
        <A>$item.a</A>
    </Item>
    #end
</Items>
    `}
        </pre>
    </>
)

const DestinationAdapters: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAdd, setShowAdd] = React.useState(false)
    const [showDetail, setShowDetail] = React.useState(false)
    const [selectedAdapter, setSelectedAdapter] = React.useState<StateStreamTarget>()
    const [loading, setLoading] = React.useState(false)
    const [vtlHelpDrawer, setVtlHelpDrawer] = React.useState(false)
    const [type, setType] = React.useState<any>()
    const [form] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [stateStreamTargets, setStateStreamTargets] = React.useState<StateStreamTarget[]>([])

    const destinationTypes: string[] = Object.keys(StateStreamTargetType)

    const getStateStreamTargets = async () => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)

        try {
            const res = await ctx.instance?.call<any>({
                method: RootProjectClassMethods.getStateStreamTargets,
            })
            setStateStreamTargets(res?.data ?? [])
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const updateAdapter = async (values: { id: string; transformationTemplate?: string }) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            const targets = stateStreamTargets || []
            const index = targets?.findIndex(t => t.id === values.id)
            if (index === -1) {
                throw new Error('target not found')
            }
            targets[index].transformationTemplate = values.transformationTemplate
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateStateStreamTargets,
                body: {
                    targets,
                },
            })
            await getStateStreamTargets()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
            updateForm.resetFields()
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            } else {
                notification.error({
                    placement: 'bottomRight',
                    message: 'error',
                })
            }
        }
        setLoading(false)
    }

    const addAdapter = async (values: any) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            const dat = {
                ...values,
                credentials: values.credentials,
            }
            try {
                dat.credentials = JSON.parse(values.credentials)
            } catch (e: any) {}
            if (values.type === 'Http' && values.credentials && values.credentials.headers) {
                const headers: any = {}
                values.credentials.headers.map((header: any) => {
                    headers[header.key] = header.value
                })
                values.credentials.headers = headers
            }
            if (values.retryConfig) {
                dat['retryConfig'] = { ...values.retryConfig, rate: 1 }
            }
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateStateStreamTargets,
                body: {
                    targets: (stateStreamTargets || []).concat(dat),
                },
            })
            await getStateStreamTargets()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        form.resetFields()
        setType(undefined)
        setShowAdd(false)
        setLoading(false)
    }

    const deleteAdapter = async (values: StateStreamTarget) => {
        if (!ctx) throw new Error('ctx not found')
        setLoading(true)
        try {
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateStateStreamTargets,
                body: {
                    targets: (stateStreamTargets || []).filter(item => !(item.id === values.id && item.mappingId === values.mappingId && item.type === values.type)),
                },
            })
            await getStateStreamTargets()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        setShowDetail(false)
        setSelectedAdapter(undefined)
        setLoading(false)
    }

    // init
    useEffect(() => {
        getStateStreamTargets()
    }, [])

    return (
        <ThemeContext.Consumer>
            {({ isDarkMode }) => (
                <>
                    <Button
                        loading={loading}
                        onClick={() => {
                            setShowAdd(true)
                        }}
                    >
                        Add Destination Adapter
                    </Button>
                    <Divider dashed />
                    <Table
                        dataSource={stateStreamTargets}
                        columns={[
                            {
                                title: 'Id',
                                render: (item: StateStreamTarget) => {
                                    return (
                                        <a
                                            onClick={() => {
                                                setSelectedAdapter(undefined)
                                                updateForm.resetFields()
                                                updateForm.setFieldsValue({
                                                    id: item.id,
                                                    transformationTemplate: item.transformationTemplate,
                                                })
                                                setSelectedAdapter(item)
                                                setShowDetail(true)
                                            }}
                                        >
                                            {item.id}
                                        </a>
                                    )
                                },
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                            },
                        ]}
                    />
                    <Drawer
                        title="Detail"
                        width={600}
                        placement="right"
                        onClose={() => {
                            setShowDetail(false)
                            updateForm.resetFields()
                            setSelectedAdapter(undefined)
                        }}
                        open={showDetail}
                        extra={
                            <Space>
                                <Button loading={loading} type={'primary'} htmlType={'submit'} form={'update-destination-adapter'}>
                                    Update
                                </Button>
                                <Popconfirm
                                    title="Are you sure to delete this adapter?"
                                    onConfirm={async () => {
                                        await deleteAdapter(selectedAdapter!)
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger loading={loading}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </Space>
                        }
                    >
                        <Descriptions bordered>
                            <Descriptions.Item span={3} label="Id">
                                {' '}
                                {selectedAdapter?.id}{' '}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Type">
                                {' '}
                                {selectedAdapter?.type}{' '}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={'Credentials'}>
                                {selectedAdapter?.credentials ? JSON.stringify(selectedAdapter?.credentials, null, 2) : ''}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={'Retry Config'}>
                                {selectedAdapter?.retryConfig ? JSON.stringify(selectedAdapter?.retryConfig, null, 2) : ''}
                            </Descriptions.Item>
                        </Descriptions>
                        <Form
                            form={updateForm}
                            id={'update-destination-adapter'}
                            name="basic"
                            layout={'vertical'}
                            labelCol={{ span: 10 }}
                            initialValues={{ id: selectedAdapter?.id, transformationTemplate: selectedAdapter?.transformationTemplate }}
                            onFinish={updateAdapter}
                            autoComplete="off"
                        >
                            <Form.Item label="Id" hidden name="id" rules={[{ required: true, message: 'Please input id!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        Transformation Template&nbsp;
                                        <Typography.Link
                                            onClick={() => {
                                                setVtlHelpDrawer(true)
                                            }}
                                        >
                                            Help
                                        </Typography.Link>
                                    </>
                                }
                                name={'transformationTemplate'}
                            >
                                <Editor height={'20vh'} defaultValue={selectedAdapter?.transformationTemplate} language={'text'} theme={isDarkMode ? 'vs-dark' : 'light'} />
                            </Form.Item>
                        </Form>
                        <Drawer
                            title="Transformation Template (VTL) Help"
                            width={450}
                            closable={true}
                            onClose={() => {
                                setVtlHelpDrawer(false)
                            }}
                            open={vtlHelpDrawer}
                        >
                            {vtlHelp}
                        </Drawer>
                    </Drawer>
                    <Drawer
                        title="Add Destination Adapter"
                        width={600}
                        placement="right"
                        onClose={() => {
                            setShowAdd(false)
                        }}
                        open={showAdd}
                        extra={
                            <Button loading={loading} form={'add-destination-adapter'} htmlType={'submit'}>
                                Add
                            </Button>
                        }
                    >
                        <Form
                            form={form}
                            id={'add-destination-adapter'}
                            name="basic"
                            layout={'vertical'}
                            labelCol={{ span: 10 }}
                            initialValues={{ remember: true }}
                            onFinish={addAdapter}
                            autoComplete="off"
                        >
                            <Form.Item label="Id" name="id" rules={[{ required: true, message: 'Please input id!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please input type!' }]}>
                                <Select
                                    onChange={e => {
                                        setType(e)
                                    }}
                                >
                                    {destinationTypes.map(t => {
                                        return <Select.Option value={t}>{t}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Parallelization Factor" name="pfactor">
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <>
                                        Transformation Template&nbsp;
                                        <Typography.Link
                                            onClick={() => {
                                                setVtlHelpDrawer(true)
                                            }}
                                        >
                                            Help
                                        </Typography.Link>
                                    </>
                                }
                                name={'transformationTemplate'}
                            >
                                <Editor height={'20vh'} language={'text'} theme={isDarkMode ? 'vs-dark' : 'light'} />
                            </Form.Item>
                            <Drawer
                                title="Transformation Template (VTL) Help"
                                width={450}
                                closable={true}
                                onClose={() => {
                                    setVtlHelpDrawer(false)
                                }}
                                open={vtlHelpDrawer}
                            >
                                {vtlHelp}
                            </Drawer>
                            <Form.Item label="Retry Config" name="retryConfig">
                                <Form.Item
                                    label="Delay"
                                    name={['retryConfig', 'delay']}
                                    rules={[{ required: true, message: 'Please input value!' }]}
                                    // rootStyle={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <InputNumber min={1} />
                                </Form.Item>
                                <Form.Item
                                    label="Count"
                                    name={['retryConfig', 'count']}
                                    rules={[{ required: true, message: 'Please input value!' }]}
                                    // rootStyle={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <InputNumber min={1} />
                                </Form.Item>
                            </Form.Item>
                            {type === 'Firestore' ? (
                                <>
                                    <DestinationFirestoreForm />
                                </>
                            ) : null}
                            {type === 'Elasticsearch' ? (
                                <>
                                    <DestinationElasticForm />
                                </>
                            ) : null}
                            {type === 'Http' ? (
                                <>
                                    <DestinationHttpForm />
                                </>
                            ) : null}
                        </Form>
                    </Drawer>
                </>
            )}
        </ThemeContext.Consumer>
    )
}

export default DestinationAdapters
