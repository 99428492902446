import React, {Component} from "react";
import {Button, Divider, Modal, Spin, Upload} from "antd";
import {APIService} from "../Api/APIService";
import {InboxOutlined} from '@ant-design/icons';
import {RcFile, UploadChangeParam} from "antd/lib/upload";

const {Dragger} = Upload;


interface Props {
    projectId: string
}

interface State {
    loading: boolean
    visible: boolean
    templateString: string
    fileList: RcFile[]
    currentTemplateDeployLoading: boolean
}

export class DeployTemplateModal extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            templateString: "",
            fileList: [],
            currentTemplateDeployLoading: false
        }
        this.deployTemplate = this.deployTemplate.bind(this)
        this.draggerOnChange = this.draggerOnChange.bind(this)
        this.draggerAction = this.draggerAction.bind(this)
        this.deployWithCurrentTemplate = this.deployWithCurrentTemplate.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async deployTemplate(template: any) {
        this.setState({loading: true});
        //await this.apiService.deployProjectTemplate(this.props.projectId, Buffer.from(template, 'utf-8').toString('base64'))
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async () => {
        await this.deployTemplate(this.state.templateString)
    };

    onFinishFailed = (errorInfo: any) => {

    };

    draggerOnChange(e: UploadChangeParam) {

    }

    draggerAction(e: RcFile, fList: RcFile[]) {
        this.setState({fileList: [e], templateString: ""})
        e.text().then((d) => {
            this.setState({templateString: d})
        })
        return false
    }

    async deployWithCurrentTemplate() {
        this.setState({
            currentTemplateDeployLoading: true
        })
        /*
        if(await this.apiService.deployWithCurrentTemplate(this.props.projectId))
            this.setState({
                currentTemplateDeployLoading: false,
                visible: false
            })

         */
        this.setState({
            currentTemplateDeployLoading: false
        })
    }


    render() {
        const {visible, loading} = this.state;

        return (<>
            <Button type="default" onClick={this.showModal}>
                Deploy Template
            </Button>
            <Modal
                open={visible}
                title="Deploy Template"
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel} loading={loading}>
                        Cancel
                    </Button>
                ]}
            >
                <Button type="dashed" loading={this.state.currentTemplateDeployLoading} block onClick={this.deployWithCurrentTemplate}>
                    Deploy with current template
                </Button>
                <Divider plain>Or</Divider>
                <Spin spinning={loading}>
                    <Dragger
                        fileList={this.state.fileList}
                        key={"uploadcreatemanifest"}
                        name='file'
                        multiple={false}
                        accept={'.yaml,.yml'}
                        beforeUpload={this.draggerAction}
                        onChange={this.draggerOnChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag template file to this area to process</p>
                        <p className="ant-upload-hint">
                            Support for a single file.
                        </p>
                    </Dragger>
                    <Divider/>
                    {this.state.templateString !== "" ? <>
                        <Button key="Upload" type={"primary"} loading={loading} onClick={this.onFinish}>
                            Deploy
                        </Button>
                    </> : ""}
                </Spin>
            </Modal>
        </>);
    }
}