// this file is auto generated, do not edit it directly
module.exports = {
  "rootProjectId": "root",
  "stage": "PROD",
  "captchaKey": "6LeeM0spAAAAAH1mXka_QbmnLwZN9ttX46oJyoa1",
  "region": "eu-west-1",
  "cosUrl": "api.prod.retter.io",
  "version": "2.0.28",
  "appUrl": "https://prod.c.retter.io/",
  "uiBucketName": "prod.c.retter.io",
  "uiBucketDomainName": "prod.c.retter.io"
}