import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import CloudObjectListLayout from './CosLayouts/CloudObjectListLayout'
import { IProjectClass } from '../Actions/Interfaces/IProject'
import { RootProjectContext } from '../Contexts/RootProjectContext'

interface Props {
    routeComponentProps: RouteComponentProps<{ projectId: string }>
}

interface State {
    projectClassList: IProjectClass[]
    loading: boolean
}

class CloudObjectLayout extends Component<Props, State> {
    projectId: string

    constructor(props: Props) {
        super(props)
        this.projectId = props.routeComponentProps.match.params.projectId
        this.state = {
            loading: true,
            projectClassList: [],
        }
        this.createSuccessCallback = this.createSuccessCallback.bind(this)
    }

    componentDidMount() {
        this.setState({
            loading: false,
        })
    }

    async createSuccessCallback(classId: string) {
        this.props.routeComponentProps.history.push(`/project/${this.projectId}/Classes/${classId}`)
    }

    render() {
        return (
            <>
                <>
                    {/*
                        <Tabs destroyInactiveTabPane tabPosition={'left'}>
                        <Tabs.TabPane tab="Classes" key="1">

                        </Tabs.TabPane>

                    </Tabs>
                     <Tabs.TabPane tab="Dependencies" key="2">
                        <DependencyList apiService={this.props.apiService} projectId={this.projectId}/>


                    </Tabs.TabPane>
                         */}

                    <CloudObjectListLayout routeComponentProps={this.props.routeComponentProps} />
                </>
            </>
        )
    }
}

CloudObjectLayout.contextType = RootProjectContext

export default CloudObjectLayout
