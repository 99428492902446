import React, { Component } from 'react'
import { Button, Input, Modal, notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import RBS from '@retter/sdk'

interface Props {
    classId: string
    projectId: string
    onSuccess: () => void
    rootProjectSdk?: RBS
}

interface State {
    classDeleting: boolean
    deleteModalVisible: boolean
    deleteModalConfirmText: string
    classNotFound: boolean
}

class CosClassDangerZone extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classDeleting: false,
            deleteModalVisible: false,
            classNotFound: false,
            deleteModalConfirmText: '',
        }
        this.deleteClassHandler = this.deleteClassHandler.bind(this)
        this.onProjectDelete = this.onProjectDelete.bind(this)
    }

    async deleteClassHandler() {
        if (!this.props.rootProjectSdk) throw new Error('rootProjectSdk not found')
        this.setState({ classDeleting: true })
        const projectInstance = await this.props.rootProjectSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: this.props.projectId,
        })
        try {
            const resp: any = await projectInstance.call<any>({
                method: RootProjectClassMethods.removeClass,
                body: {
                    classId: this.props.classId,
                },
            })
            this.setState({
                classDeleting: false,
                deleteModalVisible: false,
                deleteModalConfirmText: '',
                classNotFound: false,
            })
            if (resp) {
                this.setState({ classNotFound: true })
                await this.props.onSuccess()
            }
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ classDeleting: false })
    }

    async onProjectDelete() {
        await this.deleteClassHandler()
        return
    }

    render() {
        return (
            <>
                <Button icon={<DeleteOutlined />} onClick={() => this.setState({ deleteModalVisible: true })} type="link" danger loading={this.state.classDeleting}>
                    Delete Class
                </Button>
                <Modal
                    title="Are you absolutely sure?"
                    open={this.state.deleteModalVisible}
                    onOk={async () => {
                        await this.onProjectDelete()
                    }}
                    confirmLoading={this.state.classDeleting}
                    onCancel={() => this.setState({ deleteModalVisible: false })}
                    footer={[
                        <Button
                            style={{ width: '100%' }}
                            disabled={this.state.deleteModalConfirmText !== 'permanently delete'}
                            key="submit"
                            danger
                            loading={this.state.classDeleting}
                            onClick={async () => {
                                await this.onProjectDelete()
                            }}
                        >
                            I understand, delete the class
                        </Button>,
                    ]}
                >
                    <p>
                        This action cannot be undone. This will permanently delete the class <b>{this.props.classId}</b>
                    </p>
                    <p>
                        Please type <b>permanently delete</b> to confirm.
                    </p>
                    <Input value={this.state.deleteModalConfirmText} onChange={e => this.setState({ deleteModalConfirmText: e.target.value })} />
                </Modal>
            </>
        )
    }
}

CosClassDangerZone.contextType = RootProjectContext

export default CosClassDangerZone
