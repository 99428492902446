import React, {Component} from "react";
import { PageHeader } from '@ant-design/pro-layout';
import {
    Breadcrumb,
    Collapse,
    Descriptions,
    Divider,
    Input,
    Layout,
    List,
    Skeleton,
    Tabs,
    Typography,
} from 'antd';
import {APIService} from "../../Api/APIService";
import {Link, RouteComponentProps} from "react-router-dom";
import CustomSpinner from "../../Components/CustomSpinner";
import {LiveService} from "../../Interfaces/Service";
import {InstallServiceModal} from "../../Modals/InstallServiceModal";

const {TextArea} = Input;
const {Text} = Typography;
const {Content} = Layout;
const {Panel} = Collapse;

const {TabPane} = Tabs

interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps<{ serviceId: string }>
}

interface State {
    service: LiveService | undefined
    loading: boolean
    configStatus: string
    activeMenu: string
}

export default class StoreServiceDetail extends Component<Props, State> {
    private readonly apiService: APIService;
    private readonly serviceId: string;

    constructor(props: Props) {
        super(props);

        this.serviceId = props.routeComponentProps.match.params.serviceId

        this.apiService = props.apiService


        this.state = {
            activeMenu: "projectOverview",
            service: undefined,
            loading: true,
            configStatus: ""
        }
        this.getServiceDetailOnTheStore = this.getServiceDetailOnTheStore.bind(this)
    }

    async getServiceDetailOnTheStore() {
        const response = await this.apiService.getServiceDetailOnTheStore(this.serviceId)
        if (response) {
            this.setState({service: response, loading: false})
        }
    }

    async componentDidMount() {
        await this.getServiceDetailOnTheStore()
    }

    render() {
        return (

            <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <Link to="/Store">Store</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {this.props.routeComponentProps.match.params.serviceId}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                    }}
                >
                    <div>
                        <CustomSpinner spinning={this.state.loading}>
                            {
                                this.state.service ? <div className="site-page-header-ghost-wrapper">

                                    <PageHeader
                                        ghost={false}
                                        onBack={() => window.history.back()}
                                        title={this.state.service.manifest.name}
                                        subTitle="Service Overview"
                                        extra={[
                                            <InstallServiceModal serviceId={this.state.service.manifest.id} apiService={this.apiService} service={this.state.service}/>
                                        ]}
                                        footer={
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab="Service Manifest" key="2">
                                                    <Divider/>
                                                    Description<br/>
                                                    {this.state.service.manifest.description}
                                                    <Divider/>
                                                    <Collapse defaultActiveKey={['1','2']}>
                                                        <Panel header="Permissions" key="1">
                                                            <Divider orientation="left">Receives</Divider>
                                                            <List
                                                                size="small"
                                                                bordered
                                                                dataSource={this.state.service.manifest.actions.receives}
                                                                renderItem={item => <List.Item>{item}</List.Item>}
                                                            />
                                                            <Divider orientation="left">Sends</Divider>
                                                            <List
                                                                size="small"
                                                                bordered
                                                                dataSource={this.state.service.manifest.actions.sends}
                                                                renderItem={item => <List.Item>{item}</List.Item>}
                                                            />
                                                        </Panel>
                                                    </Collapse>
                                                </TabPane>
                                            </Tabs>
                                        }
                                    >
                                        <Descriptions size="small" column={3}>
                                            <Descriptions.Item
                                                label="Service ID">{this.state.service.manifest.id}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="Name">{this.state.service.manifest.name}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="Creation Time">{this.state.service.createdAt}</Descriptions.Item>
                                        </Descriptions>
                                    </PageHeader>
                                </div> : <Skeleton loading={this.state.loading}/>
                            }
                        </CustomSpinner>
                    </div>

                </Content>
            </Layout>

        )
    }

}