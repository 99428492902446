import React from "react";
import {IProjectDetail} from "../Actions/Interfaces/IProject";
import RBS, {RetterCloudObject} from "@retter/sdk";

export interface IProjectContext {
    projectId?: string
    rootRbsSdk?: RBS
    detail?: IProjectDetail
    instance?: RetterCloudObject
}

export let RootProjectContext: React.Context<IProjectContext> = React.createContext<IProjectContext>({})
