import React, { Component } from 'react'
import RBS from '@retter/sdk'
import { Button, Form, Input, InputNumber, notification, Tabs } from 'antd'
import { RootProjectClassEnums, RootProjectClassMethods } from '../Api/APIService'
import MemoryResultComponent from '../Components/MemoryResultComponent'

export interface Props {
    projectId: string
    rootRbsSdk: RBS
}

export interface State {
    loading: boolean
    getMemoryResponse?: any
    formValues: any
}

export class MemoryLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            formValues: {},
        }
        this.getKey = this.getKey.bind(this)
        this.setKey = this.setKey.bind(this)
    }

    async getKey(values: any) {
        this.setState({ loading: true })
        try {
            const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                instanceId: this.props.projectId,
            })
            const response = await retterClassInstance.call<any>({
                method: RootProjectClassMethods.getMemoryKey,
                body: values,
            })
            if (!response || response.status >= 400) {
                notification.error({
                    placement: 'bottomRight',
                    message: response ? response.data : 'error',
                })
                return false
            }
            await this.setState({
                getMemoryResponse: response.data,
                formValues: {
                    value: response.data,
                },
            })
            notification['success']({
                placement: 'bottomRight',
                message: 'Memory',
                description: 'Success',
            })
        } catch (e: any) {
            this.setState({
                getMemoryResponse: undefined,
            })
            notification.error({
                placement: 'bottomRight',
                message: e.response && e.response.data && e.response.data.message ? e.response.data.message : 'error',
            })
        }
        this.setState({
            loading: false,
        })
    }

    async setKey(values: any) {
        this.setState({ loading: true })
        try {
            const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                instanceId: this.props.projectId,
            })
            const response = await retterClassInstance.call<any>({
                method: RootProjectClassMethods.setMemoryKey,
                body: values,
            })
            if (!response || response.status >= 400) {
                notification.error({
                    placement: 'bottomRight',
                    message: response ? response.data : 'error',
                })
                return false
            }
            notification['success']({
                placement: 'bottomRight',
                message: 'Memory',
                description: 'Success',
            })
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e.response && e.response.data && e.response.data.message ? e.response.data.message : 'error',
            })
        }
        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Get Memory" key="1">
                        <Form name="get_memory" layout="vertical" wrapperCol={{ span: 16 }} initialValues={this.state.formValues} onFinish={this.getKey} autoComplete="off">
                            <Form.Item label="Key" name="key" rules={[{ required: true, message: 'Please input key!' }]}>
                                <Input placeholder="Key" />
                            </Form.Item>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">
                                Get
                            </Button>
                        </Form>
                        <br />
                        <MemoryResultComponent value={this.state.getMemoryResponse} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Set Memory" key="2">
                        <Form name="set_memory" layout="vertical" wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={this.setKey} autoComplete="off">
                            <Form.Item label="Key" name="key" rules={[{ required: true, message: 'Please input key!' }]}>
                                <Input placeholder="Key" />
                            </Form.Item>
                            <Form.Item label="Value" name="value" rules={[{ required: true, message: 'Please input value!' }]}>
                                <Input.TextArea placeholder="Value" rows={5} />
                            </Form.Item>

                            <Form.Item label={'TTL'} name="ttlEX">
                                <InputNumber placeholder="Seconds" min={0} />
                            </Form.Item>

                            <Button loading={this.state.loading} type="primary" htmlType="submit">
                                Set
                            </Button>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}
