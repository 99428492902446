import { LogQueryInput } from './Layouts/LogsLayout'
import queryString from 'query-string'
import RBS, { RetterRegion } from '@retter/sdk'
import autoGenConfig from './config/autoGenConfig'
import { RootProjectClassEnums, RootProjectClassMethods } from './Api/APIService'
import { MouseEvent } from 'react'

export class GlobalHelpers {
    static prepareLogsLayoutLocation(projectId: string, filters: LogQueryInput, queryCurrentTime: boolean = true, autoSearch: boolean = true) {
        return `/project/${projectId}/Logs?${queryString.stringify({
            queryCurrentTime: queryCurrentTime,
            autoSearch: autoSearch,
            query: JSON.stringify(filters),
        })}`
    }

    static getRbsInstanceByProjectId(projectId: string) {
        return RBS.getInstance({
            projectId,
            url: autoGenConfig.cosUrl,
            region: autoGenConfig.stage === 'PROD' ? RetterRegion.euWest1 : RetterRegion.euWest1Beta,
        })
    }

    static async getAccessTokenForCustomUser(rootSdkInstance: RBS, projectId: string, user: { userId: string; identity: string }) {
        const instance = await rootSdkInstance.getCloudObject({
            useLocal: true,
            instanceId: projectId,
            classId: RootProjectClassEnums.Project,
        })
        const response = await instance.call<any>({
            method: RootProjectClassMethods.getAccessTokenForUser,
            body: user,
        })
        return response.data.accessToken
    }

    static humanizeCamelCase(name: string) {
        return (name.match(/[A-Za-z][a-z]*/g) || [])
            .map(word => {
                return word.charAt(0).toUpperCase() + word.substring(1)
            })
            .join(' ')
    }
    static newTab = (e: MouseEvent) => e.ctrlKey || e.metaKey || e.button === 1
    static openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer, noopener')
        window.focus()
    }
}
