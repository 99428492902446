import React, { Component } from 'react'
import { RootProjectClassMethods } from '../../../Api/APIService'
import CustomSpinner from '../../../Components/CustomSpinner'
import { Collapse } from 'antd'
import ReactJson from 'react-json-view'
import { IProjectContext, RootProjectContext } from '../../../Contexts/RootProjectContext'

interface Props {
    logs: Record<string, string[]>
}

interface State {
    loading: boolean
    logs: Record<string, string[]>
    activeLogPane: string
}

class CosMethodResponseLogs extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            logs: props.logs,
            activeLogPane: '',
        }
    }

    async componentDidMount() {
        this.ctx = this.context
    }

    prepareLogString = (logs: string[]) => {
        return logs.map(log => {
            try {
                const json = JSON.parse(log)
                if (typeof json === 'object') {
                    return (
                        <p>
                            {/* @ts-ignore */}
                            <ReactJson name={false} collapsed={true} displayDataTypes={false} src={json} />
                        </p>
                    )
                } else {
                    return <p>{log}</p>
                }
            } catch (e: any) {
                return <p>{log}</p>
            }
        })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <CustomSpinner spinning={this.state.loading}>
                            <Collapse
                                defaultActiveKey={[this.state.activeLogPane]}
                                onChange={e => {
                                    this.setState({
                                        activeLogPane: e.toString(),
                                    })
                                }}
                            >
                                {Object.keys(this.state.logs).map((l, i) => (
                                    <Collapse.Panel header={<>{l}</>} key={i}>
                                        {this.state.logs[l].length === 0 && <p>No logs found</p>}
                                        {this.prepareLogString(this.state.logs[l])}
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        </CustomSpinner>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

CosMethodResponseLogs.contextType = RootProjectContext
export default CosMethodResponseLogs
