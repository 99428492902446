import React, { Component } from 'react'
import { Button, Input, Modal, notification } from 'antd'
import { IProjectContext, RootProjectContext } from '../../Contexts/RootProjectContext'
import CustomSpinner from '../../Components/CustomSpinner'

interface Props {}

interface State {
    loading: boolean
    projectDeleting: boolean
    deleteModalVisible: boolean
    deleteModalConfirmText: string
    projectNotFound: boolean
    projectStatus: 'deactivated' | 'active'
}

class DangerZone extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            projectStatus: 'active',
            projectDeleting: false,
            deleteModalVisible: false,
            projectNotFound: false,
            deleteModalConfirmText: '',
        }
        this.deleteProjectHandler = this.deleteProjectHandler.bind(this)
        this.onProjectDelete = this.onProjectDelete.bind(this)
    }

    componentDidMount() {
        this.getProjectStatus()
    }

    async getProjectStatus() {
        if (!this.context.instance) return
        this.setState({
            loading: true,
        })
        try {
            const res = await this.context.instance.call({
                method: 'getProjectStatus',
            })
            this.setState({
                projectStatus: res.data.type,
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    async deleteProjectHandler() {
        if (this.context) {
            const context = this.context as IProjectContext
            if (context.instance) {
                this.setState({ projectDeleting: true })
                const resp = await context.instance.call({
                    method: 'deactivate',
                })
                if (resp.status >= 200) {
                    this.setState({
                        deleteModalVisible: false,
                        deleteModalConfirmText: '',
                        projectNotFound: false,
                    })
                    window.location.href = '/'
                }
                this.setState({
                    projectDeleting: false,
                })
            }
        }
    }

    async onProjectDelete() {
        await this.deleteProjectHandler()
        return
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Button
                        disabled={this.state.projectStatus === 'deactivated'}
                        style={{ width: '200px' }}
                        onClick={() => this.setState({ deleteModalVisible: true })}
                        type="primary"
                        danger
                        block
                        loading={this.state.projectDeleting}
                    >
                        Deactivate Project
                    </Button>
                    <Modal
                        title="Are you absolutely sure?"
                        open={this.state.deleteModalVisible}
                        onOk={() => this.onProjectDelete()}
                        confirmLoading={this.state.projectDeleting}
                        onCancel={() => this.setState({ deleteModalVisible: false })}
                        footer={[
                            <Button
                                style={{ width: '100%' }}
                                disabled={this.state.deleteModalConfirmText !== 'deactivate'}
                                key="submit"
                                danger
                                loading={this.state.projectDeleting}
                                onClick={() => this.onProjectDelete()}
                            >
                                I understand, deactivate the project
                            </Button>,
                        ]}
                    >
                        <p></p>
                        <p>
                            Please type <b>deactivate</b> to confirm.
                        </p>
                        <Input value={this.state.deleteModalConfirmText} onChange={e => this.setState({ deleteModalConfirmText: e.target.value })} />
                    </Modal>
                </CustomSpinner>
            </>
        )
    }
}

DangerZone.contextType = RootProjectContext

export default DangerZone
