import React from "react";
import {Button, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';

interface LoginInput {
    email: string;
    password: string;
    captcha: string;
}

interface Props {
    loading: boolean;
    onSignUpPressed: () => void
    onForgotPasswordPressed: () => void
    onSignInPressed: (value: LoginInput) => {}
}

const SignInForm = ({loading, onSignUpPressed, onSignInPressed, onForgotPasswordPressed}: Props) => {
    const onFinish = (values: LoginInput) => {
        onSignInPressed(values)
    }

    return (
        <Form
            autoComplete={'off'}
            size={"large"}
            name="sign-in"
            className="login-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {type: 'email', message: 'The input is not valid E-mail!'},
                    {required: true, message: "Please input your Email!"}
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon"/>}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    style={{marginBottom: 10}}
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    Log in
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignInForm;
