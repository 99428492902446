import React from "react";
import {Button, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';

interface LoginInput {
    username: string;
    password: string;
    confirm: string;
    captcha: string;
}

interface Props {
    loading: boolean;
    onSignInPressed: () => void;
    onSignUpPressed: (values: LoginInput) => void
}

const SignUpForm = ({loading, onSignInPressed, onSignUpPressed}: Props) => {
    const onFinish = (values: LoginInput) => {
        onSignUpPressed(values);
    }

    const containsSpecialCharacters = (str: string) => {
        const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
        return regex.test(str);
    }

    const anyValidation = (rule: any, value: string, callback: any) => {
        if (value.length < 8) {
            callback('Password must be minimum 8 characters.');
        } else if (value === value.toLowerCase() && value !== value.toUpperCase()) {
            callback('Password must contains one uppercase letter.');
        } else if (!containsSpecialCharacters(value)) {
            callback('Password must have symbol characters');
        } else {
            callback();
        }
    };

    return (
        <Form
            autoComplete={'off'}
            size={"large"}
            name="sign-up"
            className="login-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {type: 'email', message: 'The input is not valid E-mail!'},
                    {required: true, message: "Please input your Email!"}
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon"/>}
                    placeholder="Email"
                    autoComplete={'off'}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    {validator: anyValidation},
                ]}
                hasFeedback
            >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>} placeholder={'Password'}/>
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    {validator: anyValidation},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>}
                                placeholder={'Confirm Password'}/>
            </Form.Item>
            {/* <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lez4JMaAAAAACPeVH4GlJ6oZA695L1emicWvVwt"
          onChange={onCaptchaChange}
        />
      </div> */}
            <Form.Item>
                <Button
                    style={{marginBottom: 10}}
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    Register
                </Button>
                <Divider plain>Or</Divider>
                <span
                    onClick={onSignInPressed}
                    className="login-form-forgot"
                    style={{color: '#1890ff', cursor: 'pointer'}}
                >
          Log In
        </span>
            </Form.Item>
        </Form>
    );
};

export default SignUpForm;
