import * as nomnoml from 'nomnoml'
import YAML from 'yaml'

export interface NomnomlObject {
    svgImageSrc: string,
    code: string
}

export function getNomnoml(templateString?: string): NomnomlObject | undefined {
    if (!templateString) return undefined
    const template: any = YAML.parse(templateString)
    const code = template.methods.reduce((diagram: string, {flow, method, steps}: any) => {
        const block = [`[<box> ${method} |`]
        if (Array.isArray(steps)) block.push(...steps.map(({id}) => `[<blob> ${id}]`))
        if (Array.isArray(flow)) block.push(...flow.map(({direction}) => `[<blob> ${direction[0]}] -> [<blob> ${direction[1]}]`))
        block.push(']')
        return diagram + '\n' + block.join('\n')
    }, `#arrowSize: 0.5\n#.box: fill=#f6f6f6\n#.blob: fill=#ececec`).trim()
    const svg = nomnoml.renderSvg(code)
    return {
        code,
        svgImageSrc: `data:image/svg+xml;base64, ${Buffer.from(svg).toString('base64')}`
    }
}
