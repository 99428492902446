import React from 'react'
import RBS, { RetterCloudObject } from '@retter/sdk'
import { IUserStoreDataItems } from '../Actions/Stores/UserStore'

export interface IUserProviderContext {
    rootRbsSdk?: RBS
    detail?: IUserStoreDataItems
    instance?: RetterCloudObject
    isRootProjectMember?: boolean
}

export let UserProviderContext: React.Context<IUserProviderContext> = React.createContext<IUserProviderContext>({})
