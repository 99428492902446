import React, { Component } from 'react'
import { Button, Form, Input, Modal, notification, Spin } from 'antd'
import { APIService, RootProjectClassEnums } from '../Api/APIService'

interface Props {
    apiService: APIService
    onSuccess: (projectId: string) => void
}

interface State {
    alias: string
    loading: boolean
    visible: boolean
}

export class CreateNewProjectModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            alias: '',
            loading: false,
            visible: false,
        }
        this.createProject = this.createProject.bind(this)
        this.onFinish = this.onFinish.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    async createProject() {
        this.setState({ loading: true })
        try {
            const response = await this.props.apiService.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                body: {
                    alias: this.state.alias,
                },
            })
            this.setState({ loading: false })
            this.props.onSuccess(response.instanceId)
            return response
        } catch (e: any) {
            notification.error({
                message: e.response.data.message || 'Error',
            })
            this.setState({ loading: false })
        }
    }

    async onFinish() {
        await this.createProject()
        this.setState({ visible: false })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    render() {
        const { visible, loading } = this.state

        return (
            <>
                <Button type={"primary"} onClick={this.showModal}>
                    Create New Project
                </Button>
                <Modal
                    open={visible}
                    title="Create New Project"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type={'link'} onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>,
                        <Button key="confirm" htmlType={'submit'} type={'primary'} form={'create_new_project'} loading={loading}>
                            Create
                        </Button>,
                    ]}
                >
                    <Spin spinning={loading}>
                        <Form
                            id={'create_new_project'}
                            layout={'horizontal'}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            wrapperCol={{ span: 20, offset: 1 }}
                        >
                            <Form.Item label="Alias" name="alias" rules={[{ required: true, message: 'Please input project alias' }]}>
                                <Input
                                    onChange={v => {
                                        this.setState({ alias: v.target.value })
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        )
    }
}
