import React from 'react';
import {Button, Divider, Form, Input} from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

interface Props {
  loading: boolean;
  onSignInPressed: () => void;
  onConfirmSignUpPressed: (values: Values) => void;
  username: string;
  password: string;
}

interface Values {
  email: string;
  code: string;
  password: string;
}

const ConfirmSignUpForm = ({
  loading,
  onConfirmSignUpPressed, 
  onSignInPressed,
  username,
  password
}: Props) => {

  const onFinish = (values: Values) => {
    if (!(values.email && values.password)) {
      values.email = username;
      values.password = password;
    }
    onConfirmSignUpPressed(values)
  }

  return (
    <Form
        size={"large"}
      name="confirm-sign-up"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {!username &&
        <Form.Item
          name="email"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!'},
            { required: true, message: "Please input your Email!" }
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            autoComplete={'off'}
          />
        </Form.Item>
      }
      <Form.Item
        name="code"
        rules={[
          { required: true, message: "Please input your Code!" }
        ]}
      >
        <Input
          placeholder="Code"
        />
      </Form.Item>
      {!password && 
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            autoComplete={'off'}
          />
        </Form.Item>
      }
         
      <Form.Item>
        <Button 
          style={{marginBottom: 10}} 
          block 
          type="primary" 
          htmlType="submit" 
          className="login-form-button"
          loading={loading}
        >
          Confirm Registration
        </Button>
        <Divider plain>Or</Divider>
        <span 
          onClick={onSignInPressed}
          className="login-form-forgot" 
          style={{color: '#1890ff', cursor: 'pointer'}}
        >
          Log In
        </span>
      </Form.Item>
    </Form>
  )
}

export default ConfirmSignUpForm;