import React, {Component} from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Col, Empty, Layout, Row, Typography } from 'antd';
import {APIService} from "../Api/APIService";

import CustomSpinner from "../Components/CustomSpinner";
import emptySvg from "../empty.svg"
import StoreServiceCard from "../Cards/StoreServiceCard";

const {Text} = Typography;
const {Content, Footer} = Layout;


interface Props {
    apiService: APIService
}

interface State {
    services: any
    projectDetail?: any
    adminEmail?: string
    adminId?: string
}

export default class StoreLayout extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        const admin = JSON.parse(localStorage.getItem('user')!)
        this.apiService = props.apiService

        this.state = {
            services: false,
            adminEmail: admin ? admin.email : undefined,
            adminId: admin ? admin.username : undefined
        }
    }

    async componentDidMount() {
        this.setState({services: 'loading'})
        await this.getStoreServices()
    }

    private async getStoreServices() {
        this.setState({services: 'loading'})
        const services = await this.apiService.getServicesOnTheStore()
        this.setState({services})
    }

    render() {
        return (
            <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                <CustomSpinner spinning={this.state.services === "loading"}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                        }}
                    >
                        <PageHeader
                            className="site-page-header"
                            title="Store"
                            subTitle="Global Services"
                            extra={[]}
                        >
                            <div className="site-card-wrapper">
                                <Row
                                    gutter={[{xs: 8, sm: 16, md: 24, lg: 32}, {xs: 8, sm: 16, md: 24, lg: 32}]}>
                                    {Array.isArray(this.state.services) && this.state.services.length > 0 ? this.state.services.map(p => {
                                            return <Col className="gutter-row" xs={{span: 24}} sm={{span: 12}}
                                                        md={{span: 8}} lg={{span: 6}}>
                                                <StoreServiceCard service={p} adminId={this.state.adminId!}/>
                                            </Col>
                                        }) :
                                        this.state.services === "loading" ? null :
                                            <Empty
                                                image={emptySvg}
                                                imageStyle={{
                                                    height: 60,
                                                }}
                                                description={
                                                    "Any service can not found"
                                                }
                                            >

                                            </Empty>
                                    }
                                </Row>
                            </div>
                        </PageHeader>
                    </Content>
                </CustomSpinner>
            </Layout>
        )
    }
}