import React, { Component } from 'react'
import { Button, Input, Modal, notification, Popconfirm, Table } from 'antd'
import CustomSpinner from '../../Components/CustomSpinner'
import { Link } from 'react-router-dom'
import { OrderedListOutlined } from '@ant-design/icons'
import RBS, { RetterCloudObject } from '@retter/sdk'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { RootProjectClassMethods } from '../../Api/APIService'
import { GlobalHelpers } from '../../GlobalHelpers'
import config from '../../config/autoGenConfig'
import axios from 'axios'

const { cosUrl } = config
const { Search } = Input

interface Props {
    classId: string
    projectId: string
    rootProjectSdk: RBS
    classInstance: RetterCloudObject
}

interface State {
    loading: boolean
    instanceList: string[]
    filteredInstanceList: string[]
    visibility: boolean
}

class CosInstanceList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            instanceList: [],
            visibility: false,
            filteredInstanceList: [],
        }
        this.init = this.init.bind(this)
        this.showModal = this.showModal.bind(this)
        this.searchInstance = this.searchInstance.bind(this)
    }

    async showModal() {
        this.setState({ visibility: true })
        await this.init()
    }

    async init() {
        this.setState({
            loading: true,
            instanceList: [],
            filteredInstanceList: [],
        })
        const response = await this.props.classInstance.call<any>({
            method: RootProjectClassMethods.getClassInstances,
        })

        if (response.status >= 400) {
            notification.error({
                placement: 'bottomRight',
                message: response.data,
            })
            return false
        }

        const instances: string[] = response.data
        this.setState({
            instanceList: instances,
            filteredInstanceList: instances,
        })
        this.setState({
            loading: false,
        })
    }

    searchInstance(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value
        if (value === '') {
            this.setState({ filteredInstanceList: this.state.instanceList })
        } else {
            const filtered = this.state.instanceList.filter(i => i.toLowerCase().includes(value.toLowerCase()))
            this.setState({ filteredInstanceList: filtered })
        }
    }

    deleteInstance = async (instanceId: string) => {
        this.setState({
            loading: true,
        })
        try {
            const currentUser = await this.props.rootProjectSdk.getCurrentUser()
            if (!currentUser) throw new Error('current user not found')

            const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootProjectSdk, this.props.projectId, {
                identity: currentUser.identity!,
                userId: currentUser.userId!,
            })

            const url = `https://${cosUrl}/${this.props.projectId}/DESTROY/${this.props.classId}/${instanceId}`
            await axios({
                method: 'post',
                url,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
            this.setState({
                instanceList: this.state.instanceList.filter(i => i !== instanceId),
                filteredInstanceList: this.state.filteredInstanceList.filter(i => i !== instanceId),
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <>
                            {this.props.projectId ? (
                                <>
                                    <Button type={'default'} icon={<OrderedListOutlined />} onClick={this.showModal}>
                                        Instances
                                    </Button>
                                    <Modal
                                        title="Instances"
                                        open={this.state.visibility}
                                        width={800}
                                        onCancel={() => {
                                            this.setState({ visibility: false })
                                        }}
                                        footer={[
                                            <Button
                                                type={'default'}
                                                onClick={() => {
                                                    this.setState({ visibility: false })
                                                }}
                                            >
                                                Close
                                            </Button>,
                                        ]}
                                    >
                                        <CustomSpinner spinning={this.state.loading}>
                                            <Search placeholder="Search" onChange={this.searchInstance} autoFocus />
                                            <br />
                                            <br />
                                            <Table
                                                dataSource={this.state.filteredInstanceList.map(c => {
                                                    return { key: c, instanceId: c }
                                                })}
                                                columns={[
                                                    {
                                                        title: (
                                                            <>
                                                                Instance Id{' '}
                                                                <Button
                                                                    type={'link'}
                                                                    onClick={async () => {
                                                                        await this.init()
                                                                    }}
                                                                >
                                                                    Refresh
                                                                </Button>
                                                            </>
                                                        ),
                                                        key: 'InstanceId',
                                                        render: (item: { instanceId: string }) => {
                                                            return (
                                                                <Link
                                                                    key={item.instanceId}
                                                                    to={`/project/${this.props.projectId}/Classes/${this.props.classInstance.instanceId
                                                                        .split('_')
                                                                        .pop()}/instance/${item.instanceId}`}
                                                                >
                                                                    {item.instanceId}
                                                                </Link>
                                                            )
                                                        },
                                                    },
                                                    {
                                                        title: '',
                                                        key: 'operations',
                                                        render: (item: { instanceId: string }) => {
                                                            return (
                                                                <>
                                                                    <Popconfirm
                                                                        title="Are you sure to delete this instance?"
                                                                        onConfirm={async () => {
                                                                            await this.deleteInstance(item.instanceId)
                                                                        }}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button type={'link'} loading={this.state.loading} danger>
                                                                            Delete
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </>
                                                            )
                                                        },
                                                    },
                                                ]}
                                            />
                                        </CustomSpinner>
                                    </Modal>
                                </>
                            ) : null}
                        </>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

CosInstanceList.contextType = RootProjectContext

export default CosInstanceList
