import React, { Component } from 'react'
import Editor from '@monaco-editor/react'
import { AutoComplete, Button, Col, Divider, Empty, Form, Input, notification, Row, Select, Space, Tabs, Tag, Typography } from 'antd'
import CustomSpinner from '../../../Components/CustomSpinner'
import { RoleItem } from '../../../Interfaces/IRoles'
import RBS, { RetterCallResponse, RetterCloudObject, RetterCloudObjectCall, RetterCloudObjectMethod, RetterCloudObjectState } from '@retter/sdk'
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { GlobalHelpers } from '../../../GlobalHelpers'
// import * as RjForm from '@rjsf/core'
// import { IChangeEvent } from '@rjsf/core'
import { RootProjectContext } from '../../../Contexts/RootProjectContext'
import CosMethodHeaders from './CosMethodHeaders'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../../Api/APIService'
import { ModelDefinitions } from '../../../Interfaces/IProjectSummary'
import { withTheme, IChangeEvent } from '@rjsf/core'
import { Theme as AntDTheme } from '@rjsf/antd'
import validator from '@rjsf/validator-ajv8'
import { ThemeContext } from '../../../Contexts/ThemeContext'

const { Text } = Typography
const RjForm = withTheme(AntDTheme) as any

interface Props {
    projectId: string
    input: {
        classId: string
        instanceId?: string
        method: string
    }
    rootSdkInstance: RBS
    thisClassInstance?: RetterCloudObject
    methodDetail?: RetterCloudObjectMethod
}

interface State {
    loading: boolean
    req: string
    callResponse?: RetterCallResponse<RetterCloudObjectState>
    roles: RoleItem[]
    filteredRoles: RoleItem[]
    responseTabActiveKey: string
    httpMethod: 'get' | 'post'
    reqHeaders: { [key: string]: string }
    modelDefinitions: ModelDefinitions
    queryStringParams: { key: string; value: string }[]
}

export class CosMethodTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            req: '{}',
            roles: [],
            filteredRoles: [],
            responseTabActiveKey: 'Body',
            httpMethod: 'post',
            reqHeaders: {},
            modelDefinitions: {},
            queryStringParams: [],
        }
        this.execute = this.execute.bind(this)
        this.reqOnChange = this.reqOnChange.bind(this)
        this.handleRoleInputSearch = this.handleRoleInputSearch.bind(this)
        this.identityCompleteOnFocus = this.identityCompleteOnFocus.bind(this)
        this.rjFormChanged = this.rjFormChanged.bind(this)
        this.reqOnChange = this.reqOnChange.bind(this)
        this.headersFormOnChange = this.headersFormOnChange.bind(this)
        this.getModels = this.getModels.bind(this)
    }

    async getModels() {
        this.setState({ loading: true })
        const projectInstance = await this.props.rootSdkInstance.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: this.props.projectId,
        })
        try {
            const resp: any = await projectInstance.call<any>({
                method: RootProjectClassMethods.getModelDefinitions,
            })
            if (resp) {
                this.setState({
                    modelDefinitions: resp.data,
                })
            }
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false })
    }

    async componentDidMount() {
        await this.getModels()
    }

    async execute(form: { identity: string; userId: string; httpMethod: 'get' | 'post' }) {
        try {
            this.setState({
                loading: true,
            })
            let payload
            try {
                payload = JSON.parse(this.state.req)
            } catch (e: any) {
                payload = this.state.req
            }

            const user = form.userId && form.userId !== '' && form.identity && form.identity !== '' ? form : undefined
            let ins = this.props.thisClassInstance
            let result
            let resp
            if (ins) {
                if (user) {
                    try {
                        const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, user)
                        const callObject: RetterCloudObjectCall = {
                            httpMethod: form.httpMethod,
                            instanceId: this.props.input.instanceId,
                            method: this.props.input.method,
                            token,
                            headers: this.state.reqHeaders,
                        }

                        if (callObject.httpMethod === 'get') {
                            if (typeof payload === 'string') {
                                try {
                                    callObject.queryStringParams = JSON.parse(payload)
                                } catch (e: any) {}
                            } else if (typeof payload === 'object') {
                                callObject.queryStringParams = payload
                            }
                        } else {
                            callObject.body = payload
                        }
                        resp = await ins.call(callObject)
                    } catch (e: any) {
                        if (e.response) {
                            resp = e.response
                        } else {
                            resp = e
                        }
                    }
                } else {
                    try {
                        const currentUser = await this.props.rootSdkInstance.getCurrentUser()
                        if (!currentUser) throw new Error('current user not found')
                        const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, {
                            identity: currentUser.identity!,
                            userId: currentUser.userId!,
                        })
                        const callObject: RetterCloudObjectCall = {
                            httpMethod: form.httpMethod,
                            instanceId: this.props.input.instanceId,
                            method: this.props.input.method,
                            body: payload,
                            token,
                            headers: this.state.reqHeaders,
                        }

                        if (this.state.queryStringParams.length) {
                            callObject.queryStringParams = {}
                            this.state.queryStringParams.filter(Boolean).forEach(p => {
                                callObject.queryStringParams![p.key] = p.value
                            })
                        }

                        resp = await ins.call(callObject)
                    } catch (e: any) {
                        resp = e.response
                    }
                }
            }
            if (resp) {
                this.setState({
                    callResponse: resp,
                })
            } else {
                this.setState({
                    callResponse: undefined,
                })
            }
            this.setState({
                loading: false,
            })
        } catch (e: any) {
            console.error(e)
        }
    }

    reqOnChange(val: string | undefined, e: any) {
        try {
            JSON.parse(val || '{}')
            this.setState({
                req: val || '{}',
            })
        } catch (e: any) {
            this.setState({
                req: val || '',
            })
        }
    }

    handleRoleInputSearch(key: string) {
        this.setState({
            filteredRoles: this.state.roles.filter(r => r.roleName.includes(key.toLowerCase())) || [],
        })
    }

    async identityCompleteOnFocus() {
        const projectRoles: any = undefined //await this.props.apiService.getProjectRoles(this.props.projectId)
        if (projectRoles)
            this.setState({
                roles: projectRoles.projectRoles,
            })
    }

    rjFormChanged(data: any) {
        let req
        try {
            req = JSON.stringify(data, null, 2)
            this.setState({
                req,
            })
        } catch (e: any) {}
    }

    isJsonString(val: string) {
        try {
            JSON.parse(val)
            return true
        } catch (e: any) {
            return false
        }
    }

    headersFormOnChange = (values: any) => {
        this.setState({
            reqHeaders: values.reduce((acc: any, prev: any) => {
                acc[prev.key] = prev.value
                return acc
            }, {}),
        })
    }

    reformatJsonSchema(schema: any) {
        if (schema.description) {
            schema.title = schema.description
            delete schema.description
        }

        const mulipleFields = ['allOf', 'oneOf', 'anyOf']
        mulipleFields.forEach(field => {
            if (schema[field]) {
                schema[field].forEach((s: any) => {
                    this.reformatJsonSchema(s)
                })
            }
        })

        if (schema.properties) {
            Object.keys(schema.properties).forEach(key => {
                this.reformatJsonSchema(schema.properties[key])
            })
        }

        return schema
    }

    render() {
        return (
            <>
                <ThemeContext.Consumer>
                    {({ isDarkMode }) => (
                        <RootProjectContext.Consumer>
                            {ctx =>
                                ctx ? (
                                    <>
                                        <Row>
                                            <Form name="call_instance" layout="inline" onFinish={this.execute}>
                                                <Form.Item name="identity">
                                                    <AutoComplete
                                                        onFocus={this.identityCompleteOnFocus}
                                                        onSearch={this.handleRoleInputSearch}
                                                        style={{ width: 200 }}
                                                        placeholder="Identity"
                                                    >
                                                        {this.state.filteredRoles.map(r => {
                                                            return (
                                                                <AutoComplete.Option key={r.roleName} value={r.roleName}>
                                                                    {r.roleName}
                                                                </AutoComplete.Option>
                                                            )
                                                        })}
                                                    </AutoComplete>
                                                </Form.Item>
                                                <Form.Item name="userId">
                                                    <Input placeholder={'User Id'} />
                                                </Form.Item>
                                                <Form.Item name={'httpMethod'}>
                                                    <Select
                                                        defaultValue={this.state.httpMethod}
                                                        style={{ width: 120 }}
                                                        onSelect={e => {
                                                            this.setState({ httpMethod: e })
                                                        }}
                                                    >
                                                        <Select.Option value="post">POST</Select.Option>
                                                        <Select.Option value="get">GET</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button htmlType="submit" loading={this.state.loading} type={'primary'}>
                                                        Execute
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Row>
                                        <Row gutter={[12, 12]}>
                                            <Col span={12}>
                                                <Divider orientation="left" plain>
                                                    <Text strong>Request</Text>
                                                </Divider>
                                                <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                                                    <Tabs.TabPane tab="Body" key="body">
                                                        <Editor
                                                            theme={isDarkMode ? 'vs-dark' : 'light'}
                                                            options={{
                                                                minimap: {
                                                                    enabled: false,
                                                                },
                                                            }}
                                                            height={this.props.methodDetail?.inputModel ? '70vh' : '65vh'}
                                                            language={'json'}
                                                            defaultValue={this.state.req}
                                                            onChange={this.reqOnChange}
                                                        />
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab="Headers" key="headers">
                                                        <CosMethodHeaders onChange={this.headersFormOnChange} />
                                                    </Tabs.TabPane>
                                                    {this.state.httpMethod === 'post' ? (
                                                        <>
                                                            {this.props.methodDetail?.inputModel ? (
                                                                <>
                                                                    <Tabs.TabPane tab="Form" key="form">
                                                                        <RjForm
                                                                            schema={this.reformatJsonSchema({
                                                                                ...(this.state.modelDefinitions[this.props.methodDetail.inputModel] as any),
                                                                                ...{ $defs: this.state.modelDefinitions },
                                                                            })}
                                                                            formData={this.state.req && this.isJsonString(this.state.req) ? JSON.parse(this.state.req) : {}}
                                                                            onChange={(e: IChangeEvent<any>) => {
                                                                                this.rjFormChanged(e.formData)
                                                                            }}
                                                                            validator={validator}
                                                                            children={true}
                                                                        />
                                                                    </Tabs.TabPane>
                                                                </>
                                                            ) : (
                                                                <>No input model</>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {this.props.methodDetail?.queryStringModel ? (
                                                                <>
                                                                    <Tabs.TabPane tab="Form" key="form">
                                                                        <RjForm
                                                                            schema={this.reformatJsonSchema({
                                                                                ...(this.state.modelDefinitions[this.props.methodDetail.queryStringModel] as any),
                                                                                ...{ $defs: this.state.modelDefinitions },
                                                                            })}
                                                                            formData={this.state.req && this.isJsonString(this.state.req) ? JSON.parse(this.state.req) : {}}
                                                                            onChange={(e: IChangeEvent<any>) => {
                                                                                this.rjFormChanged(e.formData)
                                                                            }}
                                                                            validator={validator}
                                                                            children={true}
                                                                        />
                                                                    </Tabs.TabPane>
                                                                </>
                                                            ) : (
                                                                <>No input model</>
                                                            )}
                                                        </>
                                                    )}

                                                    <Tabs.TabPane tab={'Params'} key={'params'}>
                                                        <Form
                                                            name="query_string_params"
                                                            autoComplete="off"
                                                            onValuesChange={(changedValues, values) => {
                                                                this.setState({
                                                                    queryStringParams: values.params,
                                                                })
                                                            }}
                                                        >
                                                            <Form.List name="params">
                                                                {(fields, { add, remove }) => (
                                                                    <>
                                                                        {fields.map(({ key, name, ...restField }) => (
                                                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                                <Form.Item {...restField} name={[name, 'key']} rules={[{ required: true, message: 'Missing key' }]}>
                                                                                    <Input placeholder="Key" />
                                                                                </Form.Item>
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[name, 'value']}
                                                                                    rules={[{ required: true, message: 'Missing value' }]}
                                                                                >
                                                                                    <Input placeholder="Value" />
                                                                                </Form.Item>
                                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                                            </Space>
                                                                        ))}
                                                                        <Form.Item>
                                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                                Add Param
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </>
                                                                )}
                                                            </Form.List>
                                                        </Form>
                                                    </Tabs.TabPane>
                                                </Tabs>
                                            </Col>
                                            <Col span={12}>
                                                <Divider orientation="left" plain>
                                                    <Text strong>
                                                        Response{' '}
                                                        {this.state.callResponse ? (
                                                            <Tag color={this.state.callResponse.status > 399 ? '#f50' : '#87d068'}>{this.state.callResponse?.status}</Tag>
                                                        ) : null}
                                                    </Text>
                                                </Divider>

                                                <CustomSpinner spinning={this.state.loading}>
                                                    {this.state.callResponse ? (
                                                        <>
                                                            <Tabs
                                                                destroyInactiveTabPane
                                                                defaultActiveKey={this.state.responseTabActiveKey}
                                                                onChange={key => {
                                                                    this.setState({ responseTabActiveKey: key })
                                                                }}
                                                            >
                                                                <Tabs.TabPane tab="Body" key="Body">
                                                                    <Editor
                                                                        theme={isDarkMode ? 'vs-dark' : 'light'}
                                                                        options={{
                                                                            readOnly: true,
                                                                            minimap: {
                                                                                enabled: false,
                                                                            },
                                                                        }}
                                                                        height={'71vh'}
                                                                        language={'json'}
                                                                        defaultValue={JSON.stringify(this.state.callResponse.data, null, 2)}
                                                                    />
                                                                </Tabs.TabPane>
                                                                <Tabs.TabPane tab="Headers" key="Headers">
                                                                    <Editor
                                                                        theme={isDarkMode ? 'vs-dark' : 'light'}
                                                                        options={{
                                                                            readOnly: true,
                                                                            minimap: {
                                                                                enabled: false,
                                                                            },
                                                                        }}
                                                                        height={'70vh'}
                                                                        language={'json'}
                                                                        defaultValue={JSON.stringify(this.state.callResponse.headers, null, 2)}
                                                                    />
                                                                </Tabs.TabPane>
                                                                <Tabs.TabPane tab="Logs" key={'Logs'}>
                                                                    {this.state.callResponse ? (
                                                                        <>
                                                                            <Button
                                                                                icon={<LinkOutlined />}
                                                                                type={'link'}
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        GlobalHelpers.prepareLogsLayoutLocation(this.props.projectId, {
                                                                                            from: Math.floor(Date.now() / 1000) - 60 * 5,
                                                                                            to: Math.floor(Date.now() / 1000) + 60,
                                                                                            filters: {
                                                                                                requestId: {
                                                                                                    value: this.state.callResponse!.headers['apigw-requestid'],
                                                                                                    operator: 'EQ',
                                                                                                },
                                                                                            },
                                                                                        }),
                                                                                        '_blank'
                                                                                    )
                                                                                }}
                                                                            >
                                                                                View In Logs  (~1 min)
                                                                            </Button>
                                                                        </>
                                                                    ) : null}
                                                                </Tabs.TabPane>
                                                            </Tabs>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Empty description={'No Response'} />
                                                        </>
                                                    )}
                                                </CustomSpinner>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null
                            }
                        </RootProjectContext.Consumer>
                    )}
                </ThemeContext.Consumer>
            </>
        )
    }
}
