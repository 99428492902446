import React, {Component} from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import CloudObjectLayout from "../CloudObjectLayout";
import CoEditorLayout from "../CoEditor/CoEditorLayout";
import CosDebugLayout from "../CosLayouts/CosDebugLayout";
import {RootProjectContext} from "../../Contexts/RootProjectContext";
import {ThisProjectContext} from "../../Contexts/ThisProjectContext";


interface Props {
}

interface State {
}


class CloudObjectDetailLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        <div>
                            {
                                ctx && ctx.rootRbsSdk ? <>
                                    <Switch>
                                        <Route path="/project/:projectId/Classes/:classId/instance/:instanceId"
                                               render={(props: RouteComponentProps<{ projectId: string, classId: string, instanceId: string }>) => {
                                                   return <CosDebugLayout
                                                       rootProjectSdk={ctx.rootRbsSdk!}
                                                       routeComponentProps={props}/>
                                               }}>
                                        </Route>
                                        <Route path="/project/:projectId/Classes/:classId/instance"
                                               render={(props: RouteComponentProps<{ projectId: string, classId: string }>) => {
                                                   return <CosDebugLayout
                                                       rootProjectSdk={ctx.rootRbsSdk!}
                                                       routeComponentProps={props}/>
                                               }}>
                                        </Route>
                                        <Route path="/project/:projectId/Classes/:classId"
                                               render={(props: RouteComponentProps<{ projectId: string, classId: string }>) => {
                                                   return <CoEditorLayout rootProjectSdk={ctx.rootRbsSdk!}
                                                                          routeComponentProps={props}/>
                                               }}>
                                        </Route>
                                        <Route path="/project/:projectId/Classes"
                                               render={(props: RouteComponentProps<{ projectId: string }>) => {
                                                   return <CloudObjectLayout
                                                       routeComponentProps={props}/>
                                               }}>
                                        </Route>
                                    </Switch>
                                </> : 'context not found'
                            }

                        </div>
                    )}
                </RootProjectContext.Consumer>
            </>
        );
    }

}

CloudObjectDetailLayout.contextType = ThisProjectContext

export default CloudObjectDetailLayout