import React, { Component } from 'react'
import { Button, Input, notification, Popconfirm, Table } from 'antd'
import { EditModelModal } from './EditModelModal'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { CreateModelModal } from './CreateModelModal'
import Retter, { RetterCloudObject } from '@retter/sdk'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import CustomSpinner from '../../Components/CustomSpinner'
import { ModelDetailModal } from './ModelDetailModal'
import LogsLayout from '../LogsLayout'
import _ from 'lodash'
import RBS from '@retter/sdk'
import { ModelDefinitions } from '../../Interfaces/IProjectSummary'

export interface ModelObj {
    modelName: string
    data: object
    key: string
    sortKey: string
}

interface Props {
    projectId: string
    rootRbsSdk: RBS
}

interface State {
    loading: boolean
    filteredModels: ModelObj[]
    searchBoxText: string
    modelDefinitions: ModelDefinitions
    models: ModelObj[]
}

class ModelListComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            searchBoxText: '',
            loading: true,
            filteredModels: [],
            models: [],
            modelDefinitions: {},
        }
        this.deleteModel = this.deleteModel.bind(this)
        this.searchModel = this.searchModel.bind(this)
        this.getModels = this.getModels.bind(this)
    }

    async getModels() {
        this.setState({ loading: true })
        const projectInstance = await this.props.rootRbsSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: this.props.projectId,
        })
        try {
            const resp: any = await projectInstance.call<any>({
                method: RootProjectClassMethods.getModelDefinitions,
            })
            if (resp) {
                const models = Object.keys(resp.data || {}).map(o => {
                    return {
                        key: o,
                        data: (resp.data || {})[o],
                        modelName: o,
                        sortKey: o.toLowerCase(),
                    }
                })
                this.setState({
                    modelDefinitions: resp.data,
                    models,
                    filteredModels: _.sortBy(
                        (models || []).filter(m => {
                            return m.modelName.toLowerCase().includes(this.state.searchBoxText)
                        }),
                        'sortKey'
                    ),
                })
            }
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false })
    }

    async componentDidMount() {
        await this.getModels()
    }

    searchModel(key: string) {
        this.setState({
            searchBoxText: key,
            filteredModels: _.sortBy(
                this.state.models.filter(m => {
                    return m.modelName.toLowerCase().includes(key.toLowerCase())
                }),
                'sortKey'
            ),
        })
    }

    async deleteModel(modelName: string, classInstance: RetterCloudObject) {
        this.setState({ loading: true })

        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.setContents,
                body: {
                    models: {
                        [modelName]: {
                            name: modelName,
                            content: undefined,
                        },
                    },
                },
            })
            await this.getModels()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx =>
                        ctx ? (
                            <>
                                <CustomSpinner spinning={this.state.loading}>
                                    <Input.Search
                                        value={this.state.searchBoxText}
                                        autoFocus={true}
                                        placeholder="Model Name"
                                        onChange={e => {
                                            this.searchModel(e.target.value)
                                        }}
                                        style={{ width: 300 }}
                                    />
                                    <Button
                                        type={'link'}
                                        onClick={async () => {
                                            await this.getModels()
                                        }}
                                    >
                                        Refresh
                                    </Button>
                                    <br />
                                    <br />
                                    <Table
                                        pagination={{ pageSize: 50 }}
                                        columns={[
                                            {
                                                title: (
                                                    <>
                                                        Model Name{' '}
                                                        <CreateModelModal
                                                            onSuccess={async () => {
                                                                await this.getModels()
                                                            }}
                                                            modelDefinitions={this.state.modelDefinitions}
                                                            classInstance={ctx.instance!}
                                                        />
                                                    </>
                                                ),
                                                key: 'name',
                                                render: (model: { modelName: string }) => model.modelName,
                                            },
                                            {
                                                title: <>Def</>,
                                                key: 'def',
                                                render: (model: ModelObj) => <ModelDetailModal model={model} />,
                                            },
                                            {
                                                title: '',
                                                key: 'operation',
                                                render: (model: { modelName: string }) => {
                                                    return (
                                                        <>
                                                            <EditModelModal
                                                                onSuccess={async () => {
                                                                    await this.getModels()
                                                                }}
                                                                modelName={model.modelName}
                                                                modelDefinitions={this.state.modelDefinitions}
                                                            />
                                                            <Popconfirm
                                                                title="Are you sure to delete this model?"
                                                                onConfirm={async () => {
                                                                    await this.deleteModel(model.modelName, ctx.instance!)
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type={'link'} danger>
                                                                    Delete
                                                                </Button>
                                                            </Popconfirm>
                                                        </>
                                                    )
                                                },
                                            },
                                        ]}
                                        dataSource={this.state.filteredModels}
                                    />
                                </CustomSpinner>
                            </>
                        ) : null
                    }
                </RootProjectContext.Consumer>
            </>
        )
    }
}

LogsLayout.contextType = RootProjectContext

export default ModelListComponent
