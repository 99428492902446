import React, { Component } from 'react'
import { Button, message, Modal, notification, Tooltip } from 'antd'
import Editor, { Monaco } from '@monaco-editor/react'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { CopyOutlined } from '@ant-design/icons'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import Retter from '@retter/sdk'
import { ModelObj } from './ModelListComponent'
import { ThemeContext } from '../../Contexts/ThemeContext'

interface Props {
    model: ModelObj
}

interface State {
    visible: boolean
}

export class ModelDetailModal extends Component<Props, State> {
    monaco?: Monaco

    constructor(props: Props) {
        super(props)
        this.state = {
            visible: false,
        }
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx =>
                        ctx ? (
                            <>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        this.setState({ visible: true })
                                    }}
                                >
                                    {'#/$defs/'.concat(this.props.model.modelName)}
                                </Button>
                                <Tooltip placement="top" title={`Copy model def`}>
                                    <a
                                        onClick={async () => {
                                            await navigator.clipboard.writeText('#/$defs/'.concat(this.props.model.modelName))
                                            message.success(
                                                <>
                                                    <b>{'#/$defs/'.concat(this.props.model.modelName)}</b> Model def copied!
                                                </>
                                            )
                                        }}
                                        style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                    >
                                        <CopyOutlined />
                                    </a>
                                </Tooltip>
                                <Modal
                                    title={'#/$defs/'.concat(this.props.model.modelName)}
                                    open={this.state.visible}
                                    width={800}
                                    onOk={() => {
                                        this.setState({ visible: false })
                                    }}
                                    onCancel={() => {
                                        this.setState({ visible: false })
                                    }}
                                >
                                    <ThemeContext.Consumer>
                                        {({ isDarkMode }) => (
                                            <Editor
                                                options={{
                                                    readOnly: true,
                                                    minimap: {
                                                        enabled: false,
                                                    },
                                                }}
                                                height="60vh"
                                                language={'json'}
                                                defaultValue={JSON.stringify(this.props.model.data, null, 2)}
                                                theme={isDarkMode ? 'vs-dark' : 'light'}
                                            />
                                        )}
                                    </ThemeContext.Consumer>
                                </Modal>
                            </>
                        ) : null
                    }
                </RootProjectContext.Consumer>
            </>
        )
    }
}
