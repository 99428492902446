export interface ICloudObjectBaseFields {
    createdAt: string
    updatedAt: string
    deletedAt: string | undefined
    updateToken: string
}


export interface ICloudObjectClass extends ICloudObjectBaseFields {
    classId: string
}

export interface ICloudObjectClassFile extends ICloudObjectBaseFields {
    classId: string
    fileName: string
    fileContext: string
}

export enum COSFileStatus {
    EDITED = 'EDITED',
    DELETED = 'DELETED',
    ADDED = 'ADDED',
    NONE= 'NONE'
}

export interface ISaveCloudObjectFileModel {
    name: string
    content: string
    status: COSFileStatus
    _updateToken?: string
}