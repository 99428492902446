import React, { Component } from 'react'
import { Tabs } from 'antd'
import ConfigLayout from './ConfigLayout'
import DangerZone from './DangerZone'
import EnvsLayout from './EnvsLayout'
import LogAdapters from './LogAdapters'
import DestinationAdapters from './DestinationAdapters'
import Packages from './Packages'
import AuthenticationRules from './AuthenticationRules'

interface Props {}

interface State {}

export class SettingsLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <>
                <Tabs destroyInactiveTabPane>
                    <Tabs.TabPane tab="Config" key="1">
                        <ConfigLayout />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Log Adapters" key="Log_Adapters">
                        <LogAdapters />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Destination Adapters" key="Destination_Adapters">
                        <DestinationAdapters />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Environments" key="3">
                        <EnvsLayout />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="package.json" key="4">
                        <Packages />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Authenticaton Rules" key="5">
                        <AuthenticationRules />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Danger Zone" key="6">
                        <DangerZone />
                    </Tabs.TabPane>
                    {/*
                        <Tabs.TabPane tab="Rate Limit Options" key="2">
                        <RateLimitConfigLayout/>
                    </Tabs.TabPane>
                         */}
                </Tabs>
            </>
        )
    }
}
