import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { ServiceManifest } from '../Interfaces/Service'
import Editor from '@monaco-editor/react'
import { ThemeContext } from '../Contexts/ThemeContext'

export interface Props {
    manifest: ServiceManifest
}

export const ShowManifestAsJsonModal = (props: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <Button type="link" onClick={showModal}>
                Show Manifest as Json
            </Button>
            <Modal width={1000} title="Manifest As Json" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <ThemeContext.Consumer>
                    {({ isDarkMode }) => (
                        <Editor
                            height={'55vh'}
                            language={'json'}
                            theme={isDarkMode ? 'vs-dark' : 'light'}
                            defaultValue={JSON.stringify(props.manifest, null, 2)}
                            options={{ readOnly: true }}
                        />
                    )}
                </ThemeContext.Consumer>
            </Modal>
        </>
    )
}
