import React, {Component} from "react";
import {Button, notification, Table} from "antd";
import {APIService, RootProjectClassEnums, RootProjectClassMethods} from "../../../Api/APIService";
import {LookupKeyItem, SortSetListResponse} from "../../../Interfaces/CosInterfaces";
import CustomSpinner from "../../../Components/CustomSpinner";
import RBS from "@retter/sdk";


export interface Props {
    projectId: string
    classId: string
    instanceId: string
    rootRbsSdk: RBS
}

export interface State {
    loading: boolean
    sortedSetListResponse: SortSetListResponse
}

export class CosSortedSetList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            sortedSetListResponse: {sortedSetItems: []}
        }
        this.init = this.init.bind(this)
    }

    async componentDidMount() {
        await this.init()
    }

    async init() {
        this.setState({loading: true})
        const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
            classId: RootProjectClassEnums.RetterClass,
            instanceId: this.props.projectId +'_'+this.props.classId,
        })
        const response = await retterClassInstance.call<any>({
            method: RootProjectClassMethods.listSortedSetItems,
            body: {
                instanceId: this.props.instanceId
            }
        })
        if(!response || response.status >= 400){
            notification.error({
                placement: 'bottomRight',
                message: response ? response.data : 'error'
            })
            return false
        }
        if (response) {
            this.setState({sortedSetListResponse: response.data})
        }
        this.setState({loading: false})
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Table
                        pagination={{defaultPageSize: 50}}
                        dataSource={this.state.sortedSetListResponse.sortedSetItems.map(c => {
                        return {key: c.sortKey + '$' + c.setName, name: c.sortKey, value: c.setName}
                    })} columns={[
                        {
                            title: <>Name <Button type={'link'} onClick={async () => {
                                await this.init()
                            }}>Refresh</Button></>,
                            key: 'name',
                            render: (item: LookupKeyItem) => {
                                return item.value
                            }
                        },
                        {
                            title: 'Key',
                            key: 'key',
                            render: (item: LookupKeyItem) => {
                                return item.name
                            }
                        },
                    ]}/>
                </CustomSpinner>
            </>
        );
    }

}
