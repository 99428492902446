import React, {useState} from 'react';
import {Button, Divider, Form, Input, notification} from "antd";
import Retter, {RetterCloudObject} from "@retter/sdk";
import {APIService, RootProjectClassEnums, RootProjectClassMethods} from "../Api/APIService";

interface Props {
    loading: boolean;
    onSignInPressed: () => void;
    onInvitationConfirmPressed: (code: string)=>Promise<void>
    apiService: APIService
}

interface Values {
    code: string;
}

const ConfirmInvitationForm = (props: Props) => {
    let [loading, setLoading] = useState(false);

    const onFinish = async (values: Values) => {
        setLoading(true)
        await props.onInvitationConfirmPressed(values.code?.trim())
        setLoading(false)
    }

    return (
        <Form
            size={"large"}
            name="confirm-sign-in"
            className="invitation-confirm-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            <Form.Item
                name="code"
                rules={[
                    {required: true, message: "Please input your Invitation Code!"}
                ]}
            >
                <Input
                    placeholder="Invitation Code"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    style={{marginBottom: 10}}
                    block
                    type="primary"
                    htmlType="submit"
                    className="invitation-confirm-form"
                    loading={loading}
                >
                    Confirm Invitation
                </Button>
                <Divider plain>Or</Divider>
                <span
                    onClick={props.onSignInPressed}
                    className="login-form-forgot"
                    style={{color: '#1890ff', cursor: 'pointer'}}
                >
          Log In
        </span>
            </Form.Item>
        </Form>
    )
}

export default ConfirmInvitationForm;
