import React, { Component } from 'react'
import CustomSpinner from '../../Components/CustomSpinner'
import { Button, Form, Input, notification } from 'antd'
import { ProjectConfig } from '../../Interfaces/IProjectSummary'
import { IProjectContext, RootProjectContext } from '../../Contexts/RootProjectContext'
import { RootProjectClassMethods } from '../../Api/APIService'

interface Props {}

interface State {
    loading: boolean
    changedProjectConfigData: any
    projectEnvironments: string
    projectConfig?: ProjectConfig
}

class ConfigLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            changedProjectConfigData: {},
            projectEnvironments: '',
        }
        this.updateConfig = this.updateConfig.bind(this)
        this.onValuesChangeHandler = this.onValuesChangeHandler.bind(this)
        this.onFinish = this.onFinish.bind(this)
    }

    componentDidMount() {
        this.ctx = this.context
        this.setState({
            loading: false,
        })
    }

    async updateConfig(values: { alias: string }) {
        if (!this.ctx) throw new Error('ctx not found')
        this.setState({ loading: true })
        try {
            await this.ctx.instance?.call<any>({
                method: RootProjectClassMethods.updateAlias,
                body: {
                    alias: values.alias,
                },
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false })
    }

    onValuesChangeHandler(changedValues: any, values: any) {
        this.setState({ changedProjectConfigData: { ...this.state.changedProjectConfigData, ...values } })
    }

    onFinish = async (values: { alias: string }) => {
        await this.updateConfig(values)
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <>
                            {ctx && ctx.detail ? (
                                <>
                                    <CustomSpinner spinning={this.state.loading}>
                                        <Form layout={'vertical'} name="basic" onFinish={this.onFinish}>
                                            <Form.Item label="Alias" name="alias">
                                                <Input defaultValue={ctx.detail.alias} placeholder={'Alias'} />
                                            </Form.Item>
                                            <Button htmlType={'submit'} key="save" type={'primary'} loading={this.state.loading}>
                                                Save
                                            </Button>
                                        </Form>
                                    </CustomSpinner>
                                </>
                            ) : null}
                        </>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

ConfigLayout.contextType = RootProjectContext

export default ConfigLayout
