import React, { Component } from 'react'
import { Button, Divider, notification } from 'antd'
import { IProjectContext, RootProjectContext } from '../../Contexts/RootProjectContext'
import Editor from '@monaco-editor/react'
import { RetterCloudObject } from '@retter/sdk'
import { RootProjectClassMethods } from '../../Api/APIService'
import CustomSpinner from '../../Components/CustomSpinner'
import { ThemeContext } from '../../Contexts/ThemeContext'

interface Props {}

interface State {
    envVars?: object
    changedEnv?: string
    loading: boolean
}

class EnvsLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        this.ctx = this.context

        this.getEnv()
    }

    async getEnv() {
        if (!this.ctx?.instance) return
        this.setState({
            loading: true,
        })

        try {
            const res = await this.ctx.instance.call<any>({
                method: RootProjectClassMethods.getEnvironmentVariables,
            })
            const sortedObject = Object.fromEntries(
                Object.entries(res.data).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            );
            this.setState({
                envVars: sortedObject,
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    async save(classInstance: RetterCloudObject) {
        if (!this.state.changedEnv) return false
        this.setState({
            loading: true,
        })
        let envs
        try {
            envs = JSON.parse(this.state.changedEnv)
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: 'Invalid json',
            })
            this.setState({
                loading: false,
            })
            return
        }

        if (typeof envs !== 'object') {
            notification.error({
                placement: 'bottomRight',
                message: 'Enviroment must be Record<string, string>',
            })
            this.setState({
                loading: false,
            })
            return
        }

        for (const key in envs) {
            if (typeof envs[key] !== 'string') {
                notification.error({
                    placement: 'bottomRight',
                    message: 'Enviroment must be Record<string, string>',
                })
                this.setState({
                    loading: false,
                })
                return
            }
        }

        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.setEnvironmentVariables,
                body: {
                    environmentVariables: envs,
                },
            })
            await this.getEnv()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <>
                            <CustomSpinner spinning={this.state.loading}>
                                <Button
                                    onClick={async () => {
                                        await this.save(ctx.instance!)
                                    }}
                                >
                                    Save
                                </Button>
                                <Divider dashed />
                                <ThemeContext.Consumer>
                                    {({ isDarkMode }) => (
                                        <Editor
                                            language={'json'}
                                            height={'70vh'}
                                            defaultValue={JSON.stringify(this.state.envVars || {}, null, 2)}
                                            value={this.state.envVars ? JSON.stringify(this.state.envVars || {}, null, 2) : '{}'}
                                            onChange={v => {
                                                this.setState({ changedEnv: v })
                                            }}
                                            theme={isDarkMode ? 'vs-dark' : 'light'}
                                        />
                                    )}
                                </ThemeContext.Consumer>
                            </CustomSpinner>
                        </>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

EnvsLayout.contextType = RootProjectContext

export default EnvsLayout
