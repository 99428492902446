import React from 'react';
import {Button, Divider, Form, Input} from "antd";
import { UserOutlined } from '@ant-design/icons';

interface Props {
  loading: boolean;
  onSignInPressed: () => void
  onForgotPasswordPressed: (email: string) => void
}

interface Values {
  email: string;
}

const ForgotPasswordForm = ({loading, onSignInPressed, onForgotPasswordPressed}: Props) => {
  const onFinish = (values: Values) => {
    onForgotPasswordPressed(values.email)
  }

  return (
    <Form
        size={'large'}
      name="forgot-password-form"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'The input is not valid E-mail!'},
          { required: true, message: "Please input your Email!" }
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
         
      <Form.Item>
        <Button 
          style={{marginBottom: 10}} 
          block 
          type="primary" 
          htmlType="submit" 
          className="login-form-button"
          loading={loading}
        >
          Reset Password
        </Button>
        <Divider plain>Or</Divider>
        <span 
          onClick={onSignInPressed}
          className="login-form-forgot" 
          style={{color: '#1890ff', cursor: 'pointer'}}
        >
          Log In
        </span>
      </Form.Item>
    </Form>
  )
}

export default ForgotPasswordForm;