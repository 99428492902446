import React from 'react'
import ReactDOM from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import autoGenConfig from './config/autoGenConfig'

ReactDOM.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider useEnterprise={true} reCaptchaKey={autoGenConfig.captchaKey} scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
            <App />
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals(console.log)
