import React, { Component, MouseEvent } from 'react'
import { Affix, Breadcrumb, Button, Input, Layout, Menu, message, Modal, Result, Skeleton, Tooltip, Typography } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { ActionTypes, topMenuStore } from '../../../Actions/Actions'
import CustomSpinner from '../../../Components/CustomSpinner'
import {
    AppstoreOutlined,
    BlockOutlined,
    CopyOutlined,
    DashboardOutlined,
    DatabaseOutlined,
    FileOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    NodeIndexOutlined,
    ReconciliationOutlined,
    ScanOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons'
import { Unsubscribe } from 'redux'
import { IProjectDetail } from '../../../Actions/Interfaces/IProject'
import { IProjectContext, RootProjectContext } from '../../../Contexts/RootProjectContext'
import { GlobalHelpers } from '../../../GlobalHelpers'
import { IThisProjectContext } from '../../../Contexts/ThisProjectContext'
import CloudObjectDetailLayout from '../../CosLayouts/CloudObjectDetailLayout'
import ModelListComponent from '../../ModelsLayout/ModelListComponent'
import { ProjectDeploymentLayout } from '../../ProjectDeploymentLayout'
import IAMLayout from '../../IAMLayout'
import LogsLayout from '../../LogsLayout'
import { SettingsLayout } from '../../ProjectSettings/SettingsLayout'
import { DependenciesLayout } from '../../DependenciesLayout'
import { FilesLayout } from '../../FilesLayout'
import { MemoryLayout } from '../../MemoryLayout'
import DashboardLayout from '../../DashboardLayout'
import ScanJobsLayout from '../../ScanJobsLayout'
import { withAntdToken } from '../../../Components/with-antd-token-hoc'

const { Sider } = Layout
const { Text, Link } = Typography

interface Props {
    token: any
    routeComponentProps: RouteComponentProps<{ projectId: string; tab: string }>
}

interface State {
    project?: IProjectDetail
    loading: boolean
    apiKeyStatus: string
    configStatus: string
    activeMenu: string
    projectServices: Array<any>
    projectServicesUpdating: boolean
    projectDeleting: boolean
    projectNotFound: boolean
    deleteModalVisible: boolean
    deleteModalConfirmText: string
    activeTab: string
    leftMenuCollapsed: boolean
    unsub?: Unsubscribe
    thisProjectContext: IThisProjectContext
}

const tabKeys = ['Dashboard', 'Classes', 'Models', 'Dependencies', 'Files', 'Memory', 'Scan Jobs', 'Deployment', 'Logs', 'Members', 'Settings']
const tabIcons = [
    <DashboardOutlined />,
    <NodeIndexOutlined />,
    <AppstoreOutlined />,
    <BlockOutlined />,
    <FileOutlined />,
    <DatabaseOutlined />,
    <ScanOutlined />,
    //<DeploymentUnitOutlined/>,
    <UnorderedListOutlined />,
    <ReconciliationOutlined />,
    <UsergroupAddOutlined />,
    <SettingOutlined />,
]

class ProjectOverviewContent extends Component<Props, State> {
    private readonly projectId: string
    private ctx?: IProjectContext

    constructor(props: Props) {
        super(props)

        this.projectId = props.routeComponentProps.match.params.projectId

        let activeTab = props.routeComponentProps.match.params.tab
        if (props.routeComponentProps.match.params.tab === 'Overview') {
            activeTab = tabKeys.includes(props.routeComponentProps.match.params.tab) ? props.routeComponentProps.match.params.tab : tabKeys[0]
            this.props.routeComponentProps.history.replace(`/project/${this.projectId}/${activeTab}`)
        }

        this.state = {
            activeMenu: 'projectOverview',
            loading: true,
            apiKeyStatus: '',
            configStatus: '',
            projectServices: [],
            projectServicesUpdating: false,
            projectDeleting: false,
            projectNotFound: false,
            deleteModalVisible: false,
            deleteModalConfirmText: '',
            activeTab,
            leftMenuCollapsed: localStorage.getItem('RBS_LEFT_MENU_COLLAPSED') !== 'true',
            thisProjectContext: {},
        }
        this.deleteProjectHandler = this.deleteProjectHandler.bind(this)
        this.tabChanged = this.tabChanged.bind(this)
        this.onClickLeftMenu = this.onClickLeftMenu.bind(this)
        this.prepareBreadCrumb = this.prepareBreadCrumb.bind(this)
    }

    getTabTitle = (tabKey: string) => {
        try {
            return tabKeys[tabKeys.findIndex(t => t === tabKey)]
        } catch (e: any) {
            return ''
        }
    }

    prepareBreadCrumb(path: string) {
        if (!this.ctx?.detail) return false
        const crumbs = path.split('/').filter(c => c !== '')
        const tab = crumbs[2]
        const classId = crumbs[3]
        const instanceId = crumbs[5]
        return (
            <Breadcrumb style={{ fontSize: 'small' }}>
                <Breadcrumb.Item>
                    <Link
                        type={'secondary'}
                        onClick={e => {
                            this.onClickLeftMenu(GlobalHelpers.newTab(e), tabKeys[0])
                        }}
                    >
                        {this.ctx.detail.alias}
                    </Link>
                    &nbsp;&nbsp;
                    <Tooltip placement="bottom" title={`Copy project id`}>
                        <a onClick={this.copyProjectId} >
                            <CopyOutlined />
                        </a>
                    </Tooltip>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link
                        type={'secondary'}
                        onClick={e => {
                            this.onClickLeftMenu(GlobalHelpers.newTab(e), tab)
                        }}
                    >
                        {this.getTabTitle(tab)}
                    </Link>
                </Breadcrumb.Item>
                {classId ? (
                    <Breadcrumb.Item>
                        <Link
                            type={'secondary'}
                            onClick={e => {
                                console.log('GlobalHelpers.newTab(e)', e)
                                if (GlobalHelpers.newTab(e)) GlobalHelpers.openInNewTab(`${window.location.origin}/project/${this.projectId}/Classes/${classId}`)
                                else this.props.routeComponentProps.history.push(`/project/${this.projectId}/Classes/${classId}`)
                            }}
                        >
                            {classId}
                        </Link>
                    </Breadcrumb.Item>
                ) : null}
                {instanceId ? (
                    <Breadcrumb.Item>
                        <Text type={'secondary'}>
                            {instanceId}
                            &nbsp;&nbsp;
                            <Tooltip placement="bottom" title={`Copy instance id`}>
                                <a
                                    onClick={async () => {
                                        await navigator.clipboard.writeText(instanceId)
                                        message.success(
                                            <>
                                                <b>{instanceId}</b> Instance id copied!
                                            </>
                                        )
                                    }}
                                >
                                    <CopyOutlined />
                                </a>
                            </Tooltip>
                        </Text>
                    </Breadcrumb.Item>
                ) : null}
            </Breadcrumb>
        )
    }

    tabChanged(key: string) {
        this.props.routeComponentProps.history.push(`/project/${this.projectId}/${key}`)
    }

    onClickLeftMenu(newTab: boolean, key: string) {
        if (newTab) GlobalHelpers.openInNewTab(`${window.location.origin}/project/${this.projectId}/${key}`)
        else {
            this.props.routeComponentProps.history.push(`/project/${this.projectId}/${key}`)
            this.setState({
                activeTab: key,
            })
        }
    }

    copyProjectId = async () => {
        if (this.projectId) {
            await navigator.clipboard.writeText(this.projectId)
            message.success(
                <>
                    <b>{this.projectId}</b> Project id copied!
                </>
            )
        }
    }

    async componentDidMount() {
        this.ctx = this.context

        const thisProjectRbsSdk = GlobalHelpers.getRbsInstanceByProjectId(this.projectId)

        this.setState({
            thisProjectContext: {
                thisRbsSdk: thisProjectRbsSdk,
            },
        })

        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
            data: {
                extraMenu: [],
                title: <>{this.prepareBreadCrumb(this.props.routeComponentProps.location.pathname)}</>,
            },
        })
        const unsub = this.props.routeComponentProps.history.listen((l, a) => {
            topMenuStore.dispatch({
                type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
                data: {
                    title: <>{this.prepareBreadCrumb(l.pathname)}</>,
                },
            })
        })
        this.setState({
            unsub,
            loading: false,
        })
    }

    componentWillUnmount() {
        if (this.state.unsub) this.state.unsub()
    }

    async deleteProjectHandler() {
        this.setState({ projectDeleting: true })
        const resp = undefined //await this.apiService.deleteProject(this.projectId);
        this.setState({
            projectDeleting: false,
            deleteModalVisible: false,
            deleteModalConfirmText: '',
        })
        if (resp) this.setState({ projectNotFound: true })
    }

    async onProjectDelete() {
        await this.deleteProjectHandler()
        return
    }

    render() {
        const { deleteModalVisible, deleteModalConfirmText, projectDeleting } = this.state

        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <CustomSpinner spinning={this.state.loading}>
                            {!this.state.projectNotFound ? (
                                <>
                                    {ctx && ctx.rootRbsSdk ? (
                                        <>
                                            <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
                                                <Sider collapsed={this.state.leftMenuCollapsed} style={{ backgroundColor: this.props.token.colorBgContainer }}>
                                                    <Affix offsetTop={0}>
                                                        <Menu mode={'inline'} selectedKeys={[this.state.activeTab]}>
                                                            <Menu.Item
                                                                key={'collapse_menu'}
                                                                icon={React.createElement(this.state.leftMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                                                                onClick={() => {
                                                                    localStorage.setItem('RBS_LEFT_MENU_COLLAPSED', this.state.leftMenuCollapsed ? 'true' : 'false')
                                                                    this.setState({ leftMenuCollapsed: !this.state.leftMenuCollapsed })
                                                                }}
                                                            ></Menu.Item>
                                                            {tabKeys.map((key, index) => {
                                                                if (!['Deployment'].includes(key)) {
                                                                    return (
                                                                        <Menu.Item
                                                                            key={key}
                                                                            icon={tabIcons[index]}
                                                                            onClick={e => {
                                                                                this.onClickLeftMenu(GlobalHelpers.newTab(e.domEvent as MouseEvent), key)
                                                                            }}
                                                                        >
                                                                            {key}
                                                                        </Menu.Item>
                                                                    )
                                                                }
                                                            })}
                                                        </Menu>
                                                    </Affix>
                                                </Sider>
                                                <Layout className="site-layout" style={{ margin: 16 }}>
                                                    {this.state.activeTab === 'Classes' ? (
                                                        <>
                                                            <CloudObjectDetailLayout />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Models' ? (
                                                        <>
                                                            <ModelListComponent rootRbsSdk={ctx?.rootRbsSdk} projectId={this.projectId} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Deployment' ? (
                                                        <>
                                                            <ProjectDeploymentLayout projectId={this.props.routeComponentProps.match.params.projectId} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Members' ? (
                                                        <>
                                                            <IAMLayout projectId={this.props.routeComponentProps.match.params.projectId} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Logs' ? (
                                                        <>
                                                            <LogsLayout projectId={this.props.routeComponentProps.match.params.projectId} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Settings' ? (
                                                        <>
                                                            <SettingsLayout />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Dependencies' ? (
                                                        <>
                                                            <DependenciesLayout />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Dashboard' ? (
                                                        <>
                                                            <DashboardLayout />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Files' ? (
                                                        <>
                                                            <FilesLayout projectId={this.projectId} rootRbsSdk={ctx?.rootRbsSdk} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Memory' ? (
                                                        <>
                                                            <MemoryLayout projectId={this.projectId} rootRbsSdk={ctx?.rootRbsSdk} />
                                                        </>
                                                    ) : null}
                                                    {this.state.activeTab === 'Scan Jobs' ? (
                                                        <>
                                                            <ScanJobsLayout />
                                                        </>
                                                    ) : null}
                                                </Layout>
                                            </Layout>
                                        </>
                                    ) : (
                                        <Skeleton loading={this.state.loading} />
                                    )}
                                </>
                            ) : (
                                <Result
                                    status="warning"
                                    title="Project not found!"
                                    extra={
                                        <Button type="primary" key="console" href={'/'}>
                                            Go to Homepage
                                        </Button>
                                    }
                                />
                            )}
                            <Modal
                                title="Are you absolutely sure?"
                                open={deleteModalVisible}
                                onOk={() => this.onProjectDelete()}
                                confirmLoading={projectDeleting}
                                onCancel={() => this.setState({ deleteModalVisible: false })}
                                footer={[
                                    <Button
                                        style={{ width: '100%' }}
                                        disabled={deleteModalConfirmText !== 'permanently delete'}
                                        key="submit"
                                        danger
                                        loading={projectDeleting}
                                        onClick={() => this.onProjectDelete()}
                                    >
                                        I understand, delete the project
                                    </Button>,
                                ]}
                            >
                                <p>This action cannot be undone. This will permanently delete the project.</p>
                                <p>
                                    Please type <b>permanently delete</b> to confirm.
                                </p>
                                <Input value={deleteModalConfirmText} onChange={e => this.setState({ deleteModalConfirmText: e.target.value })} />
                            </Modal>
                        </CustomSpinner>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

ProjectOverviewContent.contextType = RootProjectContext

export default withAntdToken(ProjectOverviewContent)
