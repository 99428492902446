import {createStore} from "redux";
import {notification} from 'antd';


export const ActionTypes = {
    PROJECT: {
        types: {
            CREATING: "PROJECT_CREATING",
            CREATED: "PROJECT_CREATED",
            UPDATING: "PROJECT_UPDATING",
            UPDATED: "PROJECT_UPDATED",
            CONFIG_UPDATING: "CONFIG_UPDATING",
            CONFIG_UPDATED: "CONFIG_UPDATED",
            ERROR: "PROJECT_ERROR"
        }
    },
    API_KEY: {
        types: {
            CREATING: "API_KEY_CREATING",
            CREATED: "API_KEY_CREATED",
            DELETING: "API_KEY_DELETING",
            DELETED: "API_KEY_DELETED",
            ERROR: "API_KEY_ERROR"
        }
    },
    USER: {
        types: {
            LOADED: "USER_LOADED"
        }
    },
    SERVICE_CONFIG: {
        types: {
            SERVICE_CONFIG_CHANGED: "SERVICE_CONFIG_CHANGED",
            SERVICE_CONFIG_CHANGES_UPDATED: "SERVICE_CONFIG_CHANGES_UPDATED",
            SERVICE_CONFIG_UPDATING: "SERVICE_CONFIG_UPDATING",
            SERVICE_CONFIG_UPDATED: "SERVICE_CONFIG_UPDATED",
            SERVICE_CONFIG_UPDATE_ERROR: "SERVICE_CONFIG_UPDATE_ERROR"

        }
    },
    PRODUCT_SERVICE_OPTIONS: {
        types: {
            PRODUCT_SERVICE_OPTIONS_FETCH: "PRODUCT_SERVICE_OPTIONS_FETCH",
            PRODUCT_SERVICE_OPTIONS_FETCHING: "PRODUCT_SERVICE_OPTIONS_FETCHING",
            PRODUCT_SERVICE_OPTIONS_FETCHED: "PRODUCT_SERVICE_OPTIONS_FETCHED",
            PRODUCT_SERVICE_OPTIONS_FETCH_ERROR: "PRODUCT_SERVICE_OPTIONS_FETCH_ERROR",
        }
    },
    PROJECT_SERVICES_CONFIG: {
        types: {
            UPDATING: "PROJECT_SERVICES_CONFIG_UPDATING",
            UPDATED: "PROJECT_SERVICES_CONFIG_UPDATED",
            ERROR: "PROJECT_SERVICES_CONFIG_ERROR"
        }
    },
    DEVELOPER_SERVICE: {
        types: {
            CREATING: "DEVELOPER_SERVICE_UPDATING",
            CREATED: "DEVELOPER_SERVICE_CREATED",
            CREATE_ERROR: "DEVELOPER_SERVICE_CREATE_ERROR",
            UPDATING: "DEVELOPER_SERVICE_UPDATING",
            UPDATED: "DEVELOPER_SERVICE_UPDATED",
            ERROR: "DEVELOPER_SERVICE_ERROR"
        }
    },
    PROJECT_USER_ROLES: {
        types: {
            UPDATING: "PROJECT_USER_ROLES_UPDATING",
            UPDATED: "PROJECT_USER_ROLES_UPDATED",
            ERROR: "PROJECT_USER_ROLES_ERROR"
        }
    },
    PROJECT_ATTACHED_SERVICE_ROLES: {
        types: {
            UPDATING: "PROJECT_ATTACHED_SERVICE_ROLES_UPDATING",
            UPDATED: "PROJECT_ATTACHED_SERVICE_ROLES_UPDATED",
            ERROR: "PROJECT_ATTACHED_SERVICE_ROLES_ERROR"
        }
    },
    TOP_MENU_CHANGED: {
        types: {
            CHANGED: "TOP_MENU_CHANGED"
        }
    }
}

export enum CosActionStoreTypes {
    FETCHING = "FETCHING",
    FETCHING_ERROR = "FETCHING_ERROR",
    FETCHED = "FETCHED",
}

function ProductServiceOptionsForm(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.PRODUCT_SERVICE_OPTIONS.types.PRODUCT_SERVICE_OPTIONS_FETCH:
            return {state: action.type, data: action.data}
        case ActionTypes.PRODUCT_SERVICE_OPTIONS.types.PRODUCT_SERVICE_OPTIONS_FETCHING:
            return {state: action.type}
        case ActionTypes.PRODUCT_SERVICE_OPTIONS.types.PRODUCT_SERVICE_OPTIONS_FETCHED:
            return {state: action.type, data: action.data}
        case ActionTypes.PRODUCT_SERVICE_OPTIONS.types.PRODUCT_SERVICE_OPTIONS_FETCH_ERROR:
            return {state: action.type}
        default:
            return {state}
    }
}

function TopMenu(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.TOP_MENU_CHANGED.types.CHANGED:
            return {state: action.type, data: action.data}
    }

}


function serviceConfig(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.SERVICE_CONFIG.types.SERVICE_CONFIG_CHANGED:
            return {state: action.type, data: action.data, oldData: action.oldData}
        case ActionTypes.SERVICE_CONFIG.types.SERVICE_CONFIG_CHANGES_UPDATED:
            return {state: action.type, data: action.data}
        case ActionTypes.SERVICE_CONFIG.types.SERVICE_CONFIG_UPDATING:
            return {state: action.type}
        case ActionTypes.SERVICE_CONFIG.types.SERVICE_CONFIG_UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service Config',
                description: 'Service config successfully updated.',
            })
            return {state: action.type}
        case ActionTypes.SERVICE_CONFIG.types.SERVICE_CONFIG_UPDATE_ERROR:
            return {state: action.type}
        default:
            return {state}
    }
}

function project(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.PROJECT.types.CREATING:
            return {state: action.type};
        case ActionTypes.PROJECT.types.CREATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project',
                description: `${action.data.config.data.alias} successfully created.`,
            })
            return {state: action.type, data: action.data};
        case ActionTypes.PROJECT.types.UPDATING:
            return {state: action.type, data: action.data};
        case ActionTypes.PROJECT.types.UPDATED:
            return {state: action.type};
        case ActionTypes.PROJECT.types.CONFIG_UPDATING:
            return {state: action.type};
        case ActionTypes.PROJECT.types.CONFIG_UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project',
                description: `Project config successfully updated.`,
            })
            return {state: action.type, data: action.data};
        case ActionTypes.PROJECT.types.ERROR:
            return {state: action.type, data: action.data};
        default:
            return {state}
    }
}

function apiKey(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.API_KEY.types.CREATING:
            return {state: action.type};
        case ActionTypes.API_KEY.types.CREATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Api Key',
                description: `****${action.data.apiKey.substr(-4, 4)} successfully created.`,
            })
            return {state: action.type, data: action.data};
        case ActionTypes.API_KEY.types.DELETING:
            return {state: action.type, data: action.data};
        case ActionTypes.API_KEY.types.DELETED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Api Key',
                description: 'Api key successfully deleted!',
            })
            return {state: action.type, data: action.data};
        case ActionTypes.API_KEY.types.ERROR:
            return {state: action.type, data: action.data};

        default:
            return {state}
    }
}

function userF(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.USER.types.LOADED:
            localStorage.setItem('user', JSON.stringify({username: action.data.username, email: action.data.email}))
            return {state: "ActionTypes.USER.types.LOADED", data: action.user};
        default:
            return {state}
    }
}

function projectServicesConfigAction(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.PROJECT_SERVICES_CONFIG.types.UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project Services Config',
                description: `Project services config update successfully`,
            })
            return {state: action.type};
        case ActionTypes.PROJECT_SERVICES_CONFIG.types.UPDATING:
            return {state: action.type};
        case ActionTypes.PROJECT_SERVICES_CONFIG.types.ERROR:
            return {state: action.type, data: action.data};
        default:
            return {state}
    }
}

function developerServiceAction(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.DEVELOPER_SERVICE.types.UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service',
                description: `Service successfully updated`,
            })
            return {state: action.type};
        case ActionTypes.DEVELOPER_SERVICE.types.UPDATING:
            return {state: action.type};
        case ActionTypes.DEVELOPER_SERVICE.types.ERROR:
            return {state: action.type, data: action.data};
        case ActionTypes.DEVELOPER_SERVICE.types.CREATE_ERROR:
            return {state: action.type, data: action.data};
        case ActionTypes.DEVELOPER_SERVICE.types.CREATING:
            return {state: action.type};
        case ActionTypes.DEVELOPER_SERVICE.types.CREATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Service',
                description: `Service successfully created`,
            })
            return {state: action.type};
        default:
            return {state}
    }
}

function projectUserRolesAction(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.PROJECT_USER_ROLES.types.UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project User Roles Config',
                description: `Project user roles update successfully`,
            })
            return {state: action.type};
        case ActionTypes.PROJECT_USER_ROLES.types.UPDATING:
            return {state: action.type};
        case ActionTypes.PROJECT_USER_ROLES.types.ERROR:
            return {state: action.type, data: action.data};
        default:
            return {state}
    }
}

function projectAttachedServiceRolesAction(state: any, action: any) {
    switch (action.type) {
        case ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.UPDATED:
            notification["success"]({
                placement: 'bottomRight',
                message: 'Project Attached Service Roles Config',
                description: `Project attached service roles update successfully`,
            })
            return {state: action.type};
        case ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.UPDATING:
            return {state: action.type};
        case ActionTypes.PROJECT_ATTACHED_SERVICE_ROLES.types.ERROR:
            return {state: action.type, data: action.data};
        default:
            return {state}
    }
}

function CosInstanceStore(state: any, action: { data: any, type: CosActionStoreTypes }) {
    switch (action.type) {
        case CosActionStoreTypes.FETCHING:
            return {state: action.type}
        case CosActionStoreTypes.FETCHED:
            return {state: action.type, data: action.data}
        case CosActionStoreTypes.FETCHING_ERROR:
            return {state: action.type, data: action.data};
        default:
            return state
    }
}


export let projectStore = createStore(project)
export let apiKeyStore = createStore(apiKey)
export let serviceConfigStore = createStore(serviceConfig)
export let productServiceOptionsStore = createStore(ProductServiceOptionsForm)
export let projectServiceConfigStore = createStore(projectServicesConfigAction)
export let developerServiceStore = createStore(developerServiceAction)
export let projectRolesStore = createStore(projectUserRolesAction)
export let projectAttachedServiceRolesStore = createStore(projectAttachedServiceRolesAction)
export let topMenuStore = createStore(TopMenu)
export let cosInstanceStore = createStore(CosInstanceStore)