import React, { Component } from 'react'
import Editor from '@monaco-editor/react'
import CustomSpinner from '../../../Components/CustomSpinner'
import { Button, Divider, Form, Input, Popconfirm, Select, Space, Tabs, Typography } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { ThemeContext } from '../../../Contexts/ThemeContext'

const { Title } = Typography

interface Props {
    deleteInstance: () => Promise<void>
    initialValue?: string
    payloadOnChange: (value: string | undefined) => void
    routeComponentProps: RouteComponentProps<{ projectId: string; classId: string; instanceId?: string }>
    getInstanceByInstanceIdFormOnFinish: (input: { instanceId?: string; httpMethod: 'get' | 'post' }) => Promise<boolean>
    getInstanceByKeyValueFormOnFinish: (keyValueInput?: { name: string; value: string; _preventNotFound: boolean; httpMethod: 'get' | 'post' }) => Promise<boolean>
}

interface State {
    loading: boolean
}

export class CosInitialPayloadTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
        }
        this.onInitialPayloadChanged = this.onInitialPayloadChanged.bind(this)
    }

    onInitialPayloadChanged(value: string | undefined, ev: any) {
        this.props.payloadOnChange(value)
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Instance Id" key="1">
                            <Form
                                autoComplete={'off'}
                                layout={'inline'}
                                size={'middle'}
                                name="get_instance_by_instance_id"
                                key="get_instance_by_instance_id"
                                initialValues={{
                                    instanceId: this.props.routeComponentProps.match.params.instanceId,
                                }}
                                onFinish={this.props.getInstanceByInstanceIdFormOnFinish}
                            >
                                <Form.Item style={{ width: 300 }} name="instanceId">
                                    <Input autoComplete={'off'} placeholder="Instance ID" />
                                </Form.Item>

                                <Form.Item name={'httpMethod'}>
                                    <Select defaultValue="post" style={{ width: 120 }}>
                                        <Select.Option value="post">POST</Select.Option>
                                        <Select.Option value="get">GET</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item>
                                    <Space>
                                        <Button type="default" htmlType="submit" block>
                                            Get Instance
                                        </Button>
                                        {this.props.routeComponentProps.match.params.instanceId ? (
                                            <Popconfirm
                                                title="Are you sure to delete this instance?"
                                                onConfirm={async () => {
                                                    await this.props.deleteInstance()
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button loading={this.state.loading} type={'default'} danger>
                                                    Delete Instance
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Key Value" key="2">
                            <Form
                                autoComplete={'off'}
                                layout={'inline'}
                                size={'middle'}
                                name="get_instance_by_key_value"
                                key="get_instance_by_key_value"
                                initialValues={{
                                    _preventNotFound: false,
                                }}
                                onFinish={this.props.getInstanceByKeyValueFormOnFinish}
                            >
                                <Form.Item style={{ width: 275 }} name="name">
                                    <Input autoComplete={'off'} placeholder="Key" />
                                </Form.Item>

                                <Form.Item style={{ width: 275 }} name="value">
                                    <Input autoComplete={'off'} placeholder="Value" />
                                </Form.Item>

                                {/*
                                 <Form.Item name="_preventNotFound" valuePropName="checked">
                                    <Checkbox>Prevent Not Found</Checkbox>
                                </Form.Item>
                                     */}

                                <Form.Item name={'httpMethod'}>
                                    <Select defaultValue="post" style={{ width: 120 }}>
                                        <Select.Option value="post">POST</Select.Option>
                                        <Select.Option value="get">GET</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item>
                                    <Space>
                                        <Button type="default" htmlType="submit" block>
                                            Get Instance
                                        </Button>
                                        {this.props.routeComponentProps.match.params.instanceId ? (
                                            <Popconfirm
                                                title="Are you sure to delete this instance?"
                                                onConfirm={async () => {
                                                    await this.props.deleteInstance()
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button loading={this.state.loading} type={'default'} danger>
                                                    Delete Instance
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                    </Tabs>
                    <Divider dashed />
                    <Title level={5}>Payload</Title>
                    <Tabs>
                        <Tabs.TabPane tab="Body" key="1">
                            <ThemeContext.Consumer>
                                {({ isDarkMode }) => (
                                    <Editor
                                        height={'calc(100vh - 64px - 336px)'}
                                        theme={isDarkMode ? 'vs-dark' : 'light'}
                                        language={'json'}
                                        onChange={this.onInitialPayloadChanged}
                                        defaultValue={this.props.initialValue}
                                    />
                                )}
                            </ThemeContext.Consumer>
                        </Tabs.TabPane>
                    </Tabs>
                </CustomSpinner>
            </>
        )
    }
}
