import React, { Component } from 'react'
import { Breadcrumb, Button, Descriptions, Divider, Image, Modal, Switch, Tag, Timeline, Tooltip, Typography } from 'antd'
import CustomSpinner from '../Components/CustomSpinner'
import moment from 'dayjs'
import { InfoCircleOutlined } from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import CosMethodResponseLogs from '../Layouts/CosLayouts/Components/CosMethodResponseLogs'
import { IProjectContext, RootProjectContext } from '../Contexts/RootProjectContext'
import { RootProjectClassMethods } from '../Api/APIService'
import { ThemeContext } from '../Contexts/ThemeContext'

const decompress = require('brotli/decompress')
const prettyBytes = require('pretty-bytes')
const prettyMilliseconds = require('pretty-ms')
const { Text } = Typography

interface Props {
    logSummary: any
    projectId: string
    show?: boolean
    onCancel?: Function
}

interface State {
    loading: boolean
    visible: boolean
    logDetail?: any
    req?: any
    res?: any
    routeFrom: any
    routeTo: any
    imageResponse?: string
}

class LogDetailModal extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            visible: !!props.show,
            routeFrom: '',
            routeTo: '',
        }
        this.showModal = this.showModal.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.getDetail = this.getDetail.bind(this)
    }

    async getDetail() {
        if (!this.ctx) throw new Error('ctx not found')
        this.setState({
            loading: true,
        })

        const res = await this.ctx.instance?.call<any>({
            method: RootProjectClassMethods.getLogDetails,
            body: {
                requestId: this.props.logSummary.requestId,
                responseTime: new Date(this.props.logSummary.responseTime).getTime(),
            },
        })
        if (res) {
            const result = res.data

            this.setState({
                logDetail: result,
            })
            if (result.context.userId) {
                this.setState({
                    routeFrom: (
                        <>
                            <Text>Id: {result.context.userId}</Text>
                            <Text code>Role: {result.context.identity}</Text>
                        </>
                    ),
                    routeTo: (
                        <>
                            <Text code>Id: {result.context.classId}</Text>
                        </>
                    ),
                })
            } else {
                this.setState({
                    routeFrom: (
                        <>
                            <Text>Id: {result.context.identity}</Text>
                        </>
                    ),
                    routeTo: (
                        <>
                            <Text>Id: {result.context.classId}</Text>
                        </>
                    ),
                })
            }
            if (result.context.classId) {
                this.setState({
                    routeTo: (
                        <>
                            <Breadcrumb>
                                <Breadcrumb.Item>Class</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a target={'_blank'} href={`/project/${result.context.projectId}/Classes/${result.context.classId}`}>
                                        {result.context.classId}
                                    </a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <a target={'_blank'} href={`/project/${result.context.projectId}/Classes/${result.context.classId}/instance/${result.context.instanceId}`}>
                                        {result.context.instanceId}
                                    </a>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{result.context.methodName}</Breadcrumb.Item>
                            </Breadcrumb>
                        </>
                    ),
                })
            }
            this.prepareData(result)
        }
        this.setState({
            loading: false,
        })
    }

    async componentDidMount() {
        this.ctx = this.context
        await this.getDetail()
    }

    prepareData = (result: any) => {
        if (result) {
            let req: any
            let res: any
            req = result.request ? result.request : ''
            res = result.response ? result.response : ''
            if (req.body && typeof req.body === 'string') {
                let body = req.body
                try {
                    body = JSON.parse(body)
                } catch (e: any) {}
                req.body = body
            }
            if (res && res.body && res.headers) {
                const typ = res.headers['Content-Type'] || res.headers['content-type']
                if (typ === 'application/json') {
                    try {
                        res.body = JSON.parse(res.body)
                    } catch (e: any) {}
                }
            }
            const maybeImage = res ? req && req.headers && req.headers.accept && res.type && res.type === 'Buffer' && res.data && Array.isArray(res.data) : false
            if (maybeImage) {
                let imageType = undefined
                for (const img of ['image/jpg', 'image/jpg', 'image/png', 'image/webp']) {
                    if (req.headers.accept.split(',')[0]!.includes(img)) {
                        imageType = img
                        break
                    }
                    if (!imageType && req.body && req.body.contentType && req.body.contentType.includes(img)) {
                        imageType = img
                        break
                    }
                }
                if (imageType) {
                    this.setState({
                        imageResponse: `data:${imageType};base64,${Buffer.from(res.data).toString('base64')}`,
                    })
                }
            } else {
                try {
                    res = JSON.parse(res)
                } catch (e: any) {}
            }
            this.setState({
                res,
                req,
            })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleCancel = async () => {
        this.setState({ visible: false })
        if (this.props.onCancel) await this.props.onCancel()
    }

    render() {
        return (
          <ThemeContext.Consumer>
            {({ isDarkMode }) => (
              <>
                <Modal
                  width={1000}
                  open={this.state.visible}
                  title={`Log Detail - ${this.props.logSummary.requestId}`}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button
                      key="back"
                      onClick={this.handleCancel}
                      loading={this.state.loading}
                    >
                      Cancel
                    </Button>,
                  ]}
                >
                  <CustomSpinner spinning={this.state.loading}>
                    {this.state.logDetail && this.state.logDetail.context ? (
                      <>
                        <Timeline>
                          <Timeline.Item>{this.state.routeFrom}</Timeline.Item>
                          <Timeline.Item
                            color={
                              this.state.logDetail.response?.statusCode >=
                                200 &&
                              this.state.logDetail.response?.statusCode < 400
                                ? "green"
                                : "red"
                            }
                          >
                            {this.state.routeTo}
                          </Timeline.Item>
                        </Timeline>
                        <Descriptions bordered>
                          <Descriptions.Item span={3} label="statusCode">
                            {this.state.logDetail.response?.statusCode >= 200 &&
                            this.state.logDetail.response?.statusCode < 400 ? (
                              <Text type="success">
                                {this.state.logDetail.response?.statusCode}
                              </Text>
                            ) : (
                              <Text type="danger">
                                {this.state.logDetail.response?.statusCode}
                              </Text>
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={
                              <>
                                Request Time{" "}
                                <Tooltip title="Local time zone">
                                  <span>
                                    <InfoCircleOutlined />
                                  </span>
                                </Tooltip>
                              </>
                            }
                          >
                            {moment(
                              this.state.logDetail.request.requestTime
                            ).format("YYYY-MM-DD HH:mm:ss.SSS")}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={
                              <>
                                Response Time{" "}
                                <Tooltip title="Local time zone">
                                  <span>
                                    <InfoCircleOutlined />
                                  </span>
                                </Tooltip>
                              </>
                            }
                          >
                            {moment(
                              this.state.logDetail.response.responseTime
                            ).format("YYYY-MM-DD HH:mm:ss.SSS")}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="identity">
                            {this.state.logDetail.context.identity}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="userId">
                            {this.state.logDetail.context.userId ?? "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="classid">
                            {this.state.logDetail.context.classId}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="instanceId">
                            {this.state.logDetail.context.instanceId}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="methodName">
                            {this.state.logDetail.context.methodName}
                          </Descriptions.Item>
                          {/* <Descriptions.Item span={3} label="refererClassId">
                                    {this.state.logDetail.refererclassid}
                                </Descriptions.Item> */}
                          {/* <Descriptions.Item span={3} label="refererInstanceId">
                                    {this.state.logDetail.refererinstanceid}
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label="refererMethodName">
                                    {this.state.logDetail.referermethodname}
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label="refererUserId">
                                    {this.state.logDetail.refereruserid}
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label="refererIdentity">
                                    {this.state.logDetail.refereridentity}
                                </Descriptions.Item> */}
                          {/* <Descriptions.Item span={3} label="logid">
                                    {this.state.logDetail.logid}
                                </Descriptions.Item> */}
                          <Descriptions.Item span={3} label="requestid">
                            {this.state.logDetail.context.requestId}
                          </Descriptions.Item>
                          {/* <Descriptions.Item span={3} label="sessionid">
                                    {this.state.logDetail.sessionid}
                                </Descriptions.Item> */}
                          <Descriptions.Item span={1} label="culture">
                            {this.state.logDetail.context.culture}
                          </Descriptions.Item>
                          <Descriptions.Item span={1} label="platform">
                            {this.state.logDetail.context.platform}
                          </Descriptions.Item>
                          {/* <Descriptions.Item span={1} label="cache">
                                    {this.state.logDetail.cache ? <Switch size="small" disabled defaultChecked /> : <Switch size="small" disabled />}
                                </Descriptions.Item> */}
                          {/* <Descriptions.Item span={2} label="durationinmilliseconds">
                                    {prettyMilliseconds(this.state.logDetail.durationinmilliseconds || 0)}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label="executiondurationinmilliseconds">
                                    {prettyMilliseconds(this.state.logDetail.executiondurationinmilliseconds || 0)}
                                </Descriptions.Item> */}
                          <Descriptions.Item
                            span={3}
                            label={"clientBrowserName"}
                          >
                            {this.state.logDetail.context.clientBrowserName}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={"clientBrowserVersion"}
                          >
                            {this.state.logDetail.context.clientBrowserVersion}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={"clientDeviceModel"}
                          >
                            {this.state.logDetail.context.clientDeviceModel}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={"clientDeviceVendor"}
                          >
                            {this.state.logDetail.context.clientDeviceVendor}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={"clientEngineName"}
                          >
                            {this.state.logDetail.context.clientEngineName}
                          </Descriptions.Item>
                          <Descriptions.Item
                            span={3}
                            label={"clientEngineVersion"}
                          >
                            {this.state.logDetail.context.clientEngineVersion}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label={"clientOsName"}>
                            {this.state.logDetail.context.clientOsName}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label={"clientOsVersion"}>
                            {this.state.logDetail.context.clientOsVersion}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label={"clientUserAgent"}>
                            {this.state.logDetail.context.clientUserAgent}
                          </Descriptions.Item>
                          <Descriptions.Item span={3} label="sourceIP">
                            {this.state.logDetail.context.sourceIP}
                          </Descriptions.Item>
                          {/* <Descriptions.Item span={2} label="reqsize">
                                    {prettyBytes(this.state.logDetail.reqsize)}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label="ressize">
                                    {prettyBytes(this.state.logDetail.ressize)}
                                </Descriptions.Item> */}
                          {/* <Descriptions.Item
                                    span={2}
                                    label={
                                        <>
                                            year{' '}
                                            <Tooltip title="UTC">
                                                <span>
                                                    <InfoCircleOutlined />
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {this.state.logDetail.year}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={2}
                                    label={
                                        <>
                                            month{' '}
                                            <Tooltip title="UTC">
                                                <span>
                                                    <InfoCircleOutlined />
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {this.state.logDetail.month}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={2}
                                    label={
                                        <>
                                            day{' '}
                                            <Tooltip title="UTC">
                                                <span>
                                                    <InfoCircleOutlined />
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {this.state.logDetail.day}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={2}
                                    label={
                                        <>
                                            hour{' '}
                                            <Tooltip title="UTC">
                                                <span>
                                                    <InfoCircleOutlined />
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {this.state.logDetail.hour}
                                </Descriptions.Item> */}
                          {Object.keys(this.state.logDetail).map((k) => {
                            if (new RegExp("^(param[0-9])$", "g").test(k)) {
                              return (
                                <Descriptions.Item span={3} label={k}>
                                  {this.state.logDetail[k]}
                                </Descriptions.Item>
                              );
                            }
                          })}
                        </Descriptions>
                        <Divider dashed />
                        <Divider orientation="left" plain>
                          <Text strong>Request</Text>
                        </Divider>
                        {this.state.req ? (
                          <Editor
                            options={{ readOnly: true }}
                            height={"40vh"}
                            language={"json"}
                            theme={isDarkMode ? "vs-dark" : "light"}
                            defaultValue={JSON.stringify(
                              this.state.req,
                              null,
                              2
                            )}
                          />
                        ) : (
                          "No request"
                        )}
                        <Divider dashed />
                        <Divider orientation="left" plain>
                          <Text strong>Response</Text>
                        </Divider>
                        {this.state.res ? (
                          this.state.imageResponse ? (
                            <Image src={this.state.imageResponse} />
                          ) : (
                            <Editor
                              options={{ readOnly: true }}
                              height={"40vh"}
                              language={"json"}
                              theme={isDarkMode ? "vs-dark" : "light"}
                              defaultValue={JSON.stringify(
                                this.state.res,
                                null,
                                2
                              )}
                            />
                          )
                        ) : (
                          "No response"
                        )}
                        <Divider dashed />
                        {this.state.logDetail.logs &&
                        Object.keys(this.state.logDetail.logs).length > 0 ? (
                          <>
                            <Divider orientation="left" plain>
                              <Text strong>Method Response Logs</Text>
                            </Divider>
                            <CosMethodResponseLogs
                              logs={this.state.logDetail.logs}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </CustomSpinner>
                </Modal>
              </>
            )}
          </ThemeContext.Consumer>
        );
    }
}

LogDetailModal.contextType = RootProjectContext

export default LogDetailModal
