import React, { Component } from 'react'
import ProjectsLayout from './ProjectsLayout'
import { APIService } from '../Api/APIService'
import { RouteComponentProps } from 'react-router-dom'

interface Props {
    routeComponentProps: RouteComponentProps
    apiService: APIService
}

interface State {}

export class HomePage extends Component<Props, State> {
    render() {
        return <ProjectsLayout apiService={this.props.apiService} routeComponentProps={this.props.routeComponentProps} />
    }
}
