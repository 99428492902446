import Editor from '@monaco-editor/react'
import React, { FC, useContext, useEffect } from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, notification, Popconfirm, Popover, Row, Select, Space, Switch, Table } from 'antd'

import { RootProjectClassMethods } from '../../Api/APIService'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import { AuthenticationRule } from '../../Actions/Interfaces/IProject'

const operators = ['EX', 'EQ', 'NE', 'GT', 'GTE', 'LT', 'LTE', 'IN', 'NIN'] as const

const operatorTypes: any = {
    EX: 'boolean',
    EQ: 'string',
    NE: 'string',
    GT: 'number',
    GTE: 'number',
    LT: 'number',
    LTE: 'number',
    IN: 'array',
    NIN: 'array',
}

const filterableFields = ['identity', 'claims', 'userId']

const AuthenticationRules: FC = () => {
    const ctx = useContext(RootProjectContext)
    const [showAdd, setShowAdd] = React.useState(false)
    const [showDetail, setShowDetail] = React.useState(false)
    const [selectedRule, setSelectedRule] = React.useState<AuthenticationRule>()
    const [loading, setLoading] = React.useState(false)
    const [authenticationRules, setAuthenticationRules] = React.useState<AuthenticationRule[]>([])

    const [addForm] = Form.useForm()
    const [editForm] = Form.useForm()
    // for some reason, form values are not updating
    const [, setFormValues] = React.useState<any>({})

    const validateOutput = (output: string) => {
        try {
            const obj = JSON.parse(output)
            console.log(obj)
            if (typeof obj !== 'object') return false
            if (Object.keys(obj).length < 1) return false
            if (Object.keys(obj).length > 3) return false
            if (Object.keys(obj).some(key => !filterableFields.includes(key))) return false
            return true
        } catch (error) {
            return false
        }
    }

    const getAuthenticationRules = async () => {
        if (!ctx) throw new Error('ctx not found')

        try {
            setLoading(true)
            const res = await ctx.instance?.call<any>({
                method: RootProjectClassMethods.getAuthenticationRules,
            })
            setAuthenticationRules(res?.data ?? [])
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const onAdd = async (values: any) => {
        if (!ctx) throw new Error('ctx not found')

        if (!validateOutput(values.output)) {
            addForm.setFields([
                {
                    name: 'output',
                    errors: ['Invalid output, must be a valid JSON object and all keys must be one of: identity, claims, userId'],
                },
            ])
            editForm.setFields([
                {
                    name: 'output',
                    errors: ['Invalid output, must be a valid JSON object and all keys must be one of: identity, claims, userId'],
                },
            ])
            return
        }
        addForm.setFields([
            {
                name: 'output',
                errors: [],
            },
        ])
        editForm.setFields([
            {
                name: 'output',
                errors: [],
            },
        ])

        try {
            setLoading(true)
            const newRule: AuthenticationRule = {
                filter: {
                    [`$.${values.filter}`]: {
                        [values.operator]: values.value,
                    },
                },
                output: JSON.parse(values.output),
            }

            if (values.id) newRule.id = values.id

            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.upsertAuthenticationRule,
                body: newRule,
            })
            addForm.resetFields()
            editForm.resetFields()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
            setShowAdd(false)
            setShowDetail(false)
            setSelectedRule(undefined)
            await getAuthenticationRules()
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const deleteAdapter = async (values: AuthenticationRule) => {
        if (!ctx) throw new Error('ctx not found')

        try {
            setLoading(true)
            await ctx.instance?.call<any>({
                method: RootProjectClassMethods.removeAuthenticationRule,
                body: {
                    id: values.id,
                },
            })
            setShowDetail(false)
            setSelectedRule(undefined)
            await getAuthenticationRules()
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const onShowEdit = (item: AuthenticationRule) => {
        const filter = Object.keys(item.filter)[0].replace('$.', '')
        const operator = Object.keys(Object.values(item.filter)[0])[0]
        const value = Object.values(Object.values(item.filter)[0])[0]

        editForm.setFieldsValue({
            id: item.id,
            filter,
            operator,
            value,
            output: JSON.stringify(item.output, null, 2),
        })

        setSelectedRule(item)
        setShowDetail(true)
    }

    useEffect(() => {
        getAuthenticationRules()
    }, [])

    return (
        <>
            <Button
                loading={loading}
                onClick={() => {
                    setShowAdd(true)
                }}
            >
                Add Authentication Rule
            </Button>
            <Divider dashed />
            <Table
                dataSource={authenticationRules || []}
                columns={[
                    {
                        title: 'Filter',
                        render: (item: AuthenticationRule) => {
                            return (
                                <Button type={'link'} onClick={() => onShowEdit(item)}>
                                    {Object.keys(item.filter)[0]} {Object.keys(Object.values(item.filter)[0])[0]}:{' '}
                                    {typeof Object.values(Object.values(item.filter)[0])[0] === 'boolean'
                                        ? Object.values(Object.values(item.filter)[0])[0]
                                            ? 'true'
                                            : 'false'
                                        : Object.values(Object.values(item.filter)[0])[0]}
                                </Button>
                            )
                        },
                    },
                    {
                        title: 'Output',
                        render: (item: AuthenticationRule) => {
                            return <span>{JSON.stringify(item.output)}</span>
                        },
                    },
                ]}
            />
            <Drawer
                title="Edit Authentication Rule"
                width={600}
                placement="right"
                onClose={() => {
                    setShowDetail(false)
                    setSelectedRule(undefined)
                    editForm.resetFields()
                }}
                visible={showDetail}
                extra={
                    <Space>
                        <Popconfirm
                            title="Are you sure to delete this adapter?"
                            onConfirm={async () => {
                                await deleteAdapter(selectedRule!)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger loading={loading}>
                                Delete
                            </Button>
                        </Popconfirm>
                        <Button loading={loading} form={'edit-authentication-rule'} htmlType={'submit'}>
                            Update
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={editForm}
                    id={'edit-authentication-rule'}
                    name="basic"
                    layout={'vertical'}
                    initialValues={{}}
                    onValuesChange={setFormValues}
                    onFinish={onAdd}
                    autoComplete="off"
                >
                    <Form.Item name="id" rules={[{ required: true }]} hidden>
                        <Input hidden />
                    </Form.Item>
                    <Form.Item label="Filter" name="filter" rules={[{ required: true }]}>
                        <Select onChange={e => editForm.setFieldsValue({ filter: e })}>
                            {filterableFields.map(item => (
                                <Select.Option value={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item shouldUpdate name="operator" rules={[{ required: true }]}>
                                <Select onChange={e => editForm.setFieldsValue({ operator: e, value: e === 'EX' ? false : undefined })}>
                                    {operators.map(item => (
                                        <Select.Option value={item}>{item}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            {operatorTypes[editForm.getFieldValue('operator')] === 'boolean' && (
                                <Form.Item shouldUpdate name="value" rules={[{ required: true }]}>
                                    <Switch
                                        defaultChecked={editForm.getFieldValue('value') === undefined ? false : editForm.getFieldValue('value')}
                                        onChange={e => editForm.setFieldsValue({ value: e })}
                                    />
                                </Form.Item>
                            )}
                            {operatorTypes[editForm.getFieldValue('operator')] === 'string' && (
                                <Form.Item shouldUpdate name="value" rules={[{ required: true }]}>
                                    <Input onChange={e => editForm.setFieldsValue({ value: e.target.value })} />
                                </Form.Item>
                            )}
                            {operatorTypes[editForm.getFieldValue('operator')] === 'number' && (
                                <Form.Item shouldUpdate name="value" rules={[{ required: true }]}>
                                    <InputNumber onChange={e => editForm.setFieldsValue({ value: e })} />
                                </Form.Item>
                            )}
                            {operatorTypes[editForm.getFieldValue('operator')] === 'array' && (
                                <Form.Item shouldUpdate name="value" rules={[{ required: true }]}>
                                    <Input onChange={e => editForm.setFieldsValue({ value: e.target.value })} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    <Form.Item shouldUpdate label="Output" name="output" rules={[{ required: true }]}>
                        <Editor
                            height={'300px'}
                            language={'json'}
                            defaultValue={JSON.stringify(selectedRule?.output, null, 2)}
                            onChange={v => {
                                editForm.setFieldsValue({ output: v })
                            }}
                            options={{
                                minimap: {
                                    enabled: false,
                                },
                            }}
                        />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Add Authentication Rule"
                width={600}
                placement="right"
                onClose={() => {
                    setShowAdd(false)
                }}
                visible={showAdd}
                extra={
                    <Button loading={loading} form={'add-authentication-rule'} htmlType={'submit'}>
                        Add
                    </Button>
                }
            >
                <Form
                    form={addForm}
                    id={'add-authentication-rule'}
                    name="basic"
                    layout={'vertical'}
                    initialValues={{}}
                    onValuesChange={setFormValues}
                    onFinish={onAdd}
                    autoComplete="off"
                >
                    <Form.Item label="Filter" name="filter" rules={[{ required: true }]}>
                        <Select onChange={e => addForm.setFieldsValue({ filter: e })}>
                            {filterableFields.map(item => (
                                <Select.Option value={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item shouldUpdate name="operator" rules={[{ required: true }]}>
                                <Select onChange={e => addForm.setFieldsValue({ operator: e, value: e === 'EX' ? false : undefined })}>
                                    {operators.map(item => (
                                        <Select.Option value={item}>{item}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Form.Item shouldUpdate name="value" rules={[{ required: true }]}>
                                {operatorTypes[addForm.getFieldValue('operator')] === 'boolean' && <Switch onChange={e => addForm.setFieldsValue({ value: e })} />}
                                {operatorTypes[addForm.getFieldValue('operator')] === 'string' && <Input onChange={e => addForm.setFieldsValue({ value: e.target.value })} />}
                                {operatorTypes[addForm.getFieldValue('operator')] === 'number' && <InputNumber onChange={e => addForm.setFieldsValue({ value: e })} />}
                                {operatorTypes[addForm.getFieldValue('operator')] === 'array' && <Input onChange={e => addForm.setFieldsValue({ value: e.target.value })} />}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        shouldUpdate
                        label={
                            <>
                                <span>Output</span>
                                <Popover
                                    content={
                                        <>
                                            <p>
                                                Output is a JSON object that will consist of userId <code>`string`</code>, identity <code>`string`</code>, and claims{' '}
                                                <code>{'`Record<String, any>`'}</code>.
                                            </p>
                                            <p>Example:</p>
                                            <pre>{`
{
    "userId": "xxx",
    "identity": "xxx",
    "claims": {
        "xxx": "xxx"
    }
}
                                                `}</pre>
                                        </>
                                    }
                                    title="Be cautious!"
                                >
                                    <InfoCircleFilled style={{ marginLeft: '10px' }} />
                                </Popover>
                            </>
                        }
                        name="output"
                    >
                        <div
                            style={{
                                border: '1px solid #d9d9d9',
                                borderRadius: 4,
                                overflow: 'hidden',
                            }}
                        >
                            <Editor
                                height={'300px'}
                                language={'json'}
                                defaultValue={JSON.stringify(
                                    {
                                        identity: 'xxx',
                                    },
                                    null,
                                    2
                                )}
                                onChange={v => {
                                    addForm.setFieldsValue({ output: v })
                                }}
                                options={{
                                    minimap: {
                                        enabled: false,
                                    },
                                }}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default AuthenticationRules
