import React, {Component} from "react";
import {Tabs} from "antd";
import {ProjectRoles} from "./Contents/ProjectDetailContents/ProjectRoles";
import {ServicesAttachedRolesModal} from "../Modals/ServicesAttachedRolesModal";
import AdminList from "../Components/AdminList";

const {TabPane} = Tabs;

interface State {
}

interface Props {
    projectId: string
}


export default class IAMLayout extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    /**
                     * <Tabs tabPosition={"left"} destroyInactiveTabPane defaultActiveKey={"1"}>

                     <TabPane tab="Roles" key="1">
                     <ProjectRoles projectId={this.props.projectId}/>
                     </TabPane>
                     <TabPane tab="Attached Roles" key="2">
                     <ServicesAttachedRolesModal projectId={this.props.projectId}/>
                     </TabPane>
                     <TabPane tab="Members" key="3">

                     </TabPane>
                     </Tabs>

                     */
                }
                <AdminList
                    projectId={this.props.projectId}
                />
            </>
        );
    }
}