import React, { Component } from 'react'
import { Button, Col, Descriptions, Divider, Image, Input, notification, Row, Skeleton, Space, Table } from 'antd'
import RBS from '@retter/sdk'
import { RootProjectClassEnums, RootProjectClassMethods } from '../Api/APIService'
import TextArea from 'antd/es/input/TextArea'

export interface Props {
    projectId: string
    rootRbsSdk: RBS
}

export interface State {
    fileListLoading: boolean
    fileContentLoading: boolean
    fileContent?: any
    lastSelectedKey: string
    filteredKeys: string[]
    searchPrefix?: string
    fileList?: {
        keys: string[]
        keyCount: number
        continuationToken?: string
        nextContinuationToken?: string
        maxKeys: number
    }
}

export class FilesLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            lastSelectedKey: '',
            fileListLoading: false,
            fileContentLoading: false,
            filteredKeys: [],
        }
        this.getFileList = this.getFileList.bind(this)
        this.downloadContent = this.downloadContent.bind(this)
        this.filterKeys = this.filterKeys.bind(this)
        this.onFileSearch = this.onFileSearch.bind(this)
    }

    async downloadContent() {
        const url = window.URL.createObjectURL(new Blob([Buffer.from(this.state.fileContent.Body)]))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = this.state.lastSelectedKey
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
    }

    async getFileContent(key: string) {
        this.setState({ fileContentLoading: true, lastSelectedKey: key })
        try {
            const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                instanceId: this.props.projectId,
            })
            const response = await retterClassInstance.call<any>({
                method: RootProjectClassMethods.getFileContent,
                queryStringParams: {
                    key,
                },
            })
            if (!response || response.status >= 400) {
                notification.error({
                    placement: 'bottomRight',
                    message: response ? response.data : 'error',
                })
                return false
            }
            this.setState({ fileContent: response.data })
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e?.response?.data?.message ?? 'error',
            })
        }
        this.setState({ fileContentLoading: false })
    }

    async getFileList(continuationToken?: string) {
        this.setState({ fileListLoading: true })
        try {
            const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                instanceId: this.props.projectId,
            })
            const queryStringParams: any = {}
            if (continuationToken) {
                queryStringParams['continuationToken'] = continuationToken
            }
            const response = await retterClassInstance.call<any>({
                method: RootProjectClassMethods.getFileList,
                queryStringParams,
            })
            if (!response || response.status >= 400) {
                notification.error({
                    placement: 'bottomRight',
                    message: response ? response.data : 'error',
                })
                return false
            }
            const prevKeys = this.state.fileList?.keys || []
            await this.setState({
                fileList: {
                    keys: [...prevKeys, ...response.data.keys],
                    keyCount: response.data.keyCount,
                    continuationToken: response.data.continuationToken,
                    nextContinuationToken: response.data.nextContinuationToken,
                    maxKeys: response.data.maxKeys,
                },
            })
            await this.filterKeys()
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e?.response?.data?.message ?? 'error',
            })
        }
        this.setState({
            fileListLoading: false,
        })
    }

    async filterKeys() {
        let filteredKeys: string[]
        if (this.state.searchPrefix) {
            filteredKeys = (this.state.fileList?.keys || []).filter(k => k.startsWith(this.state.searchPrefix!))
        } else {
            filteredKeys = this.state.fileList?.keys || []
        }
        await this.setState({
            filteredKeys,
        })
    }

    async componentDidMount() {
        await this.getFileList()
    }

    async onFileSearch(key: string) {
        await this.setState({ searchPrefix: key })
        await this.filterKeys()
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={12} style={{ padding: '1em' }}>
                        {this.state.fileList === undefined && this.state.fileListLoading ? <Skeleton active /> : null}
                        {this.state.fileList ? (
                            <>
                                <Input.Search autoFocus={true} placeholder="Input prefix" onSearch={this.onFileSearch} style={{ maxWidth: 400 }} />
                                <br />
                                <br />
                                <Table
                                    dataSource={this.state.filteredKeys.map(k => {
                                        return { key: k }
                                    })}
                                    pagination={false}
                                    columns={[
                                        {
                                            title: 'File Key',
                                            render: (item: { key: string }) => {
                                                return (
                                                    <a
                                                        onClick={async () => {
                                                            await this.getFileContent(item.key)
                                                        }}
                                                    >
                                                        {item.key}
                                                    </a>
                                                )
                                            },
                                        },
                                    ]}
                                />
                                <Divider dashed />
                                <Space split={<Divider type="vertical" />}>
                                    {this.state.fileList.nextContinuationToken ? (
                                        <Button
                                            loading={this.state.fileListLoading}
                                            onClick={async () => {
                                                await this.getFileList(this.state.fileList!.nextContinuationToken)
                                            }}
                                            type={'link'}
                                        >
                                            Load more
                                        </Button>
                                    ) : null}
                                </Space>
                            </>
                        ) : null}
                    </Col>
                    <Col span={12} style={{ padding: '1em' }}>
                        {this.state.fileContentLoading ? <Skeleton active /> : null}
                        {this.state.fileContent && !this.state.fileContentLoading ? (
                            <>
                                <Descriptions
                                    title={this.state.lastSelectedKey}
                                    extra={
                                        <Button type={'link'} onClick={this.downloadContent}>
                                            Download
                                        </Button>
                                    }
                                >
                                    <Descriptions.Item span={3} label="Content Type">
                                        {this.state.fileContent.ContentType}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Content Length">
                                        {this.state.fileContent.ContentLength}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label="Content Last Modified">
                                        {this.state.fileContent.LastModified}
                                    </Descriptions.Item>
                                </Descriptions>
                                <p>Body:</p>
                                {this.state.fileContent.ContentType.includes('image') ? (
                                    <>
                                        <Image src={`data:${this.state.fileContent.ContentType};base64,${Buffer.from(this.state.fileContent.Body, 'base64').toString('base64')}`} />
                                    </>
                                ) : this.state.fileContent.ContentType.includes('text') ? (
                                    <>
                                        <pre>{Buffer.from(this.state.fileContent.Body).toString('utf-8')}</pre>
                                    </>
                                ) : this.state.fileContent.ContentType.includes('json') ? (
                                    <>
                                        <pre>{JSON.stringify(JSON.parse(Buffer.from(this.state.fileContent.Body).toString('utf-8')), null, 2)}</pre>
                                    </>
                                ) : (
                                    <TextArea rows={10} value={Buffer.from(this.state.fileContent.Body).toString('base64')} />
                                )}
                            </>
                        ) : null}
                    </Col>
                </Row>
            </>
        )
    }
}
