import React, { Component } from 'react'
import { APIService, RootProjectClassEnums } from '../Api/APIService'
import { RouteComponentProps } from 'react-router-dom'
import CustomSpinner from '../Components/CustomSpinner'
import { IProjectDetail } from '../Actions/Interfaces/IProject'
import { projectDetailStore, ProjectDetailStoreActionTypes } from '../Actions/Stores/ProjectDetailStore'
import { RetterCloudObject } from '@retter/sdk'
import { Unsubscribable } from 'rxjs'
import { IProjectContext, RootProjectContext } from '../Contexts/RootProjectContext'
import ProjectOverviewContent from './Contents/ProjectDetailContents/ProjectOverviewContent'

interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps<{ projectId: string; tab: string }>
}

interface State {
    projectInstance?: RetterCloudObject
    loading: boolean
    apiKeyStatus: string
    configStatus: string
    sdkInit: boolean
    publicSub?: Unsubscribable
    projectContext: IProjectContext
}

export default class ProjectDetailLayout extends Component<Props, State> {
    private readonly projectId: string
    projectContext?: React.Context<any>

    constructor(props: Props) {
        super(props)

        this.projectId = props.routeComponentProps.match.params.projectId

        this.state = {
            loading: true,
            apiKeyStatus: '',
            configStatus: '',
            sdkInit: true,
            projectContext: {},
        }
    }

    async componentDidMount() {
        const projectInstance = await this.props.apiService.rootRbsSdk.getCloudObject({
            classId: RootProjectClassEnums.Project,
            instanceId: this.projectId,
        })
        await this.setState({
            projectInstance,
        })

        this.setState({
            projectContext: {
                projectId: this.projectId,
                instance: projectInstance,
                rootRbsSdk: this.props.apiService.rootRbsSdk,
            },
        })

        const publicSub = projectInstance.state?.public?.subscribe((event: IProjectDetail) => {
            console.log('project detail changed', event)

            this.setState({
                loading: false,
                projectContext: {
                    ...this.state.projectContext,
                    detail: event,
                },
            })
            projectDetailStore.dispatch({
                type: ProjectDetailStoreActionTypes.CHANGED,
                data: event,
            })
        })
        this.setState({
            publicSub,
        })
    }

    componentWillUnmount() {
        if (this.state.publicSub) this.state.publicSub.unsubscribe()
    }

    render() {
        return (
            <RootProjectContext.Provider value={this.state.projectContext}>
                <CustomSpinner spinning={this.state.loading}>
                    <ProjectOverviewContent routeComponentProps={this.props.routeComponentProps} />
                </CustomSpinner>
            </RootProjectContext.Provider>
        )
    }
}
