import React, { Component } from 'react'
import { Button, Divider, notification } from 'antd'
import { IProjectContext, RootProjectContext } from '../../Contexts/RootProjectContext'
import Editor from '@monaco-editor/react'
import { RetterCloudObject } from '@retter/sdk'
import { RootProjectClassMethods } from '../../Api/APIService'
import CustomSpinner from '../../Components/CustomSpinner'
import { gunzipSync, gzipSync } from 'zlib'
import { ThemeContext } from '../../Contexts/ThemeContext'

interface Props {}

interface State {
    loading: boolean
    files: Record<string, any>
}

class Packages extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            files: {},
        }
    }

    componentDidMount() {
        this.ctx = this.context
        this.getPackageJson()
    }

    async getPackageJson() {
        if (!this.ctx?.instance) return
        try {
            const { data } = await this.ctx?.instance?.call<any>({
                method: RootProjectClassMethods.getContents,
                body: {
                    files: {
                        'package.json': {
                            name: 'package.json',
                        },
                    },
                },
            })
            const packageJson = data.files.find((f: any) => f.name === 'package.json')?.content
            if (!packageJson) throw new Error('package.json not found')

            const decoded = gunzipSync(Buffer.from(packageJson, 'base64')).toString('utf-8')

            this.setState({
                files: {
                    ...this.state.files,
                    'package.json': decoded,
                },
                loading: false,
            })
        } catch (error: any) {
            this.setState({
                loading: false,
            })
            notification.error({
                placement: 'bottomRight',
                message: error.response?.data ?? error.message,
            })
        }
    }

    async save(classInstance: RetterCloudObject) {
        try {
            await classInstance.call<any>({
                method: RootProjectClassMethods.setContents,
                body: {
                    files: {
                        'package.json': {
                            name: 'package.json',
                            content: gzipSync(Buffer.from(this.state.files['package.json'])).toString('base64'),
                        },
                    },
                },
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {ctx => (
                        <>
                            <CustomSpinner spinning={this.state.loading}>
                                <Button
                                    onClick={async () => {
                                        await this.save(ctx.instance!)
                                    }}
                                >
                                    Save
                                </Button>
                                <Divider dashed />
                                <ThemeContext.Consumer>
                                    {({ isDarkMode }) => (
                                        <Editor
                                            language={'json'}
                                            height={'calc(100vh - 242px)'}
                                            defaultValue={this.state.files['package.json']}
                                            value={this.state.files['package.json']}
                                            onChange={v => {
                                                this.setState({
                                                    files: {
                                                        ...this.state.files,
                                                        'package.json': v,
                                                    },
                                                })
                                            }}
                                            theme={isDarkMode ? 'vs-dark' : 'light'}
                                        />
                                    )}
                                </ThemeContext.Consumer>
                            </CustomSpinner>
                        </>
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }
}

Packages.contextType = RootProjectContext

export default Packages
