import React, {Component} from "react";
import {Button, Divider, Modal, Spin, Upload} from "antd";
import {APIService} from "../Api/APIService";
import {InboxOutlined} from '@ant-design/icons';
import {RcFile, UploadChangeParam} from "antd/lib/upload";

const {Dragger} = Upload;


interface Props {
    apiService: APIService
}

interface State {
    loading: boolean
    visible: boolean
    manifest: string
    fileList: RcFile[]
}

export class CreateNewServiceModal extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService
        this.state = {
            loading: false,
            visible: false,
            manifest: "",
            fileList: []
        }
        this.createService = this.createService.bind(this)
        this.draggerOnChange = this.draggerOnChange.bind(this)
        this.draggerAction = this.draggerAction.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    async createService(manifest: any) {
        this.setState({loading: true});
        await this.apiService.createService(manifest)
        this.setState({loading: false, visible: false});
    }

    handleCancel = () => {
        this.setState({visible: false});
    };

    onFinish = async () => {
        await this.createService(JSON.parse(this.state.manifest))

    };

    onFinishFailed = (errorInfo: any) => {
    };

    draggerOnChange(e: UploadChangeParam) {
    }

    draggerAction(e: RcFile, fList: RcFile[]) {
        this.setState({fileList: [e], manifest: ""})
        e.text().then((d) => {
            this.setState({manifest: d})
        })
        return false
    }


    render() {
        const {visible, loading} = this.state;

        return (<>
            <Button type="link" onClick={this.showModal}>
                Create New Service
            </Button>
            <Modal
                open={visible}
                title="Create New Service"
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel} loading={loading}>
                        Cancel
                    </Button>
                ]}
            >
                <Spin spinning={loading}>
                    <Dragger
                        fileList={this.state.fileList}
                        key={"uploadcreatemanifest"}
                        name='file'
                        multiple={false}
                        beforeUpload={this.draggerAction}
                        onChange={this.draggerOnChange}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Click or drag manifest file to this area to process</p>
                        <p className="ant-upload-hint">
                            Support for a single file.
                        </p>
                    </Dragger>
                    <Divider/>
                    {this.state.manifest !== "" ? <>
                        <Button key="Create" type={"primary"} loading={loading} onClick={this.onFinish}>
                            Create
                        </Button>
                    </> : ""}
                </Spin>
            </Modal>
        </>);
    }

}