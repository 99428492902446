import React, {Component} from "react";
import {Button, Popconfirm, Space, Table, Tag} from "antd";
import {IProjectEvent} from "../Interfaces/IProjectEvents";
import CustomSpinner from "../Components/CustomSpinner";
import {APIService} from "../Api/APIService";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {DeployTemplateModal} from "../Modals/DeployTemplateModal";
import {ActionTypes, topMenuStore} from "../Actions/Actions";

interface Props {
    projectId: string
}

interface State {
    loading: boolean
    projectEvents: IProjectEvent[]
    autoRefresh: boolean
    autoRefreshInterval?: { clear: Function }
    autoRefreshIntervalDown: number
    intervalCountdown: number
    getCurrentTemplateLoading: boolean
}

export class ProjectDeploymentLayout extends Component<Props, State> {
    private readonly countdown: number = 2

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            projectEvents: [],
            autoRefreshIntervalDown: this.countdown,
            intervalCountdown: this.countdown,
            autoRefresh: false,
            getCurrentTemplateLoading: false
        }
        this.getEvents = this.getEvents.bind(this)
        this.cancelUpdate = this.cancelUpdate.bind(this)
        this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this)
        this.clearAutoRefreshInterval = this.clearAutoRefreshInterval.bind(this)
        this.startAutoRefreshInterval = this.startAutoRefreshInterval.bind(this)
        this.intervalCountdown = this.intervalCountdown.bind(this)
        this.getCurrentTemplate = this.getCurrentTemplate.bind(this)
        this.prepareMenu = this.prepareMenu.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        const prev = Array.isArray(prevState.projectEvents) && prevState.projectEvents.length > 0 && prevState.projectEvents[0].data.projectStatus.endsWith('IN_PROGRESS')
        const next = Array.isArray(this.state.projectEvents) && this.state.projectEvents.length > 0 && this.state.projectEvents[0].data.projectStatus.endsWith('IN_PROGRESS')
        if (prev !== next) {
            this.prepareMenu()
        }else if(prevState.getCurrentTemplateLoading !== this.state.getCurrentTemplateLoading){
            this.prepareMenu()
        }else if(prevState.loading !== this.state.loading){
            this.prepareMenu()
        }else if(prevState.autoRefresh !== this.state.autoRefresh || prevState.autoRefreshIntervalDown !== this.state.autoRefreshIntervalDown){
            this.prepareMenu()
        }
    }

    prepareMenu() {
        const caseX = Array.isArray(this.state.projectEvents) && this.state.projectEvents.length > 0 && this.state.projectEvents[0].data.projectStatus.endsWith('IN_PROGRESS') ?
            <>
                <Popconfirm
                    title="Are you sure to cancel this update?"
                    onConfirm={this.cancelUpdate}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button loading={this.state.loading} key={"delete"} danger type={"default"}>Cancel
                        Update</Button>
                </Popconfirm>
            </> : null
        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED, data: {
                extraMenu: [
                    <Button key="Refresh" type={"default"} loading={this.state.loading}
                            onClick={this.toggleAutoRefresh}>
                        {this.state.autoRefresh ? "Stop" : "Start"} Auto Refresh
                        ({this.state.autoRefreshIntervalDown})
                    </Button>,
                    <Button type={'default'} loading={this.state.getCurrentTemplateLoading}
                            onClick={this.getCurrentTemplate}>
                        Get Template
                    </Button>,
                    <DeployTemplateModal
                        projectId={this.props.projectId}
                    />,
                    caseX
                ]
            }
        })
    }

    async getEvents() {
        this.setState({loading: true})
        const events: any[] = []//await this.props.apiService.getProjectEvents(this.props.projectId)
        this.setState({projectEvents: events, loading: false})
        if (this.state.autoRefresh) {
            this.clearAutoRefreshInterval()
            this.startAutoRefreshInterval()
        }
    }

    async componentDidMount() {
        await this.getEvents()
        if (this.state.autoRefresh) {
            this.startAutoRefreshInterval()
        }
        this.prepareMenu()
    }

    componentWillUnmount() {
        this.clearAutoRefreshInterval()
        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED, data: {
                extraMenu: []
            }
        })
    }

    async cancelUpdate() {
        this.setState({loading: true})
        /*
        if (await this.props.apiService.putCancelEvent(this.props.projectId)) {
            await this.getEvents()
        }
         */
        this.setState({loading: false})
    }

    clearAutoRefreshInterval() {
        if (this.state.autoRefreshInterval) {
            this.state.autoRefreshInterval.clear()
            this.setState({autoRefreshIntervalDown: this.countdown, autoRefreshInterval: undefined})
        }
    }

    async intervalCountdown() {
        this.setState({autoRefreshIntervalDown: this.state.autoRefreshIntervalDown - 1})
        if (this.state.autoRefreshIntervalDown === 0) {
            await this.getEvents()
        }
    }

    startAutoRefreshInterval() {
        this.setState({autoRefreshIntervalDown: this.countdown})
        if (!this.state.autoRefreshInterval) {
            const t = setInterval(this.intervalCountdown, 1000)
            this.setState({
                autoRefreshInterval: {
                    clear: () => clearInterval(t)
                }
            })
        }
    }

    toggleAutoRefresh() {
        if (this.state.autoRefresh) {
            this.clearAutoRefreshInterval()
            this.setState({autoRefresh: false, autoRefreshInterval: undefined})
        } else {
            if (!this.state.autoRefreshInterval) {
                this.startAutoRefreshInterval()
            }
            this.setState({autoRefresh: true})
        }
    }

    async getCurrentTemplate() {
        this.setState({getCurrentTemplateLoading: true})
        const response: any = undefined//await this.props.apiService.getCurrentTemplate(this.props.projectId)
        if (response && response.templateBase64String) {
            const blob = new Blob([Buffer.from(response.templateBase64String, 'base64').toString('utf-8')], {type: 'application/x-yaml'})
            const href = await URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = this.props.projectId + ".template" + ".yml";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        this.setState({getCurrentTemplateLoading: false})
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Table
                        size={"small"}
                        pagination={{defaultPageSize: 50}}
                        dataSource={this.state.projectEvents} columns={[
                        {
                            title: <>Time
                                <Button key="Refresh" type={"link"} loading={this.state.loading}
                                        onClick={async () => await this.getEvents()}>Refresh</Button>
                            </>,
                            render: (event: IProjectEvent) => <>
                                {new Date(event.data.timestampInMilliseconds).toISOString()}
                            </>
                        },
                        {
                            title: 'Resource Id',
                            render: (event: IProjectEvent) => <>
                                {event.data.resourceId}
                            </>
                        },
                        {
                            title: 'Initiated By',
                            render: (event: IProjectEvent) => <>
                                {event.initiatedBy}
                            </>
                        },
                        {
                            title: 'Project Status',
                            render: (event: IProjectEvent) => <>
                                {
                                    event.data.projectStatus.endsWith('IN_PROGRESS') ? <>
                                        <Tag icon={<ClockCircleOutlined/>} color="processing">
                                            {event.data.projectStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.projectStatus.endsWith('FAILED') ? <>
                                        <Tag icon={<CloseCircleOutlined/>} color="error">
                                            {event.data.projectStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.projectStatus.endsWith('SUCCEEDED') || event.data.projectStatus.endsWith('COMPLETED') ? <>
                                        <Tag icon={<CheckCircleOutlined/>} color="success">
                                            {event.data.projectStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.projectStatus.endsWith('CANCELLED') ? <>
                                        <Tag icon={<ExclamationCircleOutlined/>} color="warning">
                                            {event.data.projectStatus}
                                        </Tag>
                                    </> : ''
                                }
                            </>
                        },
                        {
                            title: 'Event Status',
                            render: (event: IProjectEvent) => <>
                                {
                                    event.data.eventStatus.endsWith('IN_PROGRESS') ? <>
                                        <Tag icon={<ClockCircleOutlined/>} color="processing">
                                            {event.data.eventStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.eventStatus.endsWith('FAILED') ? <>
                                        <Tag icon={<CloseCircleOutlined/>} color="error">
                                            {event.data.eventStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.eventStatus.endsWith('SUCCEEDED') || event.data.eventStatus.endsWith('COMPLETED') ? <>
                                        <Tag icon={<CheckCircleOutlined/>} color="success">
                                            {event.data.eventStatus}
                                        </Tag>
                                    </> : ''
                                }
                                {
                                    event.data.eventStatus.endsWith('CANCELLED') ? <>
                                        <Tag icon={<ExclamationCircleOutlined/>} color="warning">
                                            {event.data.eventStatus}
                                        </Tag>
                                    </> : ''
                                }
                            </>
                        },
                        {
                            title: 'Reason',
                            render: (event: IProjectEvent) => <>
                                {event.data.reason}
                            </>
                        }
                    ]}/>
                </CustomSpinner>
            </>
        );
    }
}