import { Form } from 'antd'
import React, { FC } from 'react'
import Editor from '@monaco-editor/react'
import { ThemeContext } from '../../../Contexts/ThemeContext'

const DestinationFirestoreForm: FC = props => {
    return (
        <>
            <Form.Item label="Credentials" name={'credentials'}>
                <ThemeContext.Consumer>
                    {({ isDarkMode }) => <Editor key={'credentials'} height={'50vh'} language={'json'} theme={isDarkMode ? 'vs-dark' : 'light'} />}
                </ThemeContext.Consumer>
            </Form.Item>
        </>
    )
}

export default DestinationFirestoreForm
