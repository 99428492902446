import React, {Component} from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Breadcrumb, Button, Divider, Layout, Table, Tag } from 'antd';
import {APIService} from "../../Api/APIService";
import CustomSpinner from "../../Components/CustomSpinner";
import {Link} from "react-router-dom";
import {IBillingAccountItem} from "../../Interfaces/BillingAccountInterfaces";

const {Content} = Layout;


interface Props {
    apiService: APIService
}

interface State {
    loading: boolean
    billingAccounts: IBillingAccountItem[]
    billingAccountCreating: boolean
}

export default class BillingAccountList extends Component<Props, State> {
    private readonly apiService: APIService;

    constructor(props: Props) {
        super(props);
        this.apiService = props.apiService

        this.state = {
            loading: true,
            billingAccounts: [],
            billingAccountCreating: false
        }
        this.getAccounts = this.getAccounts.bind(this)
        this.createNewBillingAccount = this.createNewBillingAccount.bind(this)
    }

    async getAccounts() {
        const response = await this.apiService.getBillingAccounts()
        if (response)
            this.setState({billingAccounts: response})
        this.setState({loading: false})
    }

    async componentDidMount() {
        await this.getAccounts()
    }

    async createNewBillingAccount() {
        this.setState({billingAccountCreating: true})
        const response = await this.apiService.createNewBillingAccount()
        if (response)
            await this.getAccounts()
        this.setState({billingAccountCreating: false})

    }

    render() {
        return (
            <Layout>
                <Layout style={{padding: '0 24px 24px', overflow: "auto"}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                        }}
                    >
                        <div>
                            <CustomSpinner spinning={this.state.loading}>
                                <PageHeader
                                    ghost={false}
                                    onBack={() => window.history.back()}
                                    title={"Billing"}
                                    subTitle="Accounts"
                                    extra={[
                                        <Button type={"link"} loading={this.state.billingAccountCreating}
                                                onClick={this.createNewBillingAccount}>Create New
                                            Billing Account</Button>
                                    ]}
                                    footer={
                                        <>
                                            <Divider/>
                                            <Table
                                                pagination={{defaultPageSize: 20}}
                                                columns={[
                                                    {
                                                        title: 'ID',
                                                        render: (item: IBillingAccountItem) => <Link
                                                            to={"/billing/" + item.data.data.billingAccountId}>{item.data.data.billingAccountId}</Link>
                                                    },
                                                    {
                                                        title: 'Payment Methods',
                                                        render: (item: IBillingAccountItem) =>
                                                            <>
                                                                {item.data.data.paymentMethods.length}
                                                            </>
                                                    },
                                                    {
                                                        title: 'Roles',
                                                        render: (item: IBillingAccountItem) => {
                                                            return item.adminBillingAccount.roles.map(role => {
                                                                return <>
                                                                    <Tag color={"geekblue"}
                                                                         key={role}>
                                                                        {role}
                                                                    </Tag>
                                                                </>
                                                            })
                                                        }
                                                    }
                                                ]}
                                                dataSource={this.state.billingAccounts}/>
                                        </>
                                    }
                                >
                                </PageHeader>
                            </CustomSpinner>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}