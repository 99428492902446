import { z } from 'zod'
import { isEqual } from 'lodash'
import React, { Component } from 'react'
import Editor from '@monaco-editor/react'
import RBS, { RetterCallResponse, RetterCloudObject, RetterCloudObjectState } from '@retter/sdk'
import { AutoComplete, Button, Empty, Form, Input, Modal, notification, Select, Space } from 'antd'
import { RoleItem } from '../../../Interfaces/IRoles'
import { GlobalHelpers } from '../../../GlobalHelpers'
import { ThemeContext } from '../../../Contexts/ThemeContext'
import CustomSpinner from '../../../Components/CustomSpinner'

interface Props {
    projectId: string
    input: {
        classId: string
        instanceId: string
    }
    rootSdkInstance: RBS
    thisClassInstance?: RetterCloudObject
    isStateEditable: boolean
}

const stateSchema = z.object({
    public: z.record(z.any()),
    private: z.record(z.any()),
    user: z.record(z.any()),
    role: z.record(z.any()),
})

interface State {
    cosState?: RetterCallResponse<RetterCloudObjectState>
    loading: boolean
    roles: RoleItem[]
    filteredRoles: RoleItem[]
    isEditing: boolean
    editedState?: string
    isStateChanged: boolean
    stateVersion?: number
}

export class CosStatesTab extends Component<Props, State> {
    private editorRef: any

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            roles: [],
            filteredRoles: [],
            isEditing: false,
            isStateChanged: false,
        }
        this.execute = this.execute.bind(this)
        this.identityCompleteOnFocus = this.identityCompleteOnFocus.bind(this)
        this.execute = this.execute.bind(this)
        this.identityCompleteOnFocus = this.identityCompleteOnFocus.bind(this)
        this.handleRoleInputSearch = this.handleRoleInputSearch.bind(this)
    }

    async execute(form: any) {
        this.setState({
            loading: true,
        })
        const user = form.userId && form.userId !== '' && form.identity && form.identity !== '' ? form : undefined
        let ins = this.props.thisClassInstance
        let result
        if (ins) {
            if (user) {
                try {
                    const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, user)
                    if (this.state.isEditing) {
                        await this.updateState(token)
                    }
                    result = await ins.getState({ token })
                    this.setState({
                        cosState: result,
                        stateVersion: result.headers['x-rio-state-version'] ? parseInt(result.headers['x-rio-state-version']) : undefined,
                    })
                } catch (e: any) {
                    if (e.response && e.response.data && e.response.data.message) {
                        notification.error({
                            placement: 'bottomRight',
                            message: e.response.data && e.response.data.issues ? e.response.data.issues.map((i: any) => i.message).join(', ') : e.response.data.message,
                        })
                    } else {
                        notification.error({
                            placement: 'bottomRight',
                            message: 'Error',
                        })
                    }
                    this.setState({ loading: false })
                }
            } else {
                try {
                    const currentUser = await this.props.rootSdkInstance.getCurrentUser()
                    if (!currentUser) throw new Error('current user not found')
                    const token = await GlobalHelpers.getAccessTokenForCustomUser(this.props.rootSdkInstance, this.props.projectId, {
                        identity: currentUser.identity!,
                        userId: currentUser.userId!,
                    })
                    if (this.state.isEditing) {
                        await this.updateState(token)
                    }
                    result = await ins.getState({
                        token,
                        httpMethod: form.httpMethod,
                    })
                    this.setState({
                        cosState: result,
                        stateVersion: result.headers['x-rio-state-version'] ? parseInt(result.headers['x-rio-state-version']) : undefined,
                    })
                } catch (e: any) {
                    if (e.response && e.response.data && e.response.data.message) {
                        notification.error({
                            placement: 'bottomRight',
                            message: e.response.data && e.response.data.issues ? e.response.data.issues.map((i: any) => i.message).join(', ') : e.response.data.message,
                        })
                    } else {
                        notification.error({
                            placement: 'bottomRight',
                            message: 'Error',
                        })
                    }
                    this.setState({ loading: false })
                }
            }
        }
        this.setState({
            loading: false,
        })
    }

    async updateState(token: string) {
        const cos = this.props.thisClassInstance
        await cos?.call({
            token,
            method: 'setState',
            body: {
                state: JSON.parse(this.state.editedState || ''),
                version: this.state.stateVersion,
            },
        })
        this.setState({
            isEditing: false,
            isStateChanged: false,
        })
    }

    async identityCompleteOnFocus() {
        const projectRoles: any = undefined //await this.props.apiService.getProjectRoles(this.props.projectId)
        if (projectRoles)
            this.setState({
                roles: projectRoles.projectRoles,
            })
    }

    handleRoleInputSearch(key: string) {
        this.setState({
            filteredRoles: this.state.roles.filter(r => r.roleName.includes(key.toLowerCase())) || [],
        })
    }

    render() {
        let isStateValid = true
        let zodError: z.ZodIssue[] | undefined = undefined

        if (this.state.editedState) {
            try {
                const obj = JSON.parse(this.state.editedState || '')
                stateSchema.strict().parse(obj)
            } catch (error) {
                isStateValid = false
                if (error instanceof z.ZodError) zodError = error.issues
                else zodError = undefined
            }
        }

        return (
            <>
                <Form name="call_instance_state" layout="inline" onFinish={this.execute}>
                    <Form.Item name="identity" label="Identity">
                        <AutoComplete onFocus={this.identityCompleteOnFocus} onSearch={this.handleRoleInputSearch} style={{ width: 200 }} placeholder="Identity">
                            {this.state.filteredRoles.map(r => {
                                return (
                                    <AutoComplete.Option key={r.roleName} value={r.roleName}>
                                        {r.roleName}
                                    </AutoComplete.Option>
                                )
                            })}
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item name="userId" label="User Id">
                        <Input />
                    </Form.Item>
                    <Form.Item name={'httpMethod'}>
                        <Select defaultValue="post" style={{ width: 120 }}>
                            <Select.Option value="post">POST</Select.Option>
                            <Select.Option value="get">GET</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {this.state.isEditing ? (
                            <Space>
                                <Button
                                    htmlType="button"
                                    onClick={() => {
                                        if (this.editorRef) {
                                            this.editorRef.setValue(JSON.stringify(this.state.cosState?.data, null, 2))
                                        }
                                        this.setState({ isEditing: false })
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button htmlType="submit" disabled={!this.state.isStateChanged || !isStateValid} loading={this.state.loading} type={'primary'}>
                                    Save
                                </Button>
                                {!isStateValid && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ color: 'red' }}>State is not valid</div>
                                        {zodError != null && (
                                            <Button
                                                onClick={() => {
                                                    Modal.error({
                                                        title: 'State errors',
                                                        content: (
                                                            <div>
                                                                {zodError?.map((e, i) => {
                                                                    return <div key={i}>{e.message}</div>
                                                                })}
                                                            </div>
                                                        ),
                                                    })
                                                }}
                                                size="small"
                                                danger
                                                style={{ marginLeft: 10 }}
                                            >
                                                Show errors
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </Space>
                        ) : (
                            <Button htmlType="submit" loading={this.state.loading} type={'primary'}>
                                Get
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                <br />
                <CustomSpinner spinning={this.state.loading}>
                    {this.state.cosState ? (
                        <>
                            <ThemeContext.Consumer>
                                {({ isDarkMode }) => (
                                    <Editor
                                        options={{ readOnly: !this.state.isEditing }}
                                        height={'calc(100vh - 64px - 164px)'}
                                        language={'json'}
                                        theme={isDarkMode ? 'vs-dark' : 'light'}
                                        defaultValue={JSON.stringify(this.state.cosState?.data, null, 2)}
                                        onMount={(editor, monaco) => {
                                            this.editorRef = editor
                                            if (this.props.isStateEditable) {
                                                editor.addAction({
                                                    id: 'edit',
                                                    label: 'Edit',
                                                    contextMenuGroupId: 'navigation',
                                                    contextMenuOrder: 1.5,
                                                    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyE],
                                                    run: () => {
                                                        this.setState({
                                                            isEditing: true,
                                                        })
                                                    },
                                                })
                                            }
                                        }}
                                        onChange={(value, event) => {
                                            try {
                                                const obj = JSON.parse(value ?? '')
                                                this.setState({
                                                    editedState: value,
                                                    isStateChanged: !isEqual(obj, this.state.cosState?.data),
                                                })
                                            } catch (e) {
                                                this.setState({
                                                    editedState: value,
                                                    isStateChanged: true,
                                                })
                                            }
                                        }}
                                    />
                                )}
                            </ThemeContext.Consumer>
                        </>
                    ) : (
                        <>
                            <Empty description={'No Data'} />
                        </>
                    )}
                </CustomSpinner>
            </>
        )
    }
}
