import React, { Component } from 'react'
import { Button, Form, Input, Modal, notification, Select, Spin } from 'antd'
import { RootProjectClassMethods } from '../Api/APIService'
import { RoleItem } from '../Interfaces/IRoles'
import { RetterCloudObject } from '@retter/sdk'

const { Option } = Select

interface Props {
    projectInstance: RetterCloudObject
    projectId: string
}

interface State {
    loading: boolean
    visible: boolean
    developerRoles: RoleItem[]
    selectedRole: string
}

export class AddMemberToProject extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            developerRoles: [],
            selectedRole: '',
        }
        this.addMember = this.addMember.bind(this)
        this.selectRolesChange = this.selectRolesChange.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    async addMember(email: string, role: string) {
        this.setState({ loading: true })
        try {
            await this.props.projectInstance.call<any>({
                method: RootProjectClassMethods.addMember,
                body: {
                    email,
                    role,
                },
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
            this.setState({
                visible: false,
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    onFinish = async (values: any) => {
        const { email, role } = values
        await this.addMember(email, role)
    }

    onFinishFailed = (errorInfo: any) => {}

    selectRolesChange(value: string, option: any) {
        this.setState({
            selectedRole: value,
        })
    }

    render() {
        const { visible, loading } = this.state

        return (
            <>
                <Button type="link" onClick={this.showModal}>
                    Add New Member
                </Button>
                <Modal
                    open={visible}
                    title="Add New Member"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type={'link'} onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>,
                        <Button type="primary" form={'add_project_member'} htmlType="submit">
                            Add
                        </Button>,
                    ]}
                >
                    <Spin spinning={loading}>
                        <Form
                            layout={'vertical'}
                            id={'add_project_member'}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input email address' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select member role' }]}>
                                <Select onChange={this.selectRolesChange} value={this.state.selectedRole} allowClear placeholder="Select role">
                                    <Option value={'owner'}>owner</Option>
                                    <Option value={'writer'}>writer</Option>
                                    <Option value={'reader'}>reader</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        )
    }
}
