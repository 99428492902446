import {Action, createStore} from "redux";

export interface IUserProjectItem {
    alias: string
    role: string
    projectId: string
    status?: 'deactivated' | 'active'
}

export enum UserStoreActionTypes {
    CHANGED = "CHANGED"
}

export interface IUserInvites {
    [email: string]: { status: string, invitedAt: number, confirmedAt?: number }
}

export interface IUserStoreDataItems {
    credentials?: { secretId: string, secretKey: string },
    email: string,
    myProjects: IUserProjectItem[]
    invites?: IUserInvites
    inviteLimit: number
}

export interface IUserStoreData {
    data: IUserStoreDataItems
}


export const userStore = createStore((s: any, a: Action<UserStoreActionTypes> & IUserStoreData): Action<UserStoreActionTypes> & IUserStoreData => {
    switch (a.type) {
        case UserStoreActionTypes.CHANGED:
            return a
    }
})
