import React, {FC} from "react";
import {Button, Form, Input, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

interface Props {
    onChange: (value: any) => void;
}

const CosMethodHeaders: FC<Props> = (props) => {
    const [form] = Form.useForm();
    return (
        <>
            <Form form={form} name="cos_method_headers" onChange={(e) => {
                props.onChange(form.getFieldValue('headers'))
            }} autoComplete="off">
                <Form.List name="headers">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{required: true, message: 'Missing key'}]}
                                    >
                                        <Input placeholder="Key"/>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{required: true, message: 'Missing value'}]}
                                    >
                                        <Input placeholder="Value"/>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => {
                                        remove(name)
                                        props.onChange(form.getFieldValue('headers'))
                                    }}/>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </>
    )
}
export default CosMethodHeaders;
