import {Button, Form, Input, Space} from "antd";
import React, {FC} from "react";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";


const DestinationHttpForm: FC = (props) => {


    return (
        <>
            <Form.Item
                label="Credentials"
                name={"credentials"}
            >
                <Form.Item
                    label={"Url"}
                    name={["credentials", "url"]}
                    rules={[{required: true, message: 'Missing value!'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={"Method"}
                    name={["credentials", "method"]}
                    rules={[{required: true, message: 'Missing value!'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.List name={["credentials", "headers"]}>
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{required: true, message: 'Missing key'}]}
                                    >
                                        <Input placeholder="Key"/>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{required: true, message: 'Missing value'}]}
                                    >
                                        <Input placeholder="Value"/>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)}/>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add header
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </>
    )
}

export default DestinationHttpForm
