import React, {Component} from 'react';
import {Pie} from '@ant-design/plots';


export interface DataItem {
    type: string
    value: number
}

export interface Props {
    currency: string
    data: DataItem[]
}

export interface State {

}

export default class PricePie extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const currency = this.props.currency
        return (
            <>
                <Pie
                    appendPadding={10}
                    radius={1}
                    innerRadius={0.70}
                    angleField={"value"}
                    colorField={"type"}
                    data={this.props.data}
                    interactions={[
                        {type: 'element-selected'},
                        {type: 'element-active'},
                        {type: 'pie-statistic-active'},
                    ]}
                    meta={{
                        value: {
                            formatter: function (v, k) {
                                if(v) return ''.concat(`${currency} `, v.toFixed(2))
                                else return ''.concat(` ${currency} `,"0")
                            }
                        }
                    }}
                    statistic={{
                        title: {
                            formatter: function (datum, data) {
                                if (datum && datum.type) return datum.type
                                else return "Total"
                            }
                        }
                    }}
                />
            </>
        );
    }
}