import React, { Component } from 'react'
import { Button, Empty, notification, Popconfirm, Table, Tag } from 'antd'
import { APIService, RootProjectClassEnums, RootProjectClassMethods } from '../Api/APIService'
import { CreateNewProjectModal } from '../Modals/CreateNewProjectModal'
import { IProjectSummary } from '../Interfaces/IProjectSummary'
import CustomSpinner from '../Components/CustomSpinner'
import emptySvg from '../empty.svg'
import { RouteComponentProps } from 'react-router-dom'
import { IUserProjectItem, userStore } from '../Actions/Stores/UserStore'
import { ActionTypes, topMenuStore } from '../Actions/Actions'
import { GlobalHelpers } from '../GlobalHelpers'

interface Props {
    apiService: APIService
    routeComponentProps: RouteComponentProps
}

interface State {
    loading: boolean
    projects?: IUserProjectItem[]
    projectDetail?: IProjectSummary
    adminEmail?: string
    adminId?: string
    ownProjects: { adminId: string; projectId: string }[]
}

export default class ProjectsLayout extends Component<Props, State> {
    private readonly apiService: APIService

    constructor(props: Props) {
        super(props)
        const admin = JSON.parse(localStorage.getItem('user')!)
        this.apiService = props.apiService

        this.state = {
            loading: true,
            adminEmail: admin ? admin.email : undefined,
            adminId: admin ? admin.username : undefined,
            ownProjects: [],
        }
        this.leaveTheProject = this.leaveTheProject.bind(this)
    }

    async componentDidMount() {
        const state = userStore.getState()
        if (state) {
            this.setState({
                projects: state.data.myProjects.map(mp => {
                    return { ...mp, ...{ status: mp.status ? mp.status : 'active' } }
                }),
                loading: false,
            })
        }
        userStore.subscribe(() => {
            const state = userStore.getState()
            this.setState({
                projects: state.data.myProjects.map(mp => {
                    return { ...mp, ...{ status: mp.status ? mp.status : 'active' } }
                }),
                loading: false,
            })
        })
        if (this.state.projects) {
            this.setState({
                loading: false,
            })
        }
        topMenuStore.dispatch({
          type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
          data: {
            title: 'Projects',
            extraMenu: [
              <>
                <CreateNewProjectModal
                  onSuccess={(projectId) => {
                    this.props.routeComponentProps.history.push(
                      `/project/${projectId}/Overview`
                    );
                  }}
                  apiService={this.apiService}
                />
              </>,
            ],
          },
        });
    }

    componentWillUnmount() {}

    async leaveTheProject(projectId: string) {
        const instance = await this.props.apiService.rootRbsSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: projectId,
        })
        try {
            await instance.call<any>({
                method: RootProjectClassMethods.leaveProject,
                body: {
                    projectId,
                },
            })
            if (this.state.projects)
                this.setState({
                    projects: this.state.projects?.filter(p => p.projectId !== projectId),
                })
        } catch (e: any) {
            let mes = 'error'
            if (e.response) {
                mes = e.response.data
            }
            notification.error({
                placement: 'bottomRight',
                message: mes,
            })
        }
    }

    goToProject = (projectId: string) => {
        this.props.routeComponentProps.history.push(`/project/${projectId}/Overview`)
    }

    handleClick = (project: IProjectSummary, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (GlobalHelpers.newTab(event)) { // Middle mouse button
            GlobalHelpers.openInNewTab(`/project/${project.projectId}/Overview`); // Open in a new tab
        } else {
            this.goToProject(project.projectId); // Normal left-click behavior
        }
    };

    render() {
        return (
            <CustomSpinner spinning={this.state.loading}>
                {Array.isArray(this.state.projects) && this.state.projects.length > 0 ? (
                    <>
                        <Table
                            size={'middle'}
                            style={{ margin: 15 }}
                            scroll={{ x: 200 }}
                            pagination={{ defaultPageSize: 50 }}
                            dataSource={this.state.projects}
                            columns={[
                                {
                                    title: 'Alias',
                                    width: '40%',
                                    render: (project: IProjectSummary) => {
                                        const buttonStyle = {
                                            color: '#ff4e27',
                                        };
                                    
                                        return (
                                            <Button
                                                type={"link"}
                                                style={buttonStyle}
                                                onClick={ (e: any) => { this.handleClick(project, e) } }                                            >
                                                {project.alias}
                                            </Button>
                                        );
                                    },
                                },
                                {
                                    title: 'Project Id',
                                    render: (project: IProjectSummary) => {
                                   
                                        return <span>{project.projectId}</span>;
                                    },
                                },
                                {
                                    title: 'Status',
                                    dataIndex: 'status',
                                    defaultFilteredValue: ['active'],
                                    filters: [
                                        {
                                            text: 'Active',
                                            value: 'active',
                                        },
                                        {
                                            text: 'Deactivated',
                                            value: 'deactivated',
                                        },
                                    ],
                                    onFilter: (value, record) => record.status === value,
                                    render: (val, rec) => {
                                        return (rec.status || 'active') === 'active' ? <Tag color="orange">{rec.status}</Tag> : <Tag color="red">{rec.status}</Tag>
                                    },
                                },
                                {
                                    title: 'Role',
                                    render: (project: IProjectSummary) => {
                                        return (
                                            <>
                                                {this.state.projects?.find(p => p.projectId === project.projectId)?.role === 'owner' ? (
                                                    <Tag>owner</Tag>
                                                ) : (
                                                    <>
                                                        <Popconfirm
                                                            title="Are you sure to leave this project?"
                                                            onConfirm={async () => {
                                                                await this.leaveTheProject(project.projectId)
                                                            }}
                                                            okType={'danger'}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type="primary" size="small" danger>
                                                                Leave
                                                            </Button>
                                                        </Popconfirm>
                                                    </>
                                                )}
                                            </>
                                        )
                                    },
                                },
                            ]}
                        />
                    </>
                ) : (
                    <Empty
                        image={emptySvg}
                        imageStyle={{
                            height: 60,
                        }}
                        style={{ margin: 15 }}
                        description={'No projects'}
                    >
                        <CreateNewProjectModal
                            onSuccess={projectId => {
                                this.props.routeComponentProps.history.push(`/project/${projectId}/Overview`)
                            }}
                            apiService={this.apiService}
                        />
                    </Empty>
                )}
            </CustomSpinner>
        )
    }
}
