import React, {Component} from "react";
import {APIService} from "../../../Api/APIService";
import {Table} from "antd";


interface Props {
    apiService: APIService
    billingAccountId: string
}

interface State {
    loading: boolean
}

export class BillingAccountInvoices extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
                <Table
                    pagination={{defaultPageSize: 20}}
                    columns={[
                        {
                            title: 'ID',
                            render: (item: any) => <>{}</>
                        }
                    ]}
                    dataSource={[]}/>
            </>
        );
    }

}