import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import CustomSpinner from '../../Components/CustomSpinner'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import RBS from '@retter/sdk'

interface Props {
    visible: boolean
    cancelCallback: () => void
    rootRbsSdk: RBS
}

const ChangeProjectSubscriptionModal: FC<Props> = props => {
    const [loading, setLoading] = useState<boolean>(false)
    const [getting, setGetting] = useState<boolean>(false)
    const [subscriptionIds, setSubscriptionIds] = useState<string[]>([])
    const [form] = Form.useForm()

    const getSubscriptions = async () => {
        setGetting(true)
        try {
            const retterClassInstance = await props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.SubscriptionManager,
                instanceId: 'default',
            })
            const response = await retterClassInstance.call<any>({
                method: RootProjectClassMethods.listSubscriptions,
            })
            if (response && Array.isArray(response.data)) {
                setSubscriptionIds(response.data.map(s => s.id))
            }
            form.resetFields()
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e.response ? e.response.data.message || 'error' : 'error',
            })
        }
        setGetting(false)
    }

    const changeProjectSubscription = async (values: any) => {
        setLoading(true)
        try {
            const retterClassInstance = await props.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.Project,
                instanceId: values.projectId,
            })
            await retterClassInstance.call<any>({
                method: RootProjectClassMethods.updateConfig,
                body: values,
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
            form.resetFields()
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e.response ? e.response.data.message || 'error' : 'error',
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getSubscriptions().catch()
    }, [])

    return (
        <>
            <Modal
                title="Settings"
                width={777}
                open={props.visible}
                onCancel={props.cancelCallback}
                footer={[
                    <Button type={'default'} onClick={props.cancelCallback}>
                        Cancel
                    </Button>,
                ]}
            >
                <CustomSpinner spinning={loading}>
                    <Form form={form} name="basic" onFinish={changeProjectSubscription} autoComplete="off">
                        <Form.Item label="Project Alias" name="alias" rules={[{ required: true, message: 'Please input project alias!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Project Id" name="projectId" rules={[{ required: true, message: 'Please input project id!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Subscription Id" name="subscriptionId" rules={[{ required: true, message: 'Please input subscription id!' }]}>
                            <Select loading={getting} style={{ width: 120 }}>
                                {subscriptionIds.map(sid => {
                                    return <Select.Option value={sid}>{sid}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </CustomSpinner>
            </Modal>
        </>
    )
}

export default ChangeProjectSubscriptionModal
