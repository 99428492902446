import React from 'react';
import {Button, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';

interface Props {
    loading: boolean;
    onSignInPressed: () => void
    onForgotPasswordSubmitPressed: (values: Values) => void;
    username: string;
}

interface Values {
    email: string;
    code: string;
    password: string;
}

const ForgotPasswordSubmitForm = ({
                                      loading,
                                      onForgotPasswordSubmitPressed,
                                      onSignInPressed,
                                      username
                                  }: Props) => {

    const onFinish = (values: Values) => {
        values.email = username
        onForgotPasswordSubmitPressed(values)
    }

    return (
        <Form
            autoComplete={'off'}
            size={'large'}
            name="forgot-password-submit-form"
            className="login-form"
            initialValues={{email: username}}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {type: 'email', message: 'The input is not valid E-mail!'},
                    {required: true, message: "Please input your Email!"}
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon"/>}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item
                name="code"
                rules={[
                    {required: true, message: "Please input your Code!"}
                ]}
            >
                <Input
                    placeholder="Code"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{required: true, message: "Please input your Password!"}]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    style={{marginBottom: 10}}
                    block
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    Set New Password
                </Button>
                <Divider plain>Or</Divider>
                <span
                    onClick={onSignInPressed}
                    className="login-form-forgot"
                    style={{color: '#1890ff', cursor: 'pointer'}}
                >
          Log In
        </span>
            </Form.Item>
        </Form>
    )
}

export default ForgotPasswordSubmitForm;