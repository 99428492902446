import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Button, Dropdown, Menu, Modal, Space, Table, Timeline, notification, Typography, Badge, Tag, Row, Col, Card, Progress } from 'antd'
import { ICloudObjectClass } from '../../Interfaces/CloudObjetsInterfaces'
import CreateClassModal from '../../Modals/CreateClassModal'
import { RootProjectContext } from '../../Contexts/RootProjectContext'
import _ from 'lodash'
import { Unsubscribable } from 'rxjs'
import { ActionTypes, topMenuStore } from '../../Actions/Actions'
import { CheckCircleTwoTone, CloseCircleTwoTone, DownOutlined, ExclamationCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons'
import { RootProjectClassMethods } from '../../Api/APIService'
import { ArgsProps } from 'antd/lib/notification'
import moment from 'dayjs'

interface Props {
    routeComponentProps: RouteComponentProps<{ projectId: string }>
}

interface State {
    showLatests: boolean
    deployLoading: boolean
    publicSub?: Unsubscribable
}

const { Title } = Typography

class CloudObjectListLayout extends Component<Props, State> {
  projectId: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      showLatests: false,
      deployLoading: false,
    };
    this.projectId = props.routeComponentProps.match.params.projectId;
    this.createSuccessCallback = this.createSuccessCallback.bind(this);
  }

  async createSuccessCallback(classId: string) {
    this.props.routeComponentProps.history.push(
      `/project/${this.projectId}/Classes/${classId}`
    );
  }

  componentDidMount(): void {
    topMenuStore.dispatch({
      type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
      data: {
        extraMenu: [
          <Dropdown
            overlay={
              <>
                <Menu mode={"inline"} selectedKeys={[]}>
                  <Menu.Item
                    key="deploy"
                    onClick={async () => {
                      await this.deployClass();
                    }}
                  >
                    Project Deploy
                  </Menu.Item>
                  <Menu.Item
                    key="force-deploy"
                    onClick={async () => {
                      await this.deployClass(true);
                    }}
                  >
                    Force Project Deploy
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    key="show-lastests"
                    onClick={() => {
                      this.setState({
                        showLatests: !this.state.showLatests,
                      });
                    }}
                  >
                    Latest Deployments
                  </Menu.Item>
                </Menu>
              </>
            }
          >
            <Button type={"primary"}>
              <Space>
                Project Deploy
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>,
        ],
      },
    });
  }

  componentWillUnmount(): void {
    topMenuStore.dispatch({
      type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
      data: {
        extraMenu: [],
      },
    });
  }

  async showDeployClassConfirmation() {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Do you want to deploy?",
        icon: <ExclamationCircleOutlined />,
        content: <>Are you sure you want to deploy this project?</>,
        okText: "Deploy",
        cancelText: "Cancel",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  async deployClass(force: boolean = false) {
    const cos = this.context.instance;

    if (!cos) throw new Error("class instance is not defined");
    if (!(await this.showDeployClassConfirmation())) return;
    this.setState({
      deployLoading: true,
    });
    if (this.state.publicSub) this.state.publicSub.unsubscribe();
    let deploymentId: string;
    try {
      const { data } = await cos.call({
        method: RootProjectClassMethods.deployClass,
        body: {
          force,
        },
        headers: {
          "x-rio-console": "true",
        },
      });
      deploymentId = data.requestId;
    } catch (e: any) {
      if (e.response) {
        notification.error({
          placement: "bottomRight",
          message: e.response.data.message,
        });
      }
    }
    const publicSub = cos.state?.public?.subscribe(
      (event: {
        deployments?: Record<
          string,
          {
            status: "started" | "ongoing" | "failed" | "finished";
            statusMessage: string;
            error_stack?: string[];
            createdAt: number;
            createdBy: string;
            id: string;
          }
        >;
      }) => {
        const deployments = event.deployments || {};
        const deployment = deployments[deploymentId];

        if (deployment) {
          const hasErrorStack =
            Array.isArray(deployment.error_stack) &&
            deployment.error_stack.length > 0;
          const notify: ArgsProps = {
            icon: <></>,
            key: "deployment",
            placement: "bottomRight",
            message: deployment.status,
            description: `${deployment.statusMessage}${
              hasErrorStack ? " (click for details)" : ""
            }`,
            duration: 0,
            onClick: () => {
              notification.destroy("deployment");
              if (
                Array.isArray(deployment.error_stack) &&
                deployment.error_stack.length > 0
              ) {
                Modal.error({
                  title: "Deployment Error",
                  content: (
                    <div>
                      <Title level={3}>{deployment.statusMessage}</Title>
                      <Timeline style={{ marginTop: 24 }}>
                        {deployment.error_stack.map((e, i) => (
                          <Timeline.Item color="red" key={i}>
                            {e}
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  ),
                  width: 800,
                });
              }
            },
          };
          switch (deployment.status) {
            case "failed":
              notify["icon"] = (
                <>
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                </>
              );
              notification.open(notify);
              break;
            case "finished":
            case "started":
              notify["icon"] = (
                <>
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </>
              );
              notification.open(notify);
              break;
            case "ongoing":
              notify["icon"] = (
                <>
                  <InfoCircleTwoTone />
                </>
              );
              notification.open(notify);
              break;
          }
        }
      }
    );
    this.setState({
      publicSub,
    });
    this.setState({
      deployLoading: false,
    });
  }

  render() {
    return (
      <>
        <RootProjectContext.Consumer>
          {(ctx) => (
            <>
              <Row justify="space-evenly" style={{ marginBottom: 10 }}>
                <Col span={4} xs={24}>
                  <Table
                    pagination={{ defaultPageSize: 50 }}
                    columns={[
                      {
                        title: (
                          <>
                            <div
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              Classes
                              <CreateClassModal projectId={this.projectId} successCallback={this.createSuccessCallback} />
                            </div>
                          </>
                        ),
                        render: (p: ICloudObjectClass) => (
                          <>
                            <Link
                              style={{ color: "#ff4e27" }}
                              to={`/project/${this.projectId}/Classes/${p.classId}`}
                            >
                              {p.classId}
                            </Link>
                          </>
                        ),
                      },
                    ]}
                    dataSource={
                      _.sortBy(
                        ctx.detail?.classes.map((c) => {
                          return { ...c, sortKey: c.classId.toLowerCase() };
                        }),
                        "sortKey"
                      ) || []
                    }
                  />
                </Col>
              </Row>

              <Modal
                width={800}
                title="Latest Deployments"
                open={this.state.showLatests}
                onCancel={() => {
                  this.setState({
                    showLatests: false,
                  });
                }}
                footer={false}
              >
                <Table
                  dataSource={
                    ctx.detail?.deployments
                      ? Object.values(ctx.detail?.deployments)
                      : []
                  }
                  columns={[
                    {
                      key: "status",
                      title: "Status",
                      dataIndex: "status",
                      render: (d: string) => {
                        let color: string;
                        switch (d) {
                          case "started":
                            color = "blue";
                            break;
                          case "ongoing":
                            color = "blue";
                            break;
                          case "failed":
                            color = "red";
                            break;
                          case "finished":
                            color = "green";
                            break;
                          default:
                            color = "blue";
                            break;
                        }
                        return (
                          <>
                            <Tag color={color}>{d}</Tag>
                          </>
                        );
                      },
                    },
                    {
                      key: "statusMessage",
                      title: "Status Message",
                      dataIndex: "statusMessage",
                      render: (d: string, r: any) => {
                        return (
                          <>
                            {d}
                            <br />
                            {r.error_stack && r.error_stack.length > 0 && (
                              <Button
                                type="text"
                                danger
                                onClick={() => {
                                  Modal.error({
                                    title: "Deployment Error",
                                    content: (
                                      <div>
                                        <Title level={3}>
                                          {r.statusMessage}
                                        </Title>
                                        <Timeline style={{ marginTop: 24 }}>
                                          {r.error_stack.map(
                                            (e: any, i: number) => (
                                              <Timeline.Item
                                                color="red"
                                                key={i}
                                              >
                                                {e}
                                              </Timeline.Item>
                                            )
                                          )}
                                        </Timeline>
                                      </div>
                                    ),
                                    width: 800,
                                  });
                                }}
                              >
                                View Error Stack
                              </Button>
                            )}
                          </>
                        );
                      },
                    },
                    {
                      key: "createdAt",
                      title: "Started At",
                      dataIndex: "createdAt",
                      render: (d: number) => {
                        return <>{moment(d).format("YYYY-MM-DD HH:mm:ss")}</>;
                      },
                      defaultSortOrder: "descend",
                      sorter: (a, b) => a.createdAt - b.createdAt,
                    },
                    {
                      key: "updatedAt",
                      title: "Updated At",
                      dataIndex: "updatedAt",
                      render: (d: number) => {
                        if (!d) return <></>;
                        return <>{moment(d).format("YYYY-MM-DD HH:mm:ss")}</>;
                      },
                      sorter: (a, b) => a.createdAt - b.createdAt,
                    },
                  ]}
                />
              </Modal>

            </>
          )}
        </RootProjectContext.Consumer>
      </>
    );
  }
}

CloudObjectListLayout.contextType = RootProjectContext

export default CloudObjectListLayout
