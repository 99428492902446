import React from 'react'

export interface IThemeContext {
    isDarkMode: boolean
    toggleDarkMode: () => void
}

export const ThemeContext = React.createContext<IThemeContext>({
    isDarkMode: false,
    toggleDarkMode: () => {},
})
