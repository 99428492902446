import {Button, Form, Input} from "antd";
import React, {FC} from "react";


const DestinationElasticForm: FC = (props) => {


    return (
        <>
            <Form.Item
                label="Credentials"
                name="credentials"
            >
                <Form.Item
                    label="Cloud Id"
                    rules={[{required: true, message: 'Please input type!'}]}
                    name={["credentials", "cloudId"]} required>
                    <Input/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: 'Please input type!'}]}
                    label="Username"
                    name={["credentials", "username"]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: 'Please input type!'}]}
                    label="Password"
                    name={["credentials", "password"]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: 'Please input type!'}]}
                    label="Index"
                    name={["credentials", "index"]}
                >
                    <Input/>
                </Form.Item>
            </Form.Item>
        </>
    )
}

export default DestinationElasticForm
