export interface IProjectConfig {
    accessTokenSecret: string
    refreshTokenSecret: string
}

export interface IProjectRolePermissions {
    sends: string[]
    receives: string[]
}

export interface IProjectRole {
    key: string
    roleName: string
    delegatedBy: string[]
    permissions: IProjectRolePermissions
}

export interface IProjectMember {
    userId: string
    email: string
    role: string
}

export enum StateStreamTargetType {
    Elasticsearch = 'Elasticsearch',
    Firestore = 'Firestore',
    Http = 'Http',
}

export interface StateStreamRetryConfig {
    delay: number
    count: number
    rate: number
}

export interface StateStreamTarget {
    type: StateStreamTargetType
    id: string
    mappingId: string
    credentials: any
    retryConfig?: StateStreamRetryConfig
    transformationTemplate?: string
}

export interface ScanJob {
    jobId: string
    classId: string
    destinationIds: string[]
    status: string
    executionArn: string
    totalScanned: number
}

export interface IProjectConfig {
    refreshTokenSecret: string
    accessTokenSecret: string
    customTokenSecret: string
    status?: {
        type: 'deactivated' | 'active'
    }
}

export enum LogAdapterType {
    HTTP = 'HTTP',
    KINESIS = 'KINESIS',
}

export type HttpLogAdapter = {}

export type KinesisLogAdapter = {}

export type LogAdapterItem =
    | {
          id: string
          type: LogAdapterType.HTTP
          endpoint: string
          apiKey?: string
          pfactor?: number
          retryConfig?: StateStreamRetryConfig
      }
    | {
          id: string
          type: LogAdapterType.KINESIS
          streamName: string
          accessKeyId?: string
          secretAccessKey?: string
          region?: string
          pfactor?: number
          retryConfig?: StateStreamRetryConfig
      }

export interface AuthenticationRule {
    id?: string
    filter: Record<string, any>
    output: Record<string, any>
}

export interface IProjectClass {
    classId: string
}

export interface IProjectDetail {
    members: IProjectMember[]
    projectConfig: IProjectConfig
    attachedRoles: any[]
    classes: IProjectClass[]
    alias: string
    envVars: { [key: string]: string }
    projectRoles: IProjectRole[]
    layers?: { [dependencyName: string]: { version: string; hash?: string } }
    scanJobs?: ScanJob[]
    deployments?: any[]
}
