import React, {Component} from "react";
import {Col, Row} from "antd";
import {APIService} from "../../Api/APIService";
import ServicesLayout from "../ServiceLayouts/ServicesLayout/ServicesLayout";


interface Props {
    apiService: APIService
}

interface State {

}

export class ServiceList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <Row style={{marginTop: '-2.4em'}}>
                    <Col span={24}>
                        <ServicesLayout apiService={this.props.apiService}/>
                    </Col>
                </Row>
            </>
        );
    }

}