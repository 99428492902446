import React, { Component } from 'react'
import { Button, Form, Modal, notification, Select, Spin } from 'antd'
import { RoleItem } from '../Interfaces/IRoles'
import { RetterCloudObject } from '@retter/sdk'
import { RootProjectClassMethods } from '../Api/APIService'

const { Option } = Select

interface Props {
    projectInstance: RetterCloudObject
    key: string
    member: { email: string; memberId: string; currentRole: string; updateToken: string }
    projectId: string
}

interface State {
    loading: boolean
    visible: boolean
    developerRoles: RoleItem[]
    selectedRole: string
}

export class EditProjectMemberRoles extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            developerRoles: [],
            selectedRole: props.member.currentRole,
        }
        this.updateRoles = this.updateRoles.bind(this)
        this.selectRolesChange = this.selectRolesChange.bind(this)
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    async updateRoles(role: string) {
        this.setState({ loading: true })
        try {
            await this.props.projectInstance.call<any>({
                method: RootProjectClassMethods.addMember,
                body: {
                    email: this.props.member.email,
                    role,
                },
            })
            notification.success({
                placement: 'bottomRight',
                message: 'Success',
            })
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data,
                })
            }
        }
        this.setState({ loading: false, visible: false })
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    onFinish = async (values: any) => {
        const { role } = values
        await this.updateRoles(role)
    }

    onFinishFailed = (errorInfo: any) => {}

    selectRolesChange(value: string, option: any) {
        this.setState({
            selectedRole: value,
        })
    }

    render() {
        const { visible, loading } = this.state

        return (
            <>
                <Button type="link" onClick={this.showModal}>
                    Edit Roles
                </Button>
                <Modal
                    key={this.props.key}
                    open={visible}
                    title="Edit Project Member Roles"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type={'link'} onClick={this.handleCancel} loading={loading}>
                            Cancel
                        </Button>,
                        <Button type="primary" form={'edit_project_member'} htmlType="submit">
                            Update
                        </Button>,
                    ]}
                >
                    <Spin spinning={loading}>
                        <h3>{this.props.member.email}</h3>
                        <Form
                            layout={'vertical'}
                            id={'edit_project_member'}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select member role' }]}>
                                <Select
                                    onChange={this.selectRolesChange}
                                    defaultValue={this.state.selectedRole}
                                    value={this.state.selectedRole}
                                    allowClear
                                    placeholder="Select role"
                                >
                                    <Option value={'owner'}>owner</Option>
                                    <Option value={'writer'}>writer</Option>
                                    <Option value={'reader'}>reader</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        )
    }
}
