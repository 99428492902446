import React, {Component} from "react";
import {Table} from "antd";
import {RootProjectContext} from "../Contexts/RootProjectContext";
import _ from "lodash";

interface Props {

}

interface State {
    loading: boolean
}

export class DependenciesLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        return (
            <>
                <RootProjectContext.Consumer>
                    {(ctx) => (
                        ctx && ctx.detail ? <>
                            <Table
                                columns={[
                                    {
                                        title: <>Dependency Name</>,
                                        key: 'name',
                                        render: (model: { dependencyName: string, hash: string }) => model.dependencyName,
                                    },
                                    {
                                        title: <>Dependency Hash</>,
                                        key: 'hash',
                                        render: (model: { dependencyName: string, hash: string }) => model.hash,
                                    }
                                ]} dataSource={_.sortBy(Object.keys(ctx.detail.layers || {}).map(d => {
                                return {
                                    sortKey: d.toLowerCase(),
                                    key: d,
                                    dependencyName: d,
                                    hash: ctx.detail && ctx.detail.layers ? ctx.detail.layers[d].hash : '-',
                                    version: ctx.detail && ctx.detail.layers ? ctx.detail.layers[d].version : '-'
                                }
                            }), 'sortKey')}/>

                        </> : null
                    )}
                </RootProjectContext.Consumer>
            </>
        )
    }

}
