import {Action, createStore} from "redux";
import {IProjectDetail} from "../Interfaces/IProject";

export enum ProjectDetailStoreActionTypes {
    CHANGED = "CHANGED"
}

export interface IProjectDetailStoreData {
    data: IProjectDetail
}


export const projectDetailStore = createStore((s: any, a: Action<ProjectDetailStoreActionTypes> & IProjectDetailStoreData): Action<ProjectDetailStoreActionTypes> & IProjectDetailStoreData => {
    switch (a.type) {
        case ProjectDetailStoreActionTypes.CHANGED:
            return a
    }
})
