/** @format */

import React, { Component } from "react";
import { Badge, Card, Col, Divider, Progress, Row, Typography } from "antd";
import {
  IProjectContext,
  RootProjectContext,
} from "../Contexts/RootProjectContext";
import CustomSpinner from "../Components/CustomSpinner";
import { IDashboardResponse } from "../Interfaces/IDashboardResponse";
import { Column } from "@ant-design/plots";
import dayjs from "dayjs";

interface Props {}

interface State {
  loading: boolean;
  usages: {
    [key: string]: {
      apiCall: number;
      userCodeDuration: number;
    };
  };
  limits: {
    apiCall: number;
    userCodeDuration: number;
  };
  subscriptionId: string;
}

class DashboardLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      usages: {},
      limits: {
        apiCall: 0,
        userCodeDuration: 0,
      },
      subscriptionId: "free",
    };
    this.getDashboard = this.getDashboard.bind(this);
  }

  async getDashboard() {
    this.setState({ loading: true });
    const context = this.context as IProjectContext;
    try {
      if (context.instance) {
        const result = await context.instance.call({
          method: "getDashboard",
        });

        if (result.status === 200) {
          const data = result.data as IDashboardResponse;
          this.setState({
            limits: data.limits,
            usages: data.usages,
            subscriptionId: data.subscriptionId,
          });
        }
      }
    } catch (e: any) {
      console.log(e);
    }
    this.setState({ loading: false });
  }

  async componentDidMount() {
    if (this.context) {
      await this.getDashboard();
    }
    this.setState({
      loading: false,
    });
  }

  getDaily(key: "apiCall" | "userCodeDuration") {
    const today = dayjs().format("YYYY-MM-DD");
    const limit = this.state.limits[key];
    const limitless = limit === 0;
    const usage = this.state.usages[today]?.[key] || 0;
    const percent = (usage / limit) * 100;

    return (
      <>
        <Row justify="space-evenly">
          <Col xs={10}>
            <Progress
              type="circle"
              percent={parseFloat(percent.toFixed(1))}
              format={(percent) => {
                return limitless ? '∞' : percent + "%";
              }}
              strokeColor="#ff4e27"
              style={{ marginBottom: 10 }}
            />
          </Col>
          <Col xs={14}>
            <Row align="middle" justify="center" style={{ height: "100%" }}>
              <Typography.Title level={4}>
                {usage
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  .concat(" / ")
                  .concat(
                    limitless ? '∞' : limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  )}
              </Typography.Title>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  getChart(key: "apiCall" | "userCodeDuration") {
    const chartData = [];

    for (const [date, usages] of Object.entries(this.state.usages)) {
      const a: { date: string; [key: string]: any } = {
        date: dayjs(date).format("DD/MMM"),
      };
      a[key] = usages[key];
      chartData.push(a);
    }

    const config = {
      data: chartData,
      xField: "date",
      yField: key,
      color: "#ff4e27",
      columnWidthRatio: 0.8,
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 1,
        },
        content: (og: any) => {
          if (og.userCodeDuration)
            return og.userCodeDuration
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          else if (og.apiCall)
            return og.apiCall.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        date: {
          alias: "Day",
        },
        apiCall: {
          alias: "Api Call",
        },
        userCodeDuration: {
          alias: "User Code Duration(ms)",
        },
      },
    };

    return (
      <>
        <Column {...config} />
      </>
    );
  }

  render() {

    return (
      <>
        <CustomSpinner spinning={this.state.loading}>
          <Row justify="space-evenly" style={{ marginBottom: 10 }}>
            <Col span={4} xs={10}>
              <Badge.Ribbon
                placement={"start"}
                color={"#faad14"}
                text={this.state.subscriptionId}
              >
                <Card
                  title=""
                  size="small"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography.Title level={8}>API CALL</Typography.Title>
                  <Divider plain>Daily Limit</Divider>

                  {this.getDaily("apiCall")}
                  <Divider plain>Last 7 Days</Divider>

                  {this.getChart("apiCall")}
                </Card>
              </Badge.Ribbon>
            </Col>
            <Col span={4} xs={10}>
              <Badge.Ribbon
                placement={"start"}
                color={"#faad14"}
                text={this.state.subscriptionId}
              >
                <Card
                  title=""
                  size="small"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography.Title level={8}>CODE DURATION</Typography.Title>
                  <Divider plain>Daily Limit</Divider>

                  {this.getDaily("userCodeDuration")}

                  <Divider plain>Last 7 Days</Divider>
                  {this.getChart("userCodeDuration")}
                </Card>
              </Badge.Ribbon>
            </Col>
          </Row>
        </CustomSpinner>
      </>
    );
  }
}

DashboardLayout.contextType = RootProjectContext;

export default DashboardLayout;
